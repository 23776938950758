import PoweredBy from "src/components/common/powerdBy";
import { t } from "i18next";
import Header from "src/components/common/header";
import styled, { css } from "styled-components";
import avatarIcon from "src/assets/icons/settings/avatar.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import QRCode from "qrcode.react";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "src/components/toast/Toast";
import Tips from "src/components/tips/Tips";
import { isKontosChain, shortAddress } from "src/utils/helper";
import warningIco from "src/assets/icons/receive/warning.svg";
import { Trans } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { TokenOrChainIcon } from "src/components/icons/TokenOrChainIcon";
import { useAccount, useAccountEffect, useSwitchChain } from "wagmi";
import { KontosButton } from "src/components/button/KontosButton";
import ConnectModal from "./modals/ConnectWalletModal";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { ReceiveDepositFlow } from "./deposit/ReceiveDepositFlow";
import statusIcon from "src/assets/icons/receive/status.svg";
import { buttonClick, buttonHover } from "src/components/global-styles";
import disconnectIcon from "src/assets/icons/receive/disconnect.svg";
import Copy from "src/components/copy/Copy";
import { KONTOS_CHAIN_INDEX } from "src/config";
import { watchChainId } from "@wagmi/core";
import { wagmiConfig } from "src/configs/wagmi";
import { useWalletDisconnect } from "./hooks/useWalletDisconnect";
import loadingIcon from "src/components/button/loading-purple-icon.svg";
import { fontBold, fontH5, fontH7 } from "@/style/style.global";

const Container = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: visible;
  background-image: url(/static/bg-receive.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const Panel = styled.div`
  display: flex;
  max-width: 430px;
  max-height: 520px;
  padding: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  overflow-y: visible;
  overflow-x: hidden;

  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.__deep_100};
  background: ${(props) => props.theme.colors.__white};

  .warning {
    width: 100%;
    padding: 8px 20px;

    border-radius: 8px;
    background: rgba(250, 173, 20, 0.05);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .warning-icon {
      width: 16px;
      height: 16px;
    }

    .warning-text {
      margin-left: 16px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;

      color: var(--Warning, #faad14);
      font-family: "HarmonyOS Sans SC";
      font-size: 11px;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .account {
    display: flex;
    justify-content: center;
    align-items: center;

    .account-avatar {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }

    .account-name {
      margin-left: 9px;

      color: ${(props) => props.theme.colors.__deep_800};
      font-family: HarmonyOS Sans;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .choose-tip {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;

    .strong {
      color: var(--error-notice, #ff1e2b);
      ${fontBold};
      font-size: 14px;
      line-height: 130%;
    }
  }

  .chain-icon {
    width: 64px;
    height: 64px;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .scan-tip {
    color: ${(props) => props.theme.colors.__deep_400};
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }

  .qrcode {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .qrcode-connected {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 99px;
      border: 2px solid var(--White, #fff);
      background: var(--Deep-800, #1a2535);
      user-select: none;

      display: inline-flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .qrcode-connected-icon {
        width: 10px;
        height: 10px;
      }

      .qrcode-connected-text {
        color: var(--White, #fff);

        ${fontBold};
        font-size: 10px;
        font-style: normal;
        line-height: normal;
      }
    }
  }

  .qrcode-text {
    height: 16px;
    display: flex;
    align-items: center;
    gap: 2px;

    color: var(--Deep-400, #80868f);
    text-align: center;

    /* Description */
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ChainBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  position: relative;
`;

const ChainSelectButton = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 64px;
  height: 100%;
`;

const ChainSymbolText = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  /* H5 */
  ${fontH5}
`;

const ConnectButton = styled(KontosButton)`
  position: relative;
  padding: 2px;
  border-radius: 8px;
  background: var(--Kontos-Blue, #413dec);
  height: 40px;
  color: var(--White, #fff);
  text-align: center;
  /* H7 */
  ${fontH7}
`;

const normalBtnStyle = css`
  ${buttonClick}
  ${buttonHover}
`;

const DepositButton = styled.button<{ $disconnecting?: boolean }>`
  padding: 17px 0;
  width: 100%;
  position: relative;
  padding: 2px;
  border-radius: 8px;
  background: var(--Kontos-Blue, #413dec);
  height: 40px;
  color: var(--White, #fff);
  text-align: center;
  /* H7 */
  ${fontH7}

  ${(props) => (props.$disconnecting ? "" : normalBtnStyle)}
`;

const DisConnectButton = styled.span<{ $loading?: boolean }>`
  position: absolute;
  outline: none;
  border: none;

  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  background: var(--White, #fff);

  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  cursor: ${(props) => (props.$loading ? "not-allowed" : "cursor")};
`;

const DisConnectButtonIcon = styled.img<{ $loading?: boolean }>`
  width: 20px;
  height: 20px;
  @keyframes rotateBackground {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${(props) =>
    props.$loading && `animation: rotateBackground 1s linear infinite;`}
`;

const StyledPoweredBy = styled(PoweredBy)`
  padding: 0;
`;

interface IProps {
  onGoNetworkSelect: () => void;
}

const ReceiveMain: React.FC<IProps> = observer(({ onGoNetworkSelect }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const { userStore, receiveStore } = useStores();
  const [showConnectModal, setShowConnectModal] = useState<boolean>(false);
  const helpRef = useRef<HTMLDivElement | null>(null);
  const [showTips, setShowTips] = useState<boolean>(true);
  const selectedChainSymbol: string = receiveStore.chain?.chainSymbol || "";
  const [showDepositSheet, setShowDepositSheet] = useState<boolean>(false);
  const walletAccount = useAccount();
  const { disconnectAsync, toastDisconnectSuccess } = useWalletDisconnect();
  const [disconnecting, setDisconnecting] = useState<boolean>(false);
  const { switchChainAsync } = useSwitchChain();
  const [disableDepositButton, setDisableDepositButton] =
    useState<boolean>(false);
  useAccountEffect({
    onDisconnect() {
      setShowDepositSheet(false);
    },
  });

  useEffect(() => {
    receiveStore.restore();
    const unwatch = watchChainId(wagmiConfig, {
      async onChange(chainId) {
        if (
          receiveStore.chain &&
          chainId === parseInt(receiveStore.chain?.chainIndex)
        ) {
          return;
        }

        setShowDepositSheet(false);
      },
    });

    return () => {
      unwatch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showConnectModal) {
      receiveStore.setFreezeWatchingChainId(true);
    } else {
      setTimeout(() => {
        receiveStore.setFreezeWatchingChainId(false);
      }, 50);
    }
  }, [receiveStore, showConnectModal]);

  const handleConnectSuccess = useCallback(async () => {
    setShowConnectModal(false);

    // If receiveStore doesn't have chain or is kontos chain, but wallet has
    // Try to switch to wallet's chain in receive store
    // If failed, go to chain select page
    if (
      (!receiveStore.chain ||
        receiveStore.chain.chainIndex === KONTOS_CHAIN_INDEX) &&
      walletAccount.chainId
    ) {
      try {
        await receiveStore.setChainByChainIndex(
          walletAccount.chainId.toString()
        );
      } catch (e) {
        const errorMessage = e instanceof Error ? e.message : t("System Error");
        toast({
          text: errorMessage,
          type: "error",
        });
      }
      return;
    }

    // If receiveStore's chain is different from wallets'
    // Try to switch to receive stores' chain in wallet
    // If failed, go to chain select page
    if (
      receiveStore.chain &&
      walletAccount.chainId !== parseInt(receiveStore.chain.chainIndex)
    ) {
      try {
        await switchChainAsync({
          chainId: parseInt(receiveStore.chain.chainIndex),
        });
      } catch (e) {
        const errorMessage =
          e instanceof Error
            ? e.message
            : t("Failed to switch chain in your connected wallet");
        console.warn(errorMessage);
        toast({
          text: t("Failed to switch chain in your connected wallet"),
          type: "error",
        });
        receiveStore.setChain(undefined);
        onGoNetworkSelect();
      }
      return;
    }
  }, [
    onGoNetworkSelect,
    receiveStore,
    switchChainAsync,
    walletAccount.chainId,
  ]);

  const handleChooseNetworkClick = useCallback(() => {
    onGoNetworkSelect();
  }, [onGoNetworkSelect]);

  const handleGlobalMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (helpRef.current && !helpRef.current.contains(event.target as Node)) {
        setShowTips(false);
      }
    },
    []
  );

  const handleClickConnectBtn = useCallback(() => {
    if (walletAccount.isConnected) {
      setShowDepositSheet(true);
      return;
    }
    if (walletAccount.isConnecting || walletAccount.isReconnecting) {
      return;
    }
    setShowConnectModal(true);
  }, [
    walletAccount.isConnected,
    walletAccount.isConnecting,
    walletAccount.isReconnecting,
  ]);

  const handleDisConnect = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      if (walletAccount.isDisconnected) {
        return;
      }

      try {
        setDisconnecting(true);
        await disconnectAsync();
        toastDisconnectSuccess();
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : t("Disconnect failed");
        toast({
          text: errorMessage,
          type: "error",
        });
      } finally {
        setDisconnecting(false);
      }
    },
    [disconnectAsync, toastDisconnectSuccess, walletAccount.isDisconnected]
  );

  return (
    <Container onClick={handleGlobalMouseDown} ref={wrapperRef}>
      <Header
        ref={helpRef}
        padding="8px"
        title={t("Receive")}
        rightBtnText={t("Help?") as string}
        rightBtnOnMouseEnter={() => setShowTips(true)}
        rightBtnOnMouseLeave={() => setShowTips(false)}
        rightBtnOnTouchStart={() => setShowTips(true)}
        enableBack={true}
        callBack={() => onGoNetworkSelect()}
      />

      <Panel>
        {/* Warning */}
        <div className="warning">
          <img className="warning-icon" src={warningIco} alt="" />
          <div className="warning-text">
            <Trans
              i18nKey={"trans-only-support-specific-assets-to-this-address"}
            >
              Only supports sending {{ selectedChainSymbol }} assets to this
              address. Other assets will be lost forever!
            </Trans>
          </div>
        </div>

        {/* Account Name */}
        <div className="account">
          <img className="account-avatar" src={avatarIcon} alt="" />
          <span className="account-name">{userStore.accountInfo?.name}.os</span>
        </div>

        {/* Chain Symbol & clickable */}
        <ChainBox className="choose">
          {/* Button */}
          <ChainSelectButton onClick={handleChooseNetworkClick} />

          {/* Chain Icon */}
          <Tips
            wrapperStyle={{ width: "100%" }}
            contentStyle={{
              left: "7%",
              top: "110px",
              padding: "0px 8px",
              width: "80%",
            }}
            showTips={showTips}
            showTitle={false}
            tipsContent={
              <div className="choose-tip">
                <Trans i18nKey={"trans-choose-chain-tip"}>
                  This address only supports
                  <span className="strong">
                    {{ selectedChainSymbol } as any}
                  </span>
                  assets. Sending others will result in permanent loss.
                </Trans>
              </div>
            }
            triangleLeft={"155px"}
            triggerElement={
              <TokenOrChainIcon
                className="chain-icon"
                type="chain"
                size={64}
                src={receiveStore.chain?.imageURL}
              />
            }
          />
          <ChainSymbolText>
            {receiveStore.chain?.chainSymbol ? (
              receiveStore.chain?.chainSymbol
            ) : (
              <Skeleton count={1} style={{ width: "120px" }} />
            )}
          </ChainSymbolText>
        </ChainBox>

        {/* QR Code */}
        <div className="qrcode">
          {receiveStore.toReceiveAaAddress ? (
            <>
              <QRCode
                value={receiveStore.toReceiveAaAddress}
                size={180}
                renderAs="canvas"
                fgColor="#2B2B2B"
              />
            </>
          ) : (
            <Skeleton style={{ width: "180px", height: "180px" }} />
          )}

          {walletAccount.isConnected && (
            <div className="qrcode-connected">
              <img className="qrcode-connected-icon" src={statusIcon} alt="" />
              <span className="qrcode-connected-text">{t("Connected")}</span>
            </div>
          )}
        </div>

        {/* Address */}
        <div className="qrcode-text">
          {receiveStore.toReceiveAaAddress ? (
            <>
              {isKontosChain(receiveStore.chain?.chainIndex || "-1")
                ? userStore.accountInfo?.name + ".os"
                : shortAddress(receiveStore.toReceiveAaAddress, 6, 6)}
              <Copy
                text={
                  isKontosChain(receiveStore.chain?.chainIndex || "-1")
                    ? userStore.accountInfo?.name + ".os"
                    : receiveStore.toReceiveAaAddress
                }
              />
            </>
          ) : (
            <Skeleton count={1} style={{ width: "120px", marginTop: "8px" }} />
          )}
        </div>

        {walletAccount.isConnected && walletAccount.address ? (
          <DepositButton
            onClick={handleClickConnectBtn}
            disabled={disconnecting}
            $disconnecting={disconnecting || disableDepositButton}
          >
            {t("Deposit")}
            <DisConnectButton
              onClick={handleDisConnect}
              $loading={disconnecting}
              onMouseEnter={() => setDisableDepositButton(true)}
              onMouseLeave={() => setDisableDepositButton(false)}
            >
              <DisConnectButtonIcon
                $loading={disconnecting}
                src={disconnecting ? loadingIcon : disconnectIcon}
                alt="disconnect"
              />
            </DisConnectButton>
          </DepositButton>
        ) : walletAccount.isConnecting || walletAccount.isReconnecting ? (
          <ConnectButton onClick={handleClickConnectBtn} $loading>
            {t("Connecting...")}
          </ConnectButton>
        ) : (
          <ConnectButton onClick={handleClickConnectBtn}>
            {t("Connect Wallet")}
          </ConnectButton>
        )}
      </Panel>

      <StyledPoweredBy />

      {showConnectModal && (
        <ConnectModal
          chainId={receiveStore.chain?.chainIndex}
          onSuccess={handleConnectSuccess}
          onClose={() => setShowConnectModal(false)}
        />
      )}

      <BottomSheet
        isOpen={showDepositSheet}
        mountPoint={domNode}
        onClose={() => setShowDepositSheet(false)}
      >
        {receiveStore.chain && walletAccount.address && (
          <ReceiveDepositFlow
            chain={receiveStore.chain}
            onClose={() => setShowDepositSheet(false)}
            eoaAddress={walletAccount.address}
          />
        )}
      </BottomSheet>
    </Container>
  );
});

export default ReceiveMain;
