import { callAccount, callNonce } from "@/apis/aa-apis";
import { Account } from "@/type/zkkontos";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import {
  RespAccountsBalances,
  accountsBalances,
} from "@zkkontos/kontos-sdk/src/api/assetApi";
import { rootStore } from "..";

export const getMultiAccountsBalances = async (
  addresses: string[]
): Promise<RespAccountsBalances> => {
  return await accountsBalances({ accounts: addresses });
};

export const fetchAccount = async (account: string): Promise<Account> => {
  const { accountDetail } = await callAccount({ account, offset: 0, limit: 0 });
  return accountDetail;
};

export const fetchGuardians = async (account: string): Promise<string[]> => {
  const {
    accountDetail: { guardians },
  } = await callAccount({ account, offset: 0, limit: 0 });
  return guardians || [];
};

export const getSequence = async (
  account: string,
  method: "backend" | "chain" = "backend"
): Promise<number> => {
  if (method === "backend") {
    const { nonce } = await callNonce({ account });
    return nonce;
  } else {
    const { sequence } = await (
      await rootStore.userStore.getKontosCli()
    ).signingCli.getSequence(KontosQueryCli.kontosAddress(account));
    return sequence;
  }
};

export const fetchAaAccounts = async (
  account: string
): Promise<{
  [key: string]: string;
}> => {
  const {
    accountDetail: { blockChainAccounts },
  } = await callAccount({ account, offset: 0, limit: 0 });
  const aaAccounts = Object.keys(blockChainAccounts).reduce(
    (acc, key) => {
      acc[key] = blockChainAccounts[key].aaAddress;
      return acc;
    },
    {} as { [key: string]: string }
  );
  return aaAccounts;
};
