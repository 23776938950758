import { FtAsset, FtAssetDetail } from "src/type/zkkontos";
import styled from "styled-components";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import { memo, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FavoriteButton from "src/components/button/FavoriteButton";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import { InfoButton } from "src/components/button/InfoButton";
import { AssetWhiteListIcon } from "../asset-select/AssetWhiteListIcon";
import { fontH7 } from "@/style/style.global";
import { useTranslation } from "react-i18next";
import upArrwoIcon from "src/assets/icons/trade/up-arrow.svg";
import { getChain, getChainByAsset } from "@/store/storeHelper";
import { fetchFtAssetDetail } from "@/service/trade-service";

const CONTAINER_HEIGHT = "72px";

const Container = styled.div`
  padding: 14px 14px 4px 14px;
  // height: ${CONTAINER_HEIGHT};
  background: var(--White, #fff);
  border-radius: 8px;
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .value {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans BOLD";
    font-size: 16px;
    margin-right: 6px;
    .symbol {
      margin-right: 3px;
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  .left-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    .token-img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      border-radius: 50%;
    }
    .token-symbol {
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans BOLD";
      font-size: 16px;
      margin-right: 6px;
    }
    .chain-img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      border-radius: 50%;
    }
    .chain-name {
      white-space: nowrap;

      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
      margin-right: 6px;
    }
    .tick {
      width: 12px;
      height: 12px;
    }
  }
  .left-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: ${(props) => props.theme.colors.__deep_400};
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }
`;

const RightContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px 14px 14px 0;

  display: flex;
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  .number-box-line-1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 16px;
    margin-top: 4px;
    margin-bottom: 14px;
    .info {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }
  }

  .number-box-line-2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .fluctuate {
      margin-right: 6px;
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
    }
    .positive {
      color: ${(props) => props.theme.colors.__success};
    }

    .negative {
      color: ${(props) => props.theme.colors.__error};
    }

    .value {
      white-space: nowrap;
      color: var(--Deep-800, #1a2535);
      font-family: "HarmonyOS Sans BOLD";
      font-size: 16px;
    }

    .symbol {
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

const PlaceholderContainer = styled.div`
  padding: 15px 40px;
  height: ${CONTAINER_HEIGHT};
  background: var(--White, #fff);
  border-radius: 8px;
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const PlaceholderHintText = styled.span`
  ${fontH7}
  color: var(--Kontos-Blue, #413DEC);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PlaceholderHintIcon = styled.img`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
`;

interface Props {
  ftAsset?: FtAsset;
  requesting?: boolean;
  isFavorite?: boolean;
  disableFav?: boolean;
  onClick: () => void;
  onFavClick?: (ftAsset: FtAsset) => void;
  onDetailClick: () => void;
}

export const TargetAssetPanel: React.FC<Props> = memo(
  ({
    ftAsset,
    requesting = false,
    isFavorite,
    disableFav,
    onClick,
    onFavClick,
    onDetailClick,
  }) => {
    const { t } = useTranslation();
    const assetChain = getChain(ftAsset?.chainIndex);
    const usdPrice = new KontosNumber(ftAsset?.usdPrice, DEFAULT_DECIMAL);

    if (!requesting && !ftAsset)
      return (
        <PlaceholderContainer onClick={onClick}>
          <PlaceholderHintText>
            {t("Please select an asset for trading")}
          </PlaceholderHintText>
          <PlaceholderHintIcon src={upArrwoIcon} alt="click" />
        </PlaceholderContainer>
      );

    return (
      <Container onClick={onClick}>
        <LeftContainer>
          <div className={"left-top"}>
            <ImageWithFallback
              className={"token-img"}
              src={ftAsset?.imageUrl || defaultTokenIcon}
              fallbackSrc={defaultTokenIcon}
              alt=""
            />
            <span className={"token-symbol"}>
              {ftAsset?.symbol ? ftAsset.symbol : "-"}
            </span>
            <ImageWithFallback
              className={"chain-img"}
              src={assetChain?.greyImageURL || defaultChainIcon}
              fallbackSrc={defaultChainIcon}
              alt=""
            />
            <span className={"chain-name"}>
              {getChainByAsset(ftAsset)?.chainSymbol || "-"}
            </span>
            {ftAsset && (
              <AssetWhiteListIcon
                isWhiteList={ftAsset.isWhitelist}
                disableHoverToClick
              />
            )}
          </div>
          <div className={"left-bottom"}>
            <div className="number-box-line-2"></div>
          </div>
        </LeftContainer>
        <RightContainer>
          <NumberBox>
            <div className="number-box-line-1 value">
              <span className="symbol">{"$ "}</span>
              {!usdPrice.eq(0) ? (
                usdPrice.toFormat()
              ) : requesting ? (
                <Skeleton count={1} style={{ width: "50px" }} />
              ) : (
                "-"
              )}
              {/* {isFavorite !== undefined &&
                ftAsset !== undefined &&
                !disableFav &&
                onFavClick && (
                  <FavoriteButton
                    isFavorite={isFavorite}
                    onClick={(e) => {
                      e.stopPropagation();
                      onFavClick(ftAsset);
                    }}
                  />
                )} */}

              {/* {ftAsset !== undefined && (
                <InfoButton
                  className="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDetailClick();
                  }}
                />
              )} */}

              {}
            </div>

            {/* <div className="number-box-line-2">
              <div></div>

              <div className="value">
                <span className="symbol">{"$ "}</span>
                {!usdPrice.eq(0) ? (
                  usdPrice.toFormat()
                ) : requesting ? (
                  <Skeleton count={1} style={{ width: "50px" }} />
                ) : (
                  "-"
                )}
              </div>
            </div> */}
          </NumberBox>
        </RightContainer>
      </Container>
    );
  }
);
