import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DefalutButton, Wrap } from "./style";
import Logo from "../../assets/images/logo.svg";
import RegisterKontosHelp from "./RegisterKontosHelp";
import LayoutForWebPage from "../../components/layout-for-webpage";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { RecoverAccount } from "./RecoverAccount";
import { gqlGetAccountByRegex } from "../../network/apollo/query";
import { CREATE, RECOVERY, MULTISIG, INIT } from "../../config";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";

export type Option = {
  value: string;
  label: string;
};

export const ADDRESS_TIPS = [
  {
    title: t("Account ID can only contain the following:"),
    content: [
      t("Lowercase letters (a-z)"),
      t("Digits (0-9)"),
      t("Must be at least 4 characters"),
      t("No more than 64 characters"),
    ],
  },
  {
    title: t("Set up account recovery password:"),
    content: [
      t("At least 8 characters required"),
      t("No more than 32 characters"),
      t("Must contain at least one digit"),
      t("Must contain uppercase and lowercase"),
      t("Must contain symbols (#@$!%_*?-.&)"),
    ],
  },
];

export const GUARDIAN_TIPS = [
  {
    title: t("Set up an Account Guardian"),
    content: [
      t(
        `Permission from the account's Guardian is required to recover your wallet.`
      ),
    ],
  },
];

export const getAccounts = async (str: string) => {
  return await gqlGetAccountByRegex(str);
};
const RegisterKontos = (props: { type: string }) => {
  const { userStore } = useStores();
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const currentType = [CREATE, RECOVERY, MULTISIG].find((item) =>
    searchParams.has(item)
  );
  // const [init, setInit] = useState(search ? false : true);
  const [type, setType] = useState(props.type);
  // setType(props.type);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [showPinModal, setShowPinModal] = useState(
    userStore.hasAccountInStorage()
  );

  return (
    <LayoutForWebPage>
      <Wrap ref={wrapperRef}>
        <div className="center">
          <img src={Logo} className="logo" alt="" />
          {type === INIT && (
            <div className={"content"}>
              <img
                src="/static/kontos-app.svg"
                className="logoIco"
                alt="logoIco"
              />
              <div className={"info"}>
                <Trans i18nKey="Create Your Kontos Multi-chain Smart Account">
                  Create Your Kontos
                  <br />
                  Multi-chain Smart Account
                </Trans>
              </div>
              <img
                src="/static/blockchain-logo-2.png"
                className={"chains"}
                alt="blockchain"
              />
              <div className="mb16">
                <DefalutButton
                  size="large"
                  fullWidth
                  text={t("Create New Wallet")}
                  onClick={() => {
                    // setInit(false)
                    setType(CREATE);
                  }}
                />
              </div>
              <div className="mb16">
                <DefalutButton
                  size="large"
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    // setInit(false)
                    setType(RECOVERY);
                  }}
                  text={t("Recover Wallet")}
                />
              </div>
              <div className="mb16">
                <DefalutButton
                  size="large"
                  fullWidth
                  disabled={!userStore.hasAccountInStorage()}
                  text={t("Reset Wallet")}
                />
              </div>
              {/* <DefalutButton
                  size="large"
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setInit(false)
                    setType(MULTISIG);
                  }}
                  text={t('Multisig Wallet')}
                /> */}
            </div>
          )}
          {/*{type === CREATE && (*/}
          {/*  <CreateRegularAccount onBack={() => setType(INIT)} />*/}
          {/*)}*/}
          {type === RECOVERY && <RecoverAccount onBack={() => setType(INIT)} />}
          {/* {type === MULTISIG && !init && <MultisigAccount onBack={() => setInit(true)}/>} */}
          <RegisterKontosHelp />
          {/* <div className={'description'}>
            <Trans i18nKey="Kontos provides multi-chain wallet...">
              Kontos provides multi-chain wallet for effortless management of
              digital assets across various blockchain networks <br />
              such as Ethereum, BNB Chain, Polygon, and more to come..
            </Trans>
          </div> */}
        </div>
      </Wrap>

      {/* <NoScrollerBarSheet
        isOpen={showPinModal}
        onClose={() => setShowPinModal(false)}
        mountPoint={domNode}
        disableDrag={true}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Unlock
              onSuccess={(cli) => {
                setShowPinModal(false);
              }}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </NoScrollerBarSheet> */}
    </LayoutForWebPage>
  );
};

export default observer(RegisterKontos);
