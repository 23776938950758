import { observer } from "mobx-react";
import styled from "styled-components";
import { t } from "i18next";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { authorizedDomainArr, GOOGLE_CLIENT_ID } from "src/config";
import {
  getDefaultClientPolymorphism,
  KontosQueryCli,
  StorageKontosKey,
} from "@zkkontos/kontos-sdk/src/index";
import { checkDomainMatch } from "src/components/auth/Auth";
import { base64ToUint8Array, uint8ArrayToBase64 } from "src/utils/helper";
import { keccak256 } from "@ethersproject/keccak256";
import { enhancedFromHex } from "@zkkontos/kontos-sdk/src/core/utils";
import { useSearchParams } from "react-router-dom";
import { get } from "src/store/local";
import { KEYS } from "src/store/keys";
import { useStores } from "src/hooks/useStore";
import { getSequence } from "@/service/account-service";

const { ethers } = require("ethers");

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 20px;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

export const kecca256String = (str: string): string => {
  return ethers.utils.keccak256(
    ethers.utils.toUtf8Bytes(str.trim().toLowerCase().toString())
  );
};

const Sign: React.FC = () => {
  const { userStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const navigate = useNavigate();
  const [seed, setSeed] = useState("");
  const [searchParams] = useSearchParams();
  const signType = searchParams.get("signType") || "";

  console.log("signType", signType);

  // sign
  useEffect(() => {
    (async () => {
      if (window === window.parent) return;
      const getSign = async (
        currentName: string,
        privateKey: CryptoKey | null,
        signData: any
      ) => {
        const kontosClientPolymorphism = getDefaultClientPolymorphism();
        const signDataStr = btoa(JSON.stringify(signData));
        const signDataBytes = base64ToUint8Array(signDataStr);
        const signDataHash = keccak256(signDataBytes);
        const sig = await kontosClientPolymorphism.sign(
          currentName,
          privateKey,
          enhancedFromHex(signDataHash)
        );
        const base64Sig = uint8ArrayToBase64(sig);
        console.log("base64Sig", base64Sig);
        return base64Sig;
      };

      if (!window.parent) return;
      // check is data correct
      let [signTypeSplit, accountName] = signType.split("_");
      if (!signTypeSplit || !accountName) {
        console.warn("signType or accountName is not correct");
        return;
      }
      // check is accountName recovering
      const acocuntRecovering = (await get(
        KEYS.store_accounts_recovering
      )) as string[];
      if (acocuntRecovering.includes(accountName)) {
        console.warn("account is recovering");
        return;
      }
      // get account index
      const data = get(KEYS.store_kontos_key) as StorageKontosKey[];
      if (!data || data.length === 0) {
        console.warn("no account");
        return;
      }
      const accountIndex = data.findIndex(
        (item) => item.accountName === accountName
      );
      if (accountIndex === -1) {
        console.warn("no account exist");
        return;
      }
      await userStore.switchAccount(accountIndex);
      const cli = await userStore.getKontosCli();
      const privateKey = cli.key.privateKey as CryptoKey;
      const currentName = userStore.accountInfo?.name!;
      // get nonce
      const sequence = await getSequence(currentName);
      if (!sequence) {
        throw new Error("get sequence error");
      }
      const signData: any = {
        kontos_account_name: currentName,
        nonce: sequence + 1,
      };
      switch (signTypeSplit) {
        case "dailySignIn":
          signData.timestamp = parseInt((Date.now() / 1000).toString());
          break;

        default:
          break;
      }
      console.log("sign data", signData);
      const sign = await getSign(currentName, privateKey, signData);
      console.log("sign rs", sign);
      // set response data
      const rsData: any = {
        kontos_sign: sign,
      };
      switch (signTypeSplit) {
        case "dailySignIn":
          rsData.daliy_sign_data = signData;
          break;
        case "loginX":
          rsData.kontos_account_info = signData;
          break;
        case "loginDiscord":
          rsData.kontos_account_info = signData;
          break;

        default:
          break;
      }
      authorizedDomainArr.map((item) => {
        // if (checkDomainMatch(item, window.location.href)) {
        window.parent.postMessage(
          {
            data: rsData,
            target: `kontosInpageSign:${signType}`,
          },
          item
        );
        // }
      });
      window.close();
    })();
  }, []);

  return <Wrapper ref={wrapperRef}>sign page</Wrapper>;
};

export default observer(Sign);
