import Header from "src/components/common/header";
import { t } from "i18next";
import styled, { ThemeContext } from "styled-components";
import AccountPanel from "./AccountPanel";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";
import Sheet from "react-modal-sheet";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import SettingsRouter from "./SettingsRouter";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_GENERAL,
} from "src/router/router-config";
import { observer } from "mobx-react";
import generalIcon from "src/assets/icons/settings/general.svg";
import SettingsItem from "src/components/settings/SettingsItem";
import { useStores } from "src/hooks/useStore";
import useAuthCheck from "@/hooks/useAuthCheck";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  padding: 24px 24px 8px 24px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
`;

const MainContainer = styled.div`
  padding: 14px 16px 76px 16px;
  flex: 1;
`;

const StyledAccountPanel = styled(AccountPanel)`
  margin-bottom: 8px;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Settings: React.FC = () => {
  const { uiSettingStore } = useStores();
  const { authed } = useAuthCheck();
  const location = useLocation();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const theme = useContext(ThemeContext);
  const [showAccountSettingsModal, setShowAccountSettingsModal] =
    useState<boolean>(false);
  const itemList = [
    {
      title: t("General") as string,
      icon: generalIcon,
      onClick: () => {
        navigate(ROUTE_SETTINGS_GENERAL);
      },
    },
  ];

  useEffect(() => {
    if (!showAccountSettingsModal) {
      navigate(ROUTE_SETTINGS);
    }
  }, [navigate, showAccountSettingsModal]);

  useEffect(() => {
    if (location.pathname === ROUTE_SETTINGS) {
      setShowAccountSettingsModal(false);
    }
  }, [location.pathname]);

  const closeModals = useCallback(() => {
    setShowAccountSettingsModal(false);
  }, []);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: uiSettingStore.canClose,
  });

  return (
    <Container>
      <StyledHeader title={t("Settings")} />
      <MainContainer ref={wrapperRef}>
        {authed && (
          <StyledAccountPanel
            leftText={t("Account Setting")}
            leftColor={theme.colors.__kontos_blue}
            onClick={() => {
              setShowAccountSettingsModal(true);
              navigate(ROUTE_SETTINGS_ACCOUNT);
            }}
          />
        )}
        <Scrollable>
          {itemList.map((item) => (
            <SettingsItem
              key={item.title}
              mainIcon={item.icon}
              title={item.title}
              onClick={item.onClick}
            />
          ))}
        </Scrollable>

        <NoScrollerBarSheet
          isOpen={showAccountSettingsModal}
          onClose={() => setShowAccountSettingsModal(false)}
          mountPoint={domNode}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <SettingsRouter />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setShowAccountSettingsModal(false)} />
        </NoScrollerBarSheet>
      </MainContainer>
    </Container>
  );
};

export default observer(Settings);
