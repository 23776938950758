import { EXPLORER_KONTOS_URL, TEMP_EXPLORER_URL } from "src/config";
import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { API } from "src/apis/core/API";
import * as apiTypes from "@zkkontos/kontos-sdk/src/api/types";

export interface ReqReferalInfo {
  name: string;
}

export interface IInvite {
  invitee_name: string;
  asset_usd_amount: string;
  indect_asset_usd_amount: string;
}

export interface RespReferralInfo {
  earning_amount: string;
  invitee: IInvite[];
}

export const getReferralInfo = async (
  data: ReqReferalInfo,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespReferralInfo> => {
  return request<ReqReferalInfo, RespReferralInfo>(
    API.account.referralInfo,
    data,
    endpoint
  );
};

export interface ReqSyncAccount {
  account: string;
  chainIndex: string;
  assetAddress: string;
}

export interface RespSyncAccount {}

export const callSyncSingleBalance = async (
  data: ReqSyncAccount,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespSyncAccount> => {
  return request<ReqSyncAccount, RespSyncAccount>(
    API.masset.syncSingleBalance,
    data,
    endpoint
  );
};

export interface ReqAccountsNameRegex {
  character: string;
}

export interface RespAccountsNameRegex {
  accounts: apiTypes.Account[];
}

let accountsNameRegexController: AbortController;

export const callAccountsNameRegex = async (
  data: ReqAccountsNameRegex,
  endpoint: string = EXPLORER_KONTOS_URL
) => {
  accountsNameRegexController && accountsNameRegexController.abort();
  accountsNameRegexController = new AbortController();
  return request<ReqAccountsNameRegex, RespAccountsNameRegex>(
    API.aa.accountsNameRegex,
    data,
    endpoint,
    accountsNameRegexController.signal
  );
};
