import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import waitingRecoverIco from "src/assets/images/waiting-recover-logo.svg";
import info from "src/assets/icons/info1.svg";
import info2 from "src/assets/icons/info2.svg";
import InputWithIcons from "src/components/input/KontosInputV2";
import { ReactComponent as SecurityGoogle } from "src/assets/icons/security-google.svg";
import HorizontalLine from "src/components/line/HorizontalLine";
import { t } from "i18next";
import RecoverInputStatusText from "src/components/start/RecoverInputStatusText";
import RecoveryCopySection from "src/components/start/RecoveryCopySection";
import { KontosKey, KontosQueryCli } from "@zkkontos/kontos-sdk/src/index";
import Description from "src/components/description/Description";
import { callAccountThresholdActions } from "@/apis/aa-apis";
import { ApiThresholdAction } from "@/apis/types";
import { fetchGuardians } from "@/service/account-service";
import { fontBold } from "@/style/style.global";

const Wrapper = styled.div`
  display: flex;
  flex: 1;

  .main {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    max-width: 100%;

    .description {
      display: flex;
      justify-content: center;
      color: var(--Warning, #faad14);
      border-radius: 8px;
      border: 1px solid var(--Warning, #faad14);
      background: rgba(250, 173, 20, 0.1);
      /* Description */
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      padding: 23px 16px;
      .info {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin-right: 8px;
      }
      span {
        color: var(--Warning, #faad14);
        ${fontBold};
        font-size: 14px;
        font-style: normal;
        line-height: normal;
      }
    }

    .waitingRecoverIco {
      width: 100px;
      height: 100px;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    }

    .buttonWrap {
      width: calc(100% - 40px);
      position: fixed;
      bottom: 16px;
    }
  }
`;

type IProps = {
  kontosName: string;
  kontosKey: KontosKey | undefined;
  actionThreshold: number;
};

let interval: any = null;

const isActionApprovedAndPubKeyMatched = (
  guardianAddress: string,
  localPubkey: string | undefined,
  threshHoldActions: ApiThresholdAction[] | undefined
) => {
  if (!threshHoldActions || !localPubkey) return false;
  return threshHoldActions?.find(
    (item) =>
      item.newPubKey === localPubkey &&
      Object.keys(item.approvals).includes(guardianAddress)
  );
};

const WaitingAuthorizeKontos: React.FC<IProps> = ({
  kontosName,
  kontosKey,
  actionThreshold,
}) => {
  const [accountGuardians, setAccountGuardians] = useState<
    string[] | undefined
  >(undefined);
  const [threshHoldActions, setThreshHoldActions] = useState<
    ApiThresholdAction[] | undefined
  >(undefined);

  useEffect(() => {
    clearInterval(interval);
    interval = null;
    const getData = () => {
      fetchGuardians(kontosName)
        .then((guardians) => {
          setAccountGuardians(guardians || undefined);
        })
        .catch((e) => {
          console.warn("get account guardians fail");
        });

      callAccountThresholdActions({
        account: kontosName,
      })
        .then(({ actions }) => {
          setThreshHoldActions(actions);
        })
        .catch((e) => {
          console.warn("get account guardians fail");
        });
    };
    getData();
    interval = setInterval(() => {
      getData();
    }, 10000);
  }, [kontosName]);

  const pubKeyMatchedLength =
    accountGuardians?.filter((item) =>
      isActionApprovedAndPubKeyMatched(
        KontosQueryCli.nameAddress(item),
        kontosKey?.pubKeyData?.compressedPubKey,
        threshHoldActions
      )
    ).length || 0;

  return (
    <Wrapper>
      <div className={"main"}>
        <img
          className={"waitingRecoverIco"}
          src={waitingRecoverIco}
          alt="waitingRecoverIco"
        />
        <Description
          icon={pubKeyMatchedLength > 0 ? info : info2}
          text={
            t("Waiting for guardians to submit the recovery request!") +
            ` ${pubKeyMatchedLength}/${actionThreshold}`
          }
        />
        <div className={"lineWrap"}>
          <HorizontalLine />
        </div>
        <RecoveryCopySection
          copyDataString={`${kontosKey?.pubKeyData?.compressedPubKey || ""}`}
        />
        <div className={"inputWrap"}>
          {accountGuardians?.map((item, index) => (
            <InputWithIcons
              key={index}
              inputStyle={{
                border: "none",
                background: "transparent",
              }}
              leftIcon={<SecurityGoogle />}
              rightIcon={
                <RecoverInputStatusText
                  status={
                    isActionApprovedAndPubKeyMatched(
                      KontosQueryCli.nameAddress(item),
                      kontosKey?.pubKeyData?.compressedPubKey,
                      threshHoldActions
                    )
                      ? "success"
                      : "normal"
                  }
                />
              }
              placeholder={t("")}
              value={item || ""}
              disabled={false}
              isDelete={true}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default observer(WaitingAuthorizeKontos);
