import Header from "src/components/common/header";
import { t } from "i18next";
import styled, { ThemeContext } from "styled-components";
import { useContext } from "react";
import AccountPanel from "../AccountPanel";
import switchAccountIcon from "src/assets/icons/settings/switch-account.svg";
import guardianIcon from "src/assets/icons/settings/guardian.svg";
import securityEmailIcon from "src/assets/icons/settings/security-email.svg";
import referralCenterIcon from "src/assets/icons/settings/referral-center.svg";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_GUARDIAN,
  ROUTE_SECURITY__EMAIL,
  ROUTE_SETTINGS_ACCOUNT_REFERRAL,
  ROUTE_SETTINGS_ACCOUNT_SWITCH,
  ROUTE_SETTINGS_PAYMENT,
} from "src/router/router-config";
import SettingsItem from "src/components/settings/SettingsItem";
import { useStores } from "src/hooks/useStore";
import paymentIcon from "src/assets/icons/settings/payment.svg";
import useAuthCheck from "@/hooks/useAuthCheck";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;

  padding: 0 16px 16px 16px;
`;

const Scrollable = styled.div`
  margin-top: 8px;

  flex: 1;
  overflow: auto;
`;

const AccountSettings: React.FC = () => {
  const { userStore } = useStores();
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const { authed } = useAuthCheck();
  const itemList = [
    // {
    //   title: t("Referral center") as string,
    //   icon: referralCenterIcon,
    //   onClick: () => {
    //     navigate(ROUTE_SETTINGS_ACCOUNT_REFERRAL);
    //   },
    // },
    {
      title: t("Switch Account") as string,
      icon: switchAccountIcon,
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        navigate(ROUTE_SETTINGS_ACCOUNT_SWITCH);
      },
    },
    // {
    //   title: t("Guardian") as string,
    //   icon: guardianIcon,
    //   onClick: () => {
    //     navigate(ROUTE_GUARDIAN);
    //   },
    // },
    {
      title: t("Security Email") as string,
      icon: securityEmailIcon,
      onClick: () => {
        navigate(ROUTE_SECURITY__EMAIL);
      },
    },
    // {
    //   title: t("Manage Payment") as string,
    //   icon: paymentIcon,
    //   onClick: () => {
    //     navigate(ROUTE_SETTINGS_PAYMENT);
    //   },
    // },
  ];

  return (
    <Container>
      <Header title={t("Account")} headStyle={{ marginTop: "-20px" }} />
      {authed && (
        <AccountPanel
          leftText={userStore.kontosName}
          leftColor={theme.colors.__deep_400}
        />
      )}

      <Scrollable>
        {itemList.map((item) => (
          <SettingsItem
            key={item.title}
            mainIcon={item.icon}
            title={item.title}
            onClick={item.onClick}
          />
        ))}
      </Scrollable>
    </Container>
  );
};

export default AccountSettings;
