import styled from "styled-components";
import LeftBlueArrowSvg from "src/assets/icons/contract/left-blue-arrow.svg";
import { t } from "i18next";
import { memo, useEffect, useMemo, useState } from "react";
import { classifyTaskStatus } from "src/utils/zkkontosHelper";
import successIcon from "src/assets/icons/task/success.svg";
import errorIcon from "src/assets/icons/task/error.svg";
import warningIcon from "src/assets/icons/task/warning.svg";
import { DEFAULT_DECIMAL, EXPLORER_URL } from "src/config";
import KontosNumber from "src/utils/KontosNumber";
import {
  beautifyISOTimestamp,
  formatAddress,
  isValidUserOpHash,
  openUrl,
  shortAddress,
} from "src/utils/helper";
import Divider from "src/components/divider/Divider";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import Skeleton from "react-loading-skeleton";
import FloatingButton from "src/pages/trade/FloatingButton";
import OpenUrlIcon from "src/assets/icons/task/open-url.svg";
import arrowIco from "src/assets/icons/arrow16.svg";
import { Task, TaskDetail } from "@/type/zkkontos";
import { fetchTaskDetail } from "@/service/task-service";
import { getChain } from "@/store/storeHelper";
import { ApiActionType, ApiUpdatePubKeyAction } from "@/apis/types";
import Copy from "../copy/Copy";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .success {
    color: ${({ theme }) => theme.colors.__success};
  }
  .error {
    color: ${({ theme }) => theme.colors.__error};
  }
  .warning {
    color: ${({ theme }) => theme.colors.__warning};
  }
  .strong {
    color: ${({ theme }) => theme.colors.__deep_800};
  }
  .kontos {
    color: ${({ theme }) => theme.colors.__kontos_blue};
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 23px;
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-overflow: ellipsis;
  white-space: nowrap;

  ${fontBold}
  font-size: 24px;

  .back-icon {
    width: 22px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .spacer {
    width: 22px;
  }
`;

const StatusBox = styled.div`
  margin-top: 22px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .status-box-icon {
    width: 45px;
    height: 45px;
  }

  .status-box-text {
    ${fontBold}
    font-size: 14px;
  }

  .status-box-amount {
    ${fontBold}
    font-size: 18px;
  }
`;

const Scrollable = styled.div`
  padding: 0 32px 48px 32px;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ItemLine = styled.div.attrs(() => ({}))`
  margin-top: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: HarmonyOS Sans;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.__deep_400};

  .strong {
    color: var(--Deep-800, #1a2535);
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  > span:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .blue {
    color: ${({ theme }) => theme.colors.__kontos_blue};
  }
`;

const ItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.__deep_800};
  ${fontBold};
  font-size: 14px;
`;

const ItemLine3 = styled.div`
  margin-top: 16px;

  display: flex;
  justify-content: space-between;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.__deep_400};
  text-overflow: ellipsis;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;

  word-break: break-all;
  white-space: normal;

  .item1,
  .item2,
  .item3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
  }

  .item1 {
    width: 35%;
  }

  .spacer {
    flex-grow: 1;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
`;

const FloatingBtnWraper = styled.div`
  position: sticky;
  bottom: 16px;
  width: 89%;
  z-index: 1;
  margin: 0 auto;
`;

const FailMessageBoxWrapper = styled.div`
  padding: 0 16px;
  margin: 10px 0 -10px 0;
`;

const FailMessageBox = styled.div`
  padding: 8px 20px;
  border-radius: 8px;
  background: rgba(255, 30, 43, 0.05);

  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    color: var(--error-notice, #ff1e2b);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
  }
`;

const getStatusClassName = (status: "error" | "success" | "warning") => {
  switch (status) {
    case "error":
      return "error";
    case "success":
      return "success";
    case "warning":
      return "warning";
    default:
      return "warning";
  }
};

const getStatusIcon = (status: "error" | "success" | "warning") => {
  switch (status) {
    case "error":
      return errorIcon;
    case "success":
      return successIcon;
    case "warning":
      return warningIcon;
    default:
      return errorIcon;
  }
};

export const classifyTaskStatusText = (
  status: "error" | "success" | "warning"
): string => {
  switch (status) {
    case "warning":
      return t("Task is pending");
    case "success":
      return t("Task succeeded");
    case "error":
      return t("Task failed");
    default:
      return t("Unknown");
  }
};

export const getActionStatusText = (type: ApiActionType): string => {
  switch (type) {
    case ApiActionType.EmptyCall:
      return t("Empty Call");
    case ApiActionType.Buy:
      return t("Buy");
    case ApiActionType.Sell:
      return t("Sell");
    case ApiActionType.Transfer:
      return t("Transfer");
    case ApiActionType.UserOp:
      return t("UserOp Call");
    default:
      return t("Unknown");
  }
};

export const getHasAaFee = (action: ApiUpdatePubKeyAction): boolean => {
  switch (action) {
    case ApiUpdatePubKeyAction.UpdatePubKeyActionNew:
    case ApiUpdatePubKeyAction.UpdatePubKeyActionUpdate:
      return true;
    case ApiUpdatePubKeyAction.UpdatePubKeyActionNone:
    default:
      return false;
  }
};

export const getAaFeeText = (action: ApiUpdatePubKeyAction): string => {
  switch (action) {
    case ApiUpdatePubKeyAction.UpdatePubKeyActionNew:
      return t("Create AA");
    case ApiUpdatePubKeyAction.UpdatePubKeyActionUpdate:
      return t("Sync AA");
    case ApiUpdatePubKeyAction.UpdatePubKeyActionNone:
    default:
      return "";
  }
};

interface IDisplayTask {
  fetching: boolean;
  opHash: string;
  status: "error" | "success" | "warning";
  amountWithSymbol?: string;
  failReason?: string;
  actionTypeText: string;
  hasAaFee: boolean;
  aaFeeText: string;
  chainImgUrl: string;
  chainSymbol: string;
  senderOrReceiver: string;
  broker?: string;
  buyData?: {
    assetImageUrl: string;
    assetSymbol: string;
    amount: string;
  };
  sellData?: {
    srcAssetImageUrl: string;
    srcAssetSymbol: string;
    srcAssetAmount: string;
    srcChainImageUrl: string;
    srcChainSymbol: string;
    dstAssetImageUrl: string;
    dstAssetSymbol: string;
    dstAssetAmount: string;
    dstChainImageUrl: string;
    dstChainSymbol: string;
  };
  transferData?: {
    receiver: string;
    assetImageUrl: string;
    assetSymbol: string;
    amount: string;
  };
  userOpData?: {
    contractAddress: string;
  };
  payments?: {
    assetSymbol: string;
    assetImgUrl: string;
    chainSymbol: string;
    chainImgUrl: string;
    usdValue: string;
    assetAmount: string;
  }[];
  totalFee?: string;
  createTime: string;
}

interface IProps {
  accountName: string;
  taskPreview: Task;
  onBack: () => void;
  onUpdate?: (newTask: TaskDetail) => void;
}

const REFRESH_INTERVAL = 40000;

export const TaskDetailView: React.FC<IProps> = memo(
  ({ accountName, taskPreview, onBack, onUpdate }) => {
    const [taskDetail, setTaskDetail] = useState<TaskDetail>();

    useEffect(() => {
      const reFetchData = async () => {
        try {
          const respTask = await fetchTaskDetail(taskPreview.opHash);
          setTaskDetail(respTask);
          onUpdate?.(respTask);
        } catch (e) {
          console.warn(e);
        }
      };

      const intervalId = setInterval(reFetchData, REFRESH_INTERVAL);

      return () => clearInterval(intervalId);
    }, [onUpdate, taskPreview.opHash]);

    useEffect(() => {
      const fetchData = async () => {
        let _taskFull = undefined;
        try {
          const respTask = await fetchTaskDetail(taskPreview.opHash);
          _taskFull = respTask;
          onUpdate?.(respTask);
        } catch (e) {
          console.warn(e);
        } finally {
          setTaskDetail(_taskFull);
        }
      };
      taskPreview?.opHash && fetchData();
    }, [onUpdate, taskPreview.opHash]);

    const taskDisplay: IDisplayTask = useMemo(() => {
      if (taskDetail) {
        const data: IDisplayTask = {
          fetching: false,
          opHash: taskDetail.opHash,
          status: classifyTaskStatus(taskDetail.status),
          amountWithSymbol:
            "-" +
            new KontosNumber(
              taskDetail.totalPaymentsInUsd,
              DEFAULT_DECIMAL
            ).toFormat() +
            " USD",
          failReason:
            taskDetail.failReason !== "" ? taskDetail.failReason : undefined,
          actionTypeText: getActionStatusText(taskDetail.actionType),
          hasAaFee: getHasAaFee(taskDetail.updatePubKeyAction),
          aaFeeText: getAaFeeText(taskDetail.updatePubKeyAction),
          chainImgUrl:
            getChain(taskDetail.chainIndex)?.greyImageURL || defaultChainIcon,
          chainSymbol: getChain(taskDetail.chainIndex)?.chainSymbol || "-",
          senderOrReceiver: accountName,
          broker: taskDetail.broker,
          buyData:
            taskDetail.actionType === ApiActionType.Buy
              ? {
                  assetImageUrl:
                    taskDetail.actionInfoBuy?.assetImageUrl || defaultTokenIcon,
                  assetSymbol: taskDetail.actionInfoBuy?.assetSymbol || "-",
                  amount: new KontosNumber(
                    taskDetail.actionInfoBuy?.assetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(),
                }
              : undefined,
          sellData:
            taskDetail.actionType === ApiActionType.Sell
              ? {
                  srcAssetImageUrl:
                    taskDetail.actionInfoSell?.srcAssetImageUrl ||
                    defaultTokenIcon,
                  srcAssetSymbol:
                    taskDetail.actionInfoSell?.srcAssetSymbol || "-",
                  srcAssetAmount: new KontosNumber(
                    taskDetail.actionInfoSell?.srcAssetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(),
                  srcChainImageUrl:
                    getChain(taskDetail.actionInfoSell?.srcChainIndex)
                      ?.greyImageURL || defaultChainIcon,
                  srcChainSymbol:
                    getChain(taskDetail.actionInfoSell?.srcChainIndex)
                      ?.chainSymbol || "-",
                  dstAssetImageUrl:
                    taskDetail.actionInfoSell?.dstAssetImageUrl ||
                    defaultTokenIcon,
                  dstAssetSymbol:
                    taskDetail.actionInfoSell?.dstAssetSymbol || "-",
                  dstAssetAmount: new KontosNumber(
                    taskDetail.actionInfoSell?.dstAssetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(),
                  dstChainImageUrl:
                    getChain(taskDetail.chainIndex)?.greyImageURL ||
                    defaultChainIcon,
                  dstChainSymbol:
                    getChain(taskDetail.chainIndex)?.chainSymbol || "-",
                }
              : undefined,
          transferData:
            taskDetail.actionType === ApiActionType.Transfer
              ? {
                  receiver: taskDetail.actionInfoTransfer?.receiver || "-",
                  assetImageUrl:
                    taskDetail.actionInfoTransfer?.assetImageUrl ||
                    defaultTokenIcon,
                  assetSymbol:
                    taskDetail.actionInfoTransfer?.assetSymbol || "-",
                  amount: new KontosNumber(
                    taskDetail.actionInfoTransfer?.assetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(),
                }
              : undefined,
          userOpData:
            taskDetail.actionType === ApiActionType.UserOp
              ? {
                  contractAddress:
                    taskDetail.actionInfoUserOp?.dstAddress || "-",
                }
              : undefined,
          payments:
            taskDetail.payments?.map((payment) => {
              return {
                assetSymbol: payment.assetSymbol,
                assetImgUrl: payment.assetImageUrl,
                chainSymbol: getChain(payment.chainIndex)?.chainSymbol || "-",
                chainImgUrl:
                  getChain(payment.chainIndex)?.greyImageURL ||
                  defaultChainIcon,
                assetAmount:
                  new KontosNumber(
                    payment.assetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat() +
                  " " +
                  payment.assetSymbol,
                usdValue:
                  new KontosNumber(
                    payment.usdAmount,
                    DEFAULT_DECIMAL
                  ).toFormat() + " USD",
              };
            }) || [],
          totalFee:
            new KontosNumber(
              taskDetail.totalFeeInUsd,
              DEFAULT_DECIMAL
            ).toFormat() + " USD",
          createTime: beautifyISOTimestamp(taskDetail.createdAt),
        };
        return data;
      } else {
        const data: IDisplayTask = {
          fetching: true,
          opHash: taskPreview.opHash,
          status: classifyTaskStatus(taskPreview.status),
          amountWithSymbol: undefined,
          failReason:
            taskPreview.failReason !== "" ? taskPreview.failReason : undefined,
          actionTypeText: getActionStatusText(taskPreview.actionType),
          hasAaFee: getHasAaFee(taskPreview.updatePubKeyAction),
          aaFeeText: getAaFeeText(taskPreview.updatePubKeyAction),
          chainImgUrl:
            getChain(taskPreview.chainIndex)?.greyImageURL || defaultChainIcon,
          chainSymbol: getChain(taskPreview.chainIndex)?.chainSymbol || "-",
          senderOrReceiver: accountName,
          broker: undefined,
          buyData:
            taskPreview.actionType === ApiActionType.Buy
              ? {
                  assetImageUrl:
                    taskPreview.actionInfoBuy?.assetImageUrl ||
                    defaultTokenIcon,
                  assetSymbol: taskPreview.actionInfoBuy?.assetSymbol || "-",
                  amount: new KontosNumber(
                    taskPreview.actionInfoBuy?.assetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(),
                }
              : undefined,
          sellData:
            taskPreview.actionType === ApiActionType.Sell
              ? {
                  srcAssetImageUrl:
                    taskPreview.actionInfoSell?.srcAssetImageUrl ||
                    defaultTokenIcon,
                  srcAssetSymbol:
                    taskPreview.actionInfoSell?.srcAssetSymbol || "-",
                  srcAssetAmount: new KontosNumber(
                    taskPreview.actionInfoSell?.srcAssetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(),
                  srcChainImageUrl:
                    getChain(taskPreview.actionInfoSell?.srcChainIndex)
                      ?.greyImageURL || defaultChainIcon,
                  srcChainSymbol:
                    getChain(taskPreview.actionInfoSell?.srcChainIndex)
                      ?.chainSymbol || "-",
                  dstAssetImageUrl:
                    taskPreview.actionInfoSell?.dstAssetImageUrl ||
                    defaultTokenIcon,
                  dstAssetSymbol:
                    taskPreview.actionInfoSell?.dstAssetSymbol || "-",
                  dstAssetAmount: new KontosNumber(
                    taskPreview.actionInfoSell?.dstAssetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(),
                  dstChainImageUrl:
                    getChain(taskPreview.chainIndex)?.greyImageURL ||
                    defaultChainIcon,
                  dstChainSymbol:
                    getChain(taskPreview.chainIndex)?.chainSymbol || "-",
                }
              : undefined,
          transferData:
            taskPreview.actionType === ApiActionType.Transfer
              ? {
                  receiver: taskPreview.actionInfoTransfer?.receiver || "-",
                  assetImageUrl:
                    taskPreview.actionInfoTransfer?.assetImageUrl ||
                    defaultTokenIcon,
                  assetSymbol:
                    taskPreview.actionInfoTransfer?.assetSymbol || "-",
                  amount: new KontosNumber(
                    taskPreview.actionInfoTransfer?.assetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(),
                }
              : undefined,
          userOpData:
            taskPreview.actionType === ApiActionType.UserOp
              ? {
                  contractAddress:
                    taskPreview.actionInfoUserOp?.dstAddress || "-",
                }
              : undefined,
          payments: undefined,
          totalFee: undefined,
          createTime: beautifyISOTimestamp(taskPreview.createdAt),
        };
        return data;
      }
    }, [taskDetail, taskPreview, accountName]);

    return (
      <Container>
        <TitleBox>
          <img
            className="back-icon"
            src={LeftBlueArrowSvg}
            alt="back"
            onClick={() => onBack()}
          />
          <span>{t("Task Details")}</span>
          <div className="spacer"></div>
        </TitleBox>

        <StatusBox>
          <img
            className="status-box-icon"
            src={getStatusIcon(taskDisplay?.status)}
            alt="status"
          />
          <span
            className={`status-box-text ${getStatusClassName(
              taskDisplay?.status
            )}`}
          >
            {classifyTaskStatusText(taskDisplay.status)}
          </span>
          <span
            className={`status-box-amount ${getStatusClassName(
              taskDisplay?.status
            )}`}
          >
            {taskDisplay.fetching ? (
              <Skeleton count={1} width={"150px"} />
            ) : (
              taskDisplay.amountWithSymbol
            )}
          </span>
        </StatusBox>

        {/* Cancel Desc */}
        {classifyTaskStatus(taskPreview.status) === "error" &&
          taskDisplay.failReason && (
            <FailMessageBoxWrapper>
              <FailMessageBox>
                <img src={errorIcon} alt="" />
                <span>{taskDisplay.failReason}</span>
              </FailMessageBox>
            </FailMessageBoxWrapper>
          )}

        <div style={{ padding: "0 32px" }}>
          <Divider isDashed={true} top={24} bottom={12} />
        </div>

        <Scrollable>
          {/* Overview */}

          <ItemLine>
            <span>{t("Task type:")}</span>
            <span className="blue">
              {taskDisplay.hasAaFee
                ? taskDisplay.actionTypeText + ` + ${taskDisplay.aaFeeText}`
                : taskDisplay.actionTypeText}
            </span>
          </ItemLine>
          <ItemLine>
            <span>{t("Cross-chain to:")}</span>
            <ImageWithFallback
              src={taskDisplay.chainImgUrl || defaultTokenIcon}
              fallbackSrc={defaultTokenIcon}
              StyledImg={Icon}
            />
            <span>{taskDisplay.chainSymbol}</span>
          </ItemLine>
          {typeof taskDisplay.buyData !== "undefined" ? (
            <ItemLine>
              <span>{t("Sender")}:</span>

              <>
                <Icon src={avatarIcon} />
                <span>{formatAddress(taskDisplay?.senderOrReceiver)}</span>
              </>
            </ItemLine>
          ) : (
            <ItemLine>
              <span>{t("Receiver")}:</span>

              <>
                <Icon src={avatarIcon} />
                <span>{formatAddress(taskDisplay.senderOrReceiver)}</span>
              </>
            </ItemLine>
          )}
          <ItemLine>
            <span>{t("Broker")}:</span>
            {taskDisplay?.fetching ? (
              <Skeleton count={1} width={"100px"} />
            ) : (
              <span>{formatAddress(taskDisplay.broker)}</span>
            )}
          </ItemLine>

          {/* Buy Data */}
          {typeof taskDisplay.buyData !== "undefined" && (
            <>
              <Divider isDashed={true} top={24} bottom={24} />
              <ItemTitle> {t("Buy Assets")}</ItemTitle>
              <ItemLine3
                style={{
                  marginTop: "10px",
                }}
              >
                <span className="item1">
                  <ImageWithFallback
                    src={taskDisplay.buyData.assetImageUrl}
                    fallbackSrc={defaultTokenIcon}
                    StyledImg={Icon}
                  />
                  {taskDisplay.buyData.assetSymbol}
                </span>
                <span className="item2">
                  <ImageWithFallback
                    src={taskDisplay.chainImgUrl}
                    fallbackSrc={defaultChainIcon}
                    StyledImg={Icon}
                  />
                  <span>{taskDisplay.chainSymbol}</span>
                </span>
                <span className="spacer"></span>
                <span
                  className={`item3 strong`}
                  style={{
                    color: "var(--Kontos-Blue, #413DEC)",
                  }}
                >
                  +
                  {taskDisplay.buyData.amount +
                    " " +
                    taskDisplay.buyData.assetSymbol}
                </span>
              </ItemLine3>
            </>
          )}

          {/* Sell Data */}
          {typeof taskDisplay.sellData !== "undefined" && (
            <>
              <Divider isDashed={true} top={24} bottom={24} />
              <ItemTitle> {t("Sell")}</ItemTitle>
              <ItemLine3>
                <span className="item1">
                  <ImageWithFallback
                    src={taskDisplay.sellData.srcAssetImageUrl}
                    fallbackSrc={defaultTokenIcon}
                    StyledImg={Icon}
                  />
                  {taskDisplay.sellData.srcAssetSymbol}
                </span>
                <span className="item2">
                  <ImageWithFallback
                    src={taskDisplay.sellData.srcChainImageUrl}
                    fallbackSrc={defaultChainIcon}
                    StyledImg={Icon}
                  />
                  <span>{taskDisplay.sellData.srcChainSymbol}</span>
                </span>
                <span className="spacer"></span>
                <span className={`item3 strong`}>
                  -
                  {taskDisplay.sellData.srcAssetAmount +
                    " " +
                    taskDisplay.sellData.srcAssetSymbol}
                </span>
              </ItemLine3>
              <ItemLine style={{ marginTop: "10px" }}>
                <img style={{ marginLeft: "3px" }} src={arrowIco} alt="arrow" />
              </ItemLine>
              <ItemLine3
                style={{
                  marginTop: "10px",
                }}
              >
                <span className="item1">
                  <ImageWithFallback
                    src={taskDisplay.sellData.dstAssetImageUrl}
                    fallbackSrc={defaultTokenIcon}
                    StyledImg={Icon}
                  />
                  {taskDisplay.sellData.dstAssetSymbol || " "}
                </span>
                <span className="item2">
                  <ImageWithFallback
                    src={taskDisplay.sellData.dstChainImageUrl}
                    fallbackSrc={defaultChainIcon}
                    StyledImg={Icon}
                  />
                  <span>{taskDisplay.sellData.dstChainSymbol}</span>
                </span>
                <span className="spacer"></span>
                <span
                  className={`item3 strong`}
                  style={{
                    color: "var(--Kontos-Blue, #413DEC)",
                  }}
                >
                  +
                  {taskDisplay.sellData.dstAssetAmount +
                    " " +
                    taskDisplay.sellData.dstAssetSymbol}
                </span>
              </ItemLine3>
            </>
          )}

          {/* transfer data */}
          {typeof taskDisplay.transferData !== "undefined" && (
            <>
              <Divider isDashed={true} top={24} bottom={24} />
              <ItemTitle> {t("Transfer")}</ItemTitle>
              <ItemLine>
                <span>{t("Receiver")}:</span>
                <Icon src={avatarIcon} />
                <span>{taskDisplay.transferData.receiver}</span>
              </ItemLine>
              <ItemLine3>
                <span className="item1">
                  <ImageWithFallback
                    src={taskDisplay.transferData.assetImageUrl}
                    fallbackSrc={defaultTokenIcon}
                    StyledImg={Icon}
                  />
                  {taskDisplay.transferData.assetSymbol}
                </span>
                <span className="item2">
                  <ImageWithFallback
                    src={taskDisplay.chainImgUrl}
                    fallbackSrc={defaultChainIcon}
                    StyledImg={Icon}
                  />
                  <span>{taskDisplay.chainSymbol}</span>
                </span>
                <span className="spacer"></span>
                <span className={`item3 strong`}>
                  -
                  {taskDisplay.transferData.amount +
                    " " +
                    taskDisplay.transferData.assetSymbol}
                </span>
              </ItemLine3>
            </>
          )}

          {/* UserOp Data */}
          {typeof taskDisplay.userOpData !== "undefined" && (
            <>
              <Divider isDashed={true} top={24} bottom={24} />
              <ItemTitle> {t("Contract Interaction")}</ItemTitle>
              <ItemLine
                style={{ whiteSpace: "normal", wordBreak: "break-all" }}
              >
                <span>{t("Interacted with")}:</span>
                <span>
                  {shortAddress(taskDisplay.userOpData.contractAddress)}
                </span>
              </ItemLine>
            </>
          )}

          {/* Payment */}
          <Divider isDashed={true} top={24} bottom={24} />
          <ItemTitle> {t("Payment")}</ItemTitle>
          {!taskDisplay.fetching ? (
            taskDisplay.payments?.map((payment) => {
              return (
                <ItemLine3 key={payment.assetSymbol + payment.chainSymbol}>
                  <span className="item1">
                    <ImageWithFallback
                      src={payment.assetImgUrl}
                      fallbackSrc={defaultChainIcon}
                      StyledImg={Icon}
                    />
                    <span>{payment.assetSymbol}</span>
                  </span>
                  <span className="item2">
                    <ImageWithFallback
                      src={payment.chainImgUrl}
                      fallbackSrc={defaultChainIcon}
                      StyledImg={Icon}
                    />
                    <span>{payment.chainSymbol}</span>
                  </span>
                  <span className="spacer"></span>
                  <span
                    className={`item3 ${
                      taskDisplay?.status === "error"
                        ? getStatusClassName(taskDisplay?.status)
                        : "strong"
                    }`}
                  >
                    -{payment.assetAmount}
                  </span>
                </ItemLine3>
              );
            })
          ) : (
            <Skeleton count={2} style={{ marginTop: "16px" }} />
          )}

          {/* Others */}
          <Divider isDashed={true} top={24} bottom={24} />

          <ItemLine>
            <span style={{ maxWidth: "50%" }}>
              {taskDisplay.hasAaFee
                ? t("Total fee:") + ` (${taskDisplay.aaFeeText} fee)`
                : t("Total fee:")}
            </span>
            {!!taskDisplay.totalFee ? (
              <span>{taskDisplay.totalFee}</span>
            ) : (
              <div style={{ width: "50%", textAlign: "right" }}>
                <Skeleton />
              </div>
            )}
          </ItemLine>
          {isValidUserOpHash(taskPreview.opHash) && (
            <ItemLine>
              <span>{t("OpHash")}:</span>
              <span style={{ display: "flex", alignItems: "center" }}>
                {shortAddress(taskPreview.opHash)}
                <Copy text={taskPreview.opHash} />
              </span>
            </ItemLine>
          )}
          <ItemLine>
            <span>{t("Creating time:")}</span>
            <span>{taskDisplay.createTime}</span>
          </ItemLine>
        </Scrollable>

        {/* TODO: Explorer */}
        {/* {isValidUserOpHash(taskPreview.opHash) && (
          <FloatingBtnWraper>
            <FloatingButton
              onClick={() => {
                const url =
                  EXPLORER_URL + "task-detail?hash=" + taskPreview.opHash;
                openUrl(url);
              }}
              fixedIcon={OpenUrlIcon}
            >
              {t("View on Kontos Explorer")}
            </FloatingButton>
          </FloatingBtnWraper>
        )} */}
      </Container>
    );
  }
);
