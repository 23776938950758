import { ReactNode, useCallback, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import Header from "../../components/common/header";
import { useState } from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";
import { NoScrollerBarSheet } from "../../components/wrapper/ReactiveWrapper";
import { loadingStore } from "src/store/loadingStore";
import { t } from "i18next";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { toKontosAccount } from "src/type/zkkontos";
import toast from "src/components/toast/Toast";
import OneBtnPanel, {
  OneBtnPanelProps,
} from "src/components/panel/OneBtnPanel";
import { KontosClient } from "@zkkontos/kontos-sdk";
import googleAvatarIco from "src/assets/images/email/google-ava.svg";
import Divider from "src/components/divider/Divider";
import arrowIco from "src/assets/icons/email/arrow.svg";
import PoweredBy from "src/components/common/powerdBy";
import EditEmail from "./edit/EditEmail";
import { Unlock } from "../sign-up/unlock/Unlock";
import { PopupModal } from "src/components/popups/PopupModal";
import { pollSmartAccount } from "src/service/guardian-service";
import { Trans } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "@/router/router-config";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 60px;

  > .header {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;

const Avatar = styled.img`
  margin-top: 20px;

  width: 89px;
  height: 86px;
`;

const MainTextContainer = styled.div`
  margin: 16px 35px 0 35px;
`;

const MainText = styled.div`
  color: ${(props) => props.theme.colors.__deep_800};
  text-align: center;
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;

  .highlight {
    color: ${(props) => props.theme.colors.__kontos_blue};
    ${fontBold}
    font-size: 16px;
    line-height: 130%;
  }
`;

const OneBtnPanelContainer = styled.div`
  width: 100%;
  padding: 0 16px;
`;

const EditButton = styled.div`
  width: calc(100% -40px);
  border: none;
  border-radius: 99px;
  background: ${(props) => props.theme.colors.__kontos_blue};
  padding: 17px 30px 18px 87px;

  color: ${(props) => props.theme.colors.__white};
  ${fontBold};
  font-size: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .btn-icon {
    margin-left: 42px;
    width: 16px;
  }
`;

const Scrollable = styled.div`
  flex: 1;
  overflow: auto;
`;

const ToastStrong = styled.span`
  ${fontBold};
  color: #000;
`;

export const Email = observer(({}) => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [hideEdit, setHideEdit] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const [showEmailWarnModal, setShowEmailWarnModal] = useState<boolean>(false);
  const updatingInfo: OneBtnPanelProps | undefined = useMemo(() => {
    if (userStore.emailGuardianChanged) {
      return {
        type: "warning",
        title: t("Security email list being updated...") as string,
        content: t("inter_the_update_will_take_effect_in", {
          time: userStore.updateGuardianDeadlineTimeAgo,
        }),
        // content:
        //   (t(`The update will take effect in`) as string) +
        //   statusKeeper.updateGuardianDeadlineTimeAgo,
        btnText: t("Cancel") as string,
        onClick: () => {
          setShowPinModal(true);
        },
        onRightTopClick: undefined,
      };
    } else {
      return undefined;
    }
  }, [userStore.updateGuardianDeadlineTimeAgo, userStore.emailGuardianChanged]);

  const mainText = useMemo<ReactNode>(() => {
    const emailGuardiansLength = userStore.accountInfo?.emailGuardians?.length;
    const actionThreshold = userStore.accountInfo?.actionThreshold?.toString();
    const name = userStore.accountInfo?.name?.replaceAll(".os", "") + ".os";

    return (
      <MainText>
        <Trans i18nKey={"trans-You have set"}>
          You have set{" "}
          <span className="highlight">{{ emailGuardiansLength } as any}</span>{" "}
          security email address and your current threshold is{" "}
          <span className="highlight">{{ actionThreshold } as any}</span>
          .
          <br />
          <span className="highlight">{{ name } as any}</span>
        </Trans>
      </MainText>
    );
  }, [userStore.accountInfo]);

  const handleEditOnClick = useCallback(() => {
    if (userStore.emailGuardianChanged) {
      toast({
        type: "warning",
        text: t("Please handle the current security email change first"),
      });
      return;
    }
    if (userStore.guardianChanged) {
      toast({
        type: "warning",
        text: (
          <span>
            You have an guardian change operation in progress. Please wait for
            it to complete in {userStore.updateGuardianDeadlineTimeAgo} or
            manually cancel it through <ToastStrong>/editguardian</ToastStrong>{" "}
            command from bot
          </span>
        ),
        autoClose: 10000,
      });
      return;
    }
    if (
      userStore.accountInfo?.guardians &&
      userStore.accountInfo?.guardians?.length > 0
    ) {
      setShowEmailWarnModal(true);
      return;
    }
    setShowEditModal(true);
  }, [
    userStore.accountInfo?.guardians,
    userStore.emailGuardianChanged,
    userStore.guardianChanged,
    userStore.updateGuardianDeadlineTimeAgo,
  ]);

  const [innerModalOpen, setInnerModalOpen] = useState(false);

  const closeModals = () => {
    setShowPinModal(false);
    setShowEditModal(false);
  };

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: !innerModalOpen,
  });

  const onInnerModalChange = useCallback((isOpen: boolean) => {
    setInnerModalOpen(isOpen);
  }, []);

  const handleEditSuccess = useCallback(async (isRevert = false) => {
    setShowEditModal(false);
    try {
      loadingStore.showLoading();
      setHideEdit(true);
      const newSmartAccount = await pollSmartAccount(isRevert);
      if (newSmartAccount) {
        userStore.setAccountInfo(toKontosAccount(newSmartAccount));
        setHideEdit(false);
      } else {
        toast({
          type: "warning",
          text: t("Sync failed, please check later "),
        });
      }
    } catch (e) {
      toast({
        type: "error",
        text: t("Failed to fetch latest info"),
      });
    } finally {
      loadingStore.hideLoading();
    }
  }, []);

  const handleCancelEmailChanges = useCallback(
    async (cli: KontosClient) => {
      try {
        loadingStore.showLoading();
        await cli.revertUpdateGuardianInfo();
        toast({
          type: "success",
          text: t("Your security email change is successfully reverted"),
        });
        await handleEditSuccess(true);
      } catch (e) {
        console.log(e);
        const errorMessage =
          e instanceof Error
            ? e.message
            : t("Failed to revert your security email change");
        toast({
          type: "error",
          text: errorMessage,
        });
      } finally {
        loadingStore.hideLoading();
      }
    },
    [handleEditSuccess]
  );

  const handleEmailChanges = useCallback(
    (cli: KontosClient) => {
      setShowPinModal(false);
      if (updatingInfo?.type === "warning") {
        handleCancelEmailChanges(cli);
      }
    },
    [handleCancelEmailChanges, updatingInfo?.type]
  );

  return (
    <Container ref={wrapperRef}>
      <div className="header">
        <Header
          title={t("Security Email")}
          padding="8px 24px"
          enableBack={true}
          callBack={() => navigate(ROUTE_HOME)}
          rightBtnText={t("Help?")}
          rightBtnCallBack={handleEditOnClick}
        />
      </div>
      <Avatar src={googleAvatarIco} />
      <MainTextContainer>{mainText}</MainTextContainer>
      <Divider top={16} bottom={16} left={45} right={45} isDashed={false} />

      {updatingInfo ? (
        <OneBtnPanelContainer>
          <OneBtnPanel
            type={updatingInfo.type}
            title={updatingInfo.title}
            content={updatingInfo.content}
            btnText={updatingInfo.btnText}
            onClick={updatingInfo.onClick}
            onRightTopClick={updatingInfo.onRightTopClick}
          />
          <div style={{ marginBottom: "16px" }} />
        </OneBtnPanelContainer>
      ) : (
        <EditButton onClick={handleEditOnClick}>
          {t("Edit Security Email")}
          <img className="btn-icon" src={arrowIco} alt="" />
        </EditButton>
      )}

      <Scrollable></Scrollable>

      <PoweredBy />

      {/* Edit Guardian Sheet */}
      <NoScrollerBarSheet
        isOpen={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        mountPoint={domNode}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <EditEmail
              onClose={() => {
                setShowEditModal(false);
              }}
              onSuccess={handleEditSuccess}
              onInnerModalChange={onInnerModalChange}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => {
            setShowEditModal(false);
          }}
        />
      </NoScrollerBarSheet>

      <NoScrollerBarSheet
        isOpen={showPinModal}
        onClose={() => setShowPinModal(false)}
        mountPoint={domNode}
        disableScrollLocking={true}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Unlock onSuccess={handleEmailChanges} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setShowPinModal(false)} />
      </NoScrollerBarSheet>

      {showEmailWarnModal && (
        <PopupModal
          type="warning"
          onConfirm={() => {
            setShowEmailWarnModal(false);
            setShowEditModal(true);
          }}
          onCancel={() => {
            setShowEmailWarnModal(false);
          }}
          onOutClick={() => {
            setShowEmailWarnModal(false);
          }}
          title={t("You already have guardian")}
          desc={t("Enabling the security email will invalidate your guardian")}
          btnTxt="Continue"
        />
      )}
    </Container>
  );
});
