import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import { RespAccountBalancesV2 } from "@zkkontos/kontos-sdk/src/api/assetApi";
import { SmartAccount } from "@zkkontos/kontos-sdk/src/codec/kontos/aa/v1/aa";
import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import { Dapp as KontosDapp } from "src/apis/types/DappTypes";
import Long from "long";
import {
  ApiChain,
  AssetChange as KontosAssetChange,
  SingerSendOrReceive,
  FtAsset as ApiFtAsset,
  ApiAccountDetail,
  ApiTaskDetail,
  ApiTask,
  ApiBlockchainAccount,
  FtAssetDetail as ApiFtAssetDetail,
} from "src/apis/types";

function isSmartAccount(
  account: ApiAccountDetail | SmartAccount
): account is SmartAccount {
  return (account as SmartAccount).name !== undefined;
}

const convertAddressToApiBlockchainAccount = (addressObj: {
  [key: string]: string;
}): { [key: string]: ApiBlockchainAccount } => {
  return Object.fromEntries(
    Object.entries(addressObj).map(([key, value]) => [
      key,
      {
        aaAddress: value,
        createdAt: -1,
      },
    ])
  );
};

export const toKontosAccount = (
  account: ApiAccountDetail | SmartAccount | string
): Account => {
  if (typeof account === "string") {
    return {
      createdAt: -1,
      name: account,
      pubKey: "",
      futurePubKey: "",
      recoveryPubKey: "",
      blockChainAccounts: {},
      guardians: [],
      futureGuardians: [],
      actionThreshold: 0,
      futureThreshold: 0,
      updateGuardiansDeadline: Long.fromValue(0).toNumber(),
      accountStatus: 0,
      inviter: "",
      nameAddress: "",
      kontosAddress: "",
      emailGuardians: [],
      futureEmailGuardians: [],
      wards: [],
    };
  }
  if (isSmartAccount(account)) {
    return {
      createdAt: -1,
      name: account.name,
      pubKey: account.pubKey,
      futurePubKey: account.futurePubKey,
      recoveryPubKey: account.recoveryPubKey,
      blockChainAccounts: convertAddressToApiBlockchainAccount(
        account.blockchainAccounts
      ),
      guardians: account.guardians,
      futureGuardians: account.futureGuardians,
      actionThreshold: account.actionThreshold,
      futureThreshold: account.futureThreshold,
      updateGuardiansDeadline: Long.fromValue(
        account.updateGuardiansDeadline
      ).toNumber(),
      accountStatus: account.accountStatus,
      inviter: account.inviter,
      nameAddress: KontosQueryCli.nameAddress(account.name),
      kontosAddress: KontosQueryCli.kontosAddress(account.name),
      emailGuardians: account.emailGuardians.map((item) => item.mailHash),
      futureEmailGuardians: account.futureEmailGuardians.map(
        (item) => item.mailHash
      ),
      wards: [],
    };
  } else {
    return account as Account;
  }
};

export const toFtAsset = (data: UniformedPayment): FtAsset => {
  return {
    chainIndex: data.chainIndex,
    address: data.assetAddress,
    name: data.assetName,
    symbol: data.assetSymbol,
    decimals: data.assetDecimals,
    imageUrl: data.assetImageUrl,
    usdPrice: data.assetUsdPrice,
    isWhitelist: true,
    balance: {
      id: "",
      balance: "",
      balanceUsdAmount: "",
    },
  };
};

export interface Account extends ApiAccountDetail {}

export interface FtAsset extends ApiFtAsset {}

export interface FtAssetDetail extends ApiFtAssetDetail {}

export interface FtAssetBase {
  chainIndex: string;
  address: string;
}

export interface Chain extends ApiChain {}

export interface Task extends ApiTask {}

export interface TaskDetail extends ApiTaskDetail {}

export enum ActivityType {
  Send = "Send",
  Receive = "Receive",
}

export const toActivityType = (
  singerSendOrReceive: SingerSendOrReceive
): ActivityType => {
  if (singerSendOrReceive === SingerSendOrReceive.Send)
    return ActivityType.Send;
  return ActivityType.Receive;
};

export interface Dapp extends KontosDapp {}

export interface AssetChange extends KontosAssetChange {}
