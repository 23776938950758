import { TEMP_EXPLORER_URL } from "@/config";
import { API } from "./core/API";
import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import * as apiTypes from "./types";

export interface ReqAccount {
  account: string;
  offset: number;
  limit: number;
}

export interface RespAccount {
  accountDetail: apiTypes.ApiAccountDetail;
  accounts: apiTypes.ApiAccount[];
  hasMore: boolean;
}

export const callAccount = async (
  data: ReqAccount,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccount> => {
  return await request<ReqAccount, RespAccount>(
    API.maa.account,
    data,
    endpoint
  );
};

export interface ReqSearchAccountNames {
  regex: string;
}

export interface RespSearchAccountNames {
  accounts: string[];
}

export const callSearchAccountNames = async (
  data: ReqSearchAccountNames,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespSearchAccountNames> => {
  return await request<ReqSearchAccountNames, RespSearchAccountNames>(
    API.maa.searchAccountNames,
    data,
    endpoint
  );
};

export interface ReqAccountThresholdActions {
  account: string;
}

export interface RespAccountThresholdActions {
  actions: apiTypes.ApiThresholdAction[];
}

export const callAccountThresholdActions = async (
  data: ReqAccountThresholdActions,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccountThresholdActions> => {
  return await request<ReqAccountThresholdActions, RespAccountThresholdActions>(
    API.maa.accountThresholdActions,
    data,
    endpoint
  );
};

export interface ReqAccountNewPubKeyAction {
  account: string;
}

export interface RespAccountNewPubKeyAction {
  action: apiTypes.ApiThresholdAction;
}

export const callAccountNewPubKeyAction = async (
  data: ReqAccountNewPubKeyAction,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccountNewPubKeyAction> => {
  return await request<ReqAccountNewPubKeyAction, RespAccountNewPubKeyAction>(
    API.maa.accountNewPubKeyAction,
    data,
    endpoint
  );
};

export interface ReqNonce {
  account: string;
}

export interface RespNonce {
  nonce: number;
}

export const callNonce = async (
  data: ReqNonce,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespNonce> => {
  return await request<ReqNonce, RespNonce>(API.maa.nonce, data, endpoint);
};
