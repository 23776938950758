import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PINCodeInput } from "../images/PINCodeInput";
import { useCallback, useEffect, useRef, useState } from "react";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const RetryLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  color: var(--Deep-800, #1a2535);
  ${fontBold};
  font-size: 16px;
  line-height: 20px;
`;

const RetryWrapper = styled.div`
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  color: var(--Deep-400, #80868f);
`;

const RetryButton = styled.button<{ $retrying: boolean }>`
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: ${(props) =>
    props.disabled || props.$retrying ? "not-allowed" : "pointer"};

  color: ${(props) =>
    props.disabled && !props.$retrying
      ? "var(--Deep-400, #80868f)"
      : "var(--Kontos-Blue, #413DEC)"};

  path {
    fill: ${(props) =>
      props.disabled && !props.$retrying
        ? "var(--Deep-400, #80868f)"
        : "var(--Kontos-Blue, #413DEC)"};
  }

  svg {
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation: ${(props) => props.$retrying && "spin 1s linear infinite"};
  }
`;

const Countdown = styled.span`
  height: 16px;
  line-height: 16px;
`;

const RetryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path d="M11.0007 6.43753C10.904 7.57745 10.4193 8.65014 9.62689 9.47765C8.83452 10.3052 7.78216 10.8377 6.64441 10.987C5.50666 11.1363 4.35191 10.8933 3.37173 10.2984C2.39155 9.70347 1.64486 8.79236 1.25555 7.71621C0.866245 6.64007 0.857712 5.46358 1.23137 4.38194C1.60503 3.30029 2.33842 2.37851 3.30987 1.76951C4.28131 1.16052 5.43242 0.900925 6.57222 1.03379C7.71202 1.16667 8.77199 1.68401 9.57628 2.50001H8.01039C7.87737 2.50001 7.74979 2.55269 7.65573 2.64646C7.56167 2.74023 7.50883 2.86741 7.50883 3.00001C7.50883 3.13262 7.56167 3.2598 7.65573 3.35357C7.74979 3.44734 7.87737 3.50002 8.01039 3.50002H10.0884C10.3354 3.49988 10.5722 3.40202 10.7468 3.22792C10.9215 3.05382 11.0197 2.81773 11.0198 2.57151V0.500002C11.0198 0.367393 10.9669 0.240216 10.8729 0.146447C10.7788 0.0526787 10.6512 0 10.5182 0C10.3852 0 10.2576 0.0526787 10.1636 0.146447C10.0695 0.240216 10.0167 0.367393 10.0167 0.500002V1.53901C9.00784 0.640368 7.72444 0.107242 6.37402 0.0258558C5.0236 -0.0555309 3.68511 0.31958 2.57505 1.09052C1.46499 1.86146 0.648256 2.98315 0.256945 4.27418C-0.134366 5.5652 -0.0773744 6.95007 0.418704 8.2048C0.914782 9.45954 1.82094 10.5108 2.99062 11.1885C4.1603 11.8662 5.5251 12.1307 6.8643 11.9393C8.2035 11.748 9.43878 11.1118 10.3704 10.1338C11.3019 9.15584 11.8753 7.89316 11.9978 6.55003C12.0043 6.4804 11.9962 6.41019 11.9739 6.34387C11.9517 6.27755 11.9158 6.21658 11.8686 6.16484C11.8215 6.11309 11.764 6.07172 11.6999 6.04334C11.6358 6.01497 11.5664 6.00022 11.4963 6.00003C11.3739 5.99856 11.2554 6.04254 11.1638 6.12342C11.0722 6.2043 11.0141 6.31627 11.0007 6.43753Z" />
    </svg>
  );
};

interface IProps {
  countdown: number;
  retrying: boolean;
  verifying: boolean;
  onRetry: () => Promise<void>;
  onVerify: (code: string) => Promise<boolean>;
}

export const CodeVerifier: React.FC<IProps> = ({
  countdown,
  retrying,
  verifying,
  onRetry,
  onVerify,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<{ resetValue: () => void }>(null);
  const [code, setCode] = useState("");

  const clearInput = useCallback(() => {
    inputRef.current?.resetValue();
  }, []);

  useEffect(() => {
    const verify = async () => {
      const success = await onVerify(code);
      if (!success) {
        setCode("");
        clearInput();
      }
    };

    if (code.length === 6) {
      verify();
    }
  }, [clearInput, code, code.length, onVerify]);

  return (
    <Container>
      <RetryLine>
        <Title>{t("Email Verification Code")}</Title>
        <RetryWrapper>
          <RetryButton
            $retrying={retrying}
            disabled={countdown > 0 || verifying || retrying}
            onClick={onRetry}
          >
            <RetryIcon />
            <span>{t("Re-send")}</span>
          </RetryButton>
          {countdown > 0 && <Countdown>{countdown}s</Countdown>}
        </RetryWrapper>
      </RetryLine>

      <PINCodeInput
        size="small"
        onPINChange={setCode}
        ref={inputRef}
        disabled={verifying || retrying}
        showValue
        type="verification"
      />
    </Container>
  );
};
