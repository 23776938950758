import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { EXPLORER_KONTOS_URL, TEMP_EXPLORER_URL } from "src/config";
import { API } from "./core/API";
import { FtAsset, MediaInfo } from "./types";
import { FtAssetDetail } from "@/type/zkkontos";

export interface ReqEmailRegisterCode {
  account: string;
  email: string;
}

export interface RespEmailRegisterCode {
  success: boolean;
  retryTime: number;
}

export const callEmailRegisterCode = async (
  data: ReqEmailRegisterCode,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespEmailRegisterCode> => {
  return await request<ReqEmailRegisterCode, RespEmailRegisterCode>(
    API.aa.emailRegisterCode,
    data,
    endpoint
  );
};

export interface ReqFtAssetDetail {
  coinGeckoCoinId?: string;
  chainIndex: string;
  ftAssetAddress: string;
  scopeChatAi: boolean;
}

export interface RespFtAssetDetail {
  description: string;
  medias: MediaInfo[];
  scopeChatAiData: string;
  scopeChatAiScore: number;
}

export const callFtassetDetail = async (
  data: ReqFtAssetDetail,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespFtAssetDetail> => {
  return await request<ReqFtAssetDetail, RespFtAssetDetail>(
    API.asset.ftassetDetail,
    data,
    endpoint
  );
};

export interface ReqFtAsset {
  chainIndex?: string;
  regex?: string;
  isRecommended?: boolean;
  account?: string;
  isWhitelist?: boolean;
  displayOnlyOwnedItems?: boolean;
  offset: number;
  limit: number;
}

export interface RespFtAsset {
  ftAssets: FtAsset[] | null;
  ftAssetDetail: FtAssetDetail | null;
  hasMore: boolean;
}

export const callFtAsset = async (
  data: ReqFtAsset,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespFtAsset> => {
  return await request<ReqFtAsset, RespFtAsset>(
    API.masset.ftasset,
    data,
    endpoint
  );
};

let ftAssetController: AbortController;

export const callFtAssetWithAbort = async (
  data: ReqFtAsset,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespFtAsset> => {
  ftAssetController && ftAssetController.abort();
  ftAssetController = new AbortController();
  return await request<ReqFtAsset, RespFtAsset>(
    API.masset.ftasset,
    data,
    endpoint,
    ftAssetController.signal
  );
};

export interface ReqAccountsBalances {
  accounts: string[];
}

export interface RespAccountsBalances {
  accountUsdBalances: { [key: string]: string };
}

export const callAccountsBalances = async (
  data: ReqAccountsBalances,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespAccountsBalances> => {
  return await request<ReqAccountsBalances, RespAccountsBalances>(
    API.masset.accountsBalances,
    data,
    endpoint
  );
};
