import styled from "styled-components";
import loadingGif from "src/components/input/loading.gif";
import { ReactNode } from "react";
import successIcon from "src/assets/icons/receive/success.svg";
import failIcon from "src/assets/icons/receive/fail.svg";
import Modal from "./Modal";
import { fontBold, fontH5 } from "@/style/style.global";

const Wrapper = styled.div`
  width: 343px;
  padding: 16px;
`;

const Container = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 24px;
`;

const Title = styled.span<{
  $status?: "pending" | "success" | "error" | "idle";
}>`
  color: ${(props) =>
    props.$status === "error"
      ? "var(--error-notice, #FF1E2B)"
      : props.$status === "success"
        ? "var(--Success, #10CE5C)"
        : "var(--Deep-800, #1a2535)"};
  text-align: center;

  /* H5 */
  ${fontH5}
`;

const IconArea = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingGif = styled.img`
  width: 100px;
  height: 20px;
  flex-shrink: 0;
`;

const SuccessIcon = styled.img`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
`;

const FailIcon = styled.img`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
`;

const DescArea = styled.div`
  color: var(--Deep-400, #80868f);
  text-align: center;
  overflow: hidden;

  /* Description */
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  status?: "pending" | "success" | "error" | "idle";
  desc?: string;
  buttons?: ReactNode[];
  needBackDrop?: boolean;
  onClose?: () => void;
  zExtraIndex?: number;
}

export const StatusModal: React.FC<IProps> = ({
  className,
  style,
  desc,
  buttons,
  status,
  title,
  needBackDrop = false,
  onClose,
  zExtraIndex = 0,
}) => {
  if (needBackDrop) {
    return (
      <Modal onClose={onClose} zExtraIndex={zExtraIndex}>
        <Wrapper>
          <Container className={className} style={style}>
            {title && <Title $status={status}>{title}</Title>}

            <IconArea>
              {status === "pending" && <LoadingGif src={loadingGif} />}
              {status === "success" && <SuccessIcon src={successIcon} />}
              {status === "error" && <FailIcon src={failIcon} />}
            </IconArea>
            {desc && <DescArea>{desc}</DescArea>}

            {buttons && (
              <Buttons>
                {buttons.map((button, index) => (
                  <div key={index}>{button}</div>
                ))}
              </Buttons>
            )}
          </Container>
        </Wrapper>
      </Modal>
    );
  }

  return (
    <Container className={className} style={style}>
      {title && <Title $status={status}>{title}</Title>}

      <IconArea>
        {status === "pending" && <LoadingGif src={loadingGif} />}
        {status === "success" && <SuccessIcon src={successIcon} />}
        {status === "error" && <FailIcon src={failIcon} />}
      </IconArea>
      {desc && <DescArea>{desc}</DescArea>}

      {buttons && (
        <Buttons>
          {buttons.map((button, index) => (
            <div key={index}>{button}</div>
          ))}
        </Buttons>
      )}
    </Container>
  );
};
