import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import toast from "src/components/toast/Toast";
import { loadingStore } from "src/store/loadingStore";
import { isSameFtAsset, parseApiErrorMessage } from "src/utils/zkkontosHelper";
import KontosNumber from "src/utils/KontosNumber";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import {
  DEFAULT_DECIMAL,
  DEFAULT_DISPLAY_PRECESION,
  DEFAULT_SLIPPAGE,
} from "src/config";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import SpotAmountSlider from "src/pages/trade/spot/SpotAmountSlider";
import DarkWhiteLoadingSvg from "src/assets/icons/dark-white-loading.svg";
import { FtAsset } from "src/type/zkkontos";
import { fetchSpecificFtAsset } from "src/service/trade-service";
import {
  bulkConvertUniformedPaymentToFtAssetBase,
  calculateBySlippage,
  isFavoriteFtAsset,
  shortAddress,
} from "src/utils/helper";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import { useTranslation } from "react-i18next";
import questionIco from "src/assets/icons/trade/question.svg";
import { TipsBuyMaxAvailableV2 } from "src/components/tips/TipsText";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStores } from "src/hooks/useStore";
import { ContractInteractionType } from "src/pages/contract-interaction/types";
import FloatingButton from "../FloatingButton";
import { TargetAssetPanel } from "./TargetAssetPanel";
import { debounce } from "lodash";
import {
  ShowAssetType,
  ToBuyAssetSelector,
} from "../asset-select/ToBuyAssetSelector";
// import { PriceToggle } from "./PriceToggle";
import { TradeInput } from "../TradeInput";
import { runInAction } from "mobx";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { SlippageSetup } from "../common/SlippageSetup";
import { TradeBreakdownViewV2 } from "../common/TradeBreakdownViewV2";
import {
  BreakDownAssetIcon,
  BreakDownLineWrapper,
  DarkBreakDownText,
  LightBreakDownText,
  TradeBreakdownEditableItem,
} from "../common/TradeBreakdownEditableItem";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import { ReceiverSelectModal } from "../common/ReceiverSelectModal";
import { ethers } from "ethers";
import Floater from "react-floater";
import { TradeAiPanel } from "../common/TradeAiPanel";
import useAuthCheck from "@/hooks/useAuthCheck";
import PrimaryButton from "@/components/button/PrimaryButton";
import { fontBold, fontDescription, fontH5 } from "@/style/style.global";
import {
  ROUTE_AUTH,
  ROUTE_TRADE,
  ROUTE_TRADE_BUY,
} from "@/router/router-config";
import { TradeMode } from "@/store/trade/TradeStore";
import { TxConfirmation } from "@/pages/contract-interaction/TxConfirmation";
import { PaymentInitData, PaymentProvider } from "@/store/trade/PaymentStore";
import * as TradeService from "src/service/trade-service";
import { AssetIconGroup } from "@/components/icons/AssetIconGroup";
import { PaymentPlanFlow } from "@/flows/payment-plan/PaymentPlanFlow";
import { filterUserHoldings } from "@/store/storeHelper";

export enum CallTaskDataPurpose {
  Input,
  Result,
  Both,
}

export enum BuyErrorType {
  AmountEmpty,
  QuoteFailed,
  TaskDataFailed,
  NoAccount,
  NoAsset,
  OtherNoNeedNotify,
}

export const getBuyErrorText = (errorType: BuyErrorType) => {
  switch (errorType) {
    case BuyErrorType.NoAsset:
      return "Please choose an asset";
    case BuyErrorType.AmountEmpty:
      return "Please input amount";
    case BuyErrorType.QuoteFailed:
      return "Quote failed";
    case BuyErrorType.TaskDataFailed:
      return "Generate payment failed, please try again later or choose another asset to buy";
    case BuyErrorType.NoAccount:
      return "No account info, please refresh the page and try again";
    default:
      return "Unknown error";
  }
};

const MAX_AVAILABLE_HELPER_CLASSNAME = "trade-question";

const Container = styled.div`
  padding: 16px 16px calc(16px + var(--navi-height)) 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: visible;
  gap: 16px;
`;

const TradePanel = styled.div`
  z-index: 2;
  padding: 12px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
`;

const TradeAmountBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpotAmountSliderContainer = styled.div`
  margin: 20px 9.5px 0 9.5px;
`;

// TODO: Market Price Toggle
// const StyledPriceToggle = styled(PriceToggle)`
//   align-self: flex-start;
// `;

const TradeAmountValueText = styled.span`
  color: var(--Deep-400, #80868f);
  ${fontDescription}
`;

const BalanceBox = styled.div`
  z-index: 20;
  margin-top: -2px;
  padding: 20px 0 0 0;
  text-align: right;

  background: var(--White, #fff);

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 12px;
`;

const BalanceLine = styled.div<{ $clickable?: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.$clickable
      ? "cursor: pointer; -webkit-tap-highlight-color: transparent;"
      : ""}

  @media (hover: hover) {
    &:hover {
      img {
        margin-top: -2px;
      }
    }
  }

  &:active {
    img {
      margin-top: -2px;
    }
  }
`;

const MaxAvailableQuestionIcon = styled.img`
  margin-left: 6px;
  width: 12px;
  height: 12px;
  cursor: help;
`;

const CommonText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const StrongText = styled.span`
  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

export const BottomPlaceholder = styled.div`
  width: 100%;
`;

export const BlankFilledArea = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const DarkBtnText = styled.span`
  color: var(--Deep-600, #4d5662);
  ${fontBold}
  font-size: 18px;
`;

export const LightBtnText = styled.span`
  color: var(--White, #fff);
  ${fontBold}
  font-size: 18px;
`;

const ConnectButton = styled(PrimaryButton)`
  width: 100%;
  padding: 17px;
  ${fontH5}
`;

const precision = 7;
const displayPrecision = DEFAULT_DISPLAY_PRECESION;
const DEBOUNCE_TIME = 500;
const REFRESH_TIME = 60000;
const MIN_SPOT_INPUT = 0.01;

interface IProps {
  viewAssetInfo: (asset: FtAsset) => void;
}

export const TradeBuy: React.FC<IProps> = observer(({ viewAssetInfo }) => {
  const { userStore, uiStore, buyStore, chainStore, favStore, tradeStore } =
    useStores();
  const { authed } = useAuthCheck();
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSelectAssets, setShowSelectAssets] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [innerModalOpen, setInnerModalOpen] = useState(false);
  const [showTips, setShowTips] = useState<boolean>(false);
  // Input Loading
  const [inputLoadingCount, setInputLoadingCount] = useState(0);
  const addInputLoadingCount = useCallback(() => {
    setInputLoadingCount((prevCount) => prevCount + 1);
  }, []);
  const minusInputLoadingCount = useCallback(() => {
    setInputLoadingCount((prevCount) => prevCount - 1);
  }, []);
  // Result Loading
  const [resultLoadingCount, setResultLoadingCount] = useState(0);
  const addResultLoadingCount = useCallback(() => {
    setResultLoadingCount((prevCount) => prevCount + 1);
  }, []);
  const minusResultLoadingCount = useCallback(() => {
    setResultLoadingCount((prevCount) => prevCount - 1);
  }, []);
  const [showSlippage, setShowSlippage] = useState<boolean>(false);
  const [showReceiver, setShowReceiver] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [sliderValue, setSliderValue] = useState<KontosNumber>(
    new KontosNumber(0)
  );
  const [showAiScore, setShowAiScore] = useState<boolean>(true);
  const navigate = useNavigate();
  const [paymentInitData, setPaymentInitData] = useState<
    PaymentInitData | undefined
  >(undefined);
  const [fetchingToBuyFtAsset, setFetchingToBuyFtAsset] =
    useState<boolean>(false);
  const [showPaymentPlanSheet, setShowPaymentPlanSheet] =
    useState<boolean>(false);

  const openSelectAssetsSheet = useCallback((toReplace?: FtAsset) => {
    setShowSelectAssets(true);
  }, []);
  const closeSelectAssetsSheet = useCallback(() => {
    setShowSelectAssets(false);
  }, []);

  const closeModals = useCallback(() => {
    closeSelectAssetsSheet();
    setShowConfirm(false);
    setShowReceiver(false);
    setShowSlippage(false);
    setShowReceiver(false);
    setShowPaymentPlanSheet(false);
  }, [closeSelectAssetsSheet]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: !innerModalOpen && !searchParams.get("showQuote"),
  });

  const onInnerModalChange = useCallback((isOpen: boolean) => {
    setInnerModalOpen(isOpen);
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (buyStore.toBuyFtAsset !== undefined) {
      newSearchParams.set("chainIndex", buyStore.toBuyFtAsset.chainIndex);
      newSearchParams.set("assetAddress", buyStore.toBuyFtAsset.address);
    } else {
      newSearchParams.delete("chainIndex");
      newSearchParams.delete("assetAddress");
    }

    if (
      buyStore.toBuyFtAssetValue !== undefined &&
      buyStore.toBuyFtAssetValue.gt(0)
    ) {
      newSearchParams.set(
        "usdValue",
        buyStore.toBuyFtAssetValue.toStringWithDecimal(DEFAULT_DECIMAL)
      );
    } else {
      newSearchParams.delete("usdValue");
    }

    setSearchParams(newSearchParams);
  }, [
    buyStore.toBuyFtAsset,
    buyStore.toBuyFtAssetValue,
    searchParams,
    setSearchParams,
  ]);

  // init to buy asset
  useEffect(() => {
    const initToBuyFtAsset = async () => {
      const copyTradeObj = {
        chainIndex: searchParams.get("chainIndex") as string,
        assetAddress: searchParams.get("assetAddress") as string,
        usdValue: searchParams.get("usdValue") as string,
      };
      // If the asset to search is the same as current one, return
      if (isSameFtAsset(copyTradeObj, buyStore.toBuyFtAsset)) return;
      // If the asset to search is valid
      // We fetch this asset and set it toBuyAsset
      if (
        !!copyTradeObj.assetAddress &&
        !!copyTradeObj.chainIndex &&
        copyTradeObj.usdValue
      ) {
        try {
          const toSearchAsset = await fetchSpecificFtAsset(
            copyTradeObj.chainIndex,
            copyTradeObj.assetAddress
          );
          if (!toSearchAsset) {
            throw new Error(t("Failed to get target token info"));
          }
          const toPayUsd = new KontosNumber(
            copyTradeObj.usdValue,
            DEFAULT_DECIMAL
          );
          runInAction(() => {
            buyStore.setToBuyFtAsset(toSearchAsset);
            if (toPayUsd.gt(0)) {
              buyStore.setToBuyFtAssetValue(toPayUsd.round(precision));
            }
          });
        } catch (e) {
          const errorMessage =
            e instanceof Error ? e.message : t("System Error");
          toast({
            type: "error",
            text: errorMessage,
          });
        }
        return;
      }
      // If to buy asset is already set, return
      // We return it here since searching order is prior to existing asset
      if (buyStore.toBuyFtAsset !== undefined) {
        return;
      }
      // Finally, user favorite asset is prior to recommend one
      // But if not logged in, we use recommend one
      try {
        const targetAsset = await TradeService.fetchInitRecommendedFtAsset();
        if (typeof targetAsset !== "undefined") {
          runInAction(() => {
            buyStore.setToBuyFtAsset(targetAsset);
          });
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : t("Failed to init default asset");
        toast({
          type: "error",
          text: errorMessage,
        });
      }
    };

    const execute = async () => {
      setFetchingToBuyFtAsset(true);
      await initToBuyFtAsset();
      setFetchingToBuyFtAsset(false);
    };

    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTaskDataLoading = useCallback(
    (purpose: CallTaskDataPurpose) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          addInputLoadingCount();
          break;
        case CallTaskDataPurpose.Result:
          addResultLoadingCount();
          break;
        case CallTaskDataPurpose.Both:
          loadingStore.showLoading();
          break;
        default:
          break;
      }
    },
    [addInputLoadingCount, addResultLoadingCount]
  );

  const endTaskDataLoading = useCallback(
    (purpose: CallTaskDataPurpose) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          minusInputLoadingCount();
          break;
        case CallTaskDataPurpose.Result:
          minusResultLoadingCount();
          break;
        case CallTaskDataPurpose.Both:
          loadingStore.hideLoading();
          break;
        default:
          break;
      }
    },
    [minusInputLoadingCount, minusResultLoadingCount]
  );

  const prepareTaskDataLoading = useCallback(
    (
      purpose: CallTaskDataPurpose,
      debounceTime: number = DEBOUNCE_TIME + 50
    ) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          addInputLoadingCount();
          setTimeout(() => {
            minusInputLoadingCount();
          }, debounceTime);
          break;
        case CallTaskDataPurpose.Result:
          addResultLoadingCount();
          setTimeout(() => {
            minusResultLoadingCount();
          }, debounceTime);
          break;
        default:
          break;
      }
    },
    [
      addInputLoadingCount,
      addResultLoadingCount,
      minusInputLoadingCount,
      minusResultLoadingCount,
    ]
  );

  const callFetchAndSetTaskData = useCallback(
    async (purpose: CallTaskDataPurpose, successCallback?: () => void) => {
      try {
        startTaskDataLoading(purpose);
        const resp = await buyStore.fetchAndSetTaskData();
        if (resp?.isChainLiquidityInsufficient === true) {
          toast({
            type: "error",
            text: t("Chain liquidity insufficient, please try other chains"),
          });
          return;
        }
        !!resp && successCallback?.();
        return resp;
      } catch (e) {
        console.log("Failed to fetch taskdata", e);
        const errorMessage =
          e instanceof Error
            ? parseApiErrorMessage(e).message
            : "An unknown error occurred when calculating task data";
        if (errorMessage.toLocaleLowerCase() !== "canceled") {
          toast({ type: "error", text: errorMessage });
          runInAction(() => {
            buyStore.resetInput();
          });
        }
      } finally {
        endTaskDataLoading(purpose);
      }
    },
    [startTaskDataLoading, buyStore, t, endTaskDataLoading]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchTaskData = useCallback(
    debounce((purpose: CallTaskDataPurpose, successCallback?: () => void) => {
      callFetchAndSetTaskData(purpose, successCallback);
    }, DEBOUNCE_TIME),
    []
  );

  const prepareAndDebouncedFetchTaskData = useCallback(
    (purpose: CallTaskDataPurpose, successCallback?: () => void) => {
      prepareTaskDataLoading(purpose);
      debouncedFetchTaskData(purpose, successCallback);
    },
    [debouncedFetchTaskData, prepareTaskDataLoading]
  );

  const handleViewAssetInfo = useCallback(
    (asset: FtAsset) => {
      viewAssetInfo(asset);
    },
    [viewAssetInfo]
  );

  // Since maxavailable is fetched from taskdata
  // Fetch taskdata each time target asset changes
  useEffect(() => {
    if (buyStore.toBuyFtAsset && !buyStore.toBuyFtAssetValue)
      prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Input);
  }, [
    buyStore.toBuyFtAsset,
    buyStore.toBuyFtAssetValue,
    prepareAndDebouncedFetchTaskData,
  ]);

  // Update task data automatically
  useEffect(() => {
    const cronjob = (): boolean => {
      if (!buyStore.slippage) {
        return false;
      }
      if (
        buyStore.toBuyFtAsset &&
        buyStore.toBuyFtAssetValue?.gt(0) &&
        buyStore.customPlan.length >= 0
      ) {
        if (tradeStore.fromAi) {
          prepareAndDebouncedFetchTaskData(
            CallTaskDataPurpose.Both,
            handleConfirm
          );
          tradeStore.resetAiFields();
        } else prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Result);
        return true;
      } else {
        return false;
      }
    };

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (!showConfirm && !showPaymentPlanSheet) {
      const res = cronjob();
      if (res) {
        timerRef.current = setInterval(() => {
          const flag = cronjob();
          if (!flag && timerRef.current) {
            clearInterval(timerRef.current);
          }
        }, REFRESH_TIME);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedFetchTaskData,
    buyStore.receiver,
    buyStore.toBuyFtAsset,
    buyStore.toBuyFtAssetValue,
    tradeStore.mode,
    buyStore.slippage,
    prepareAndDebouncedFetchTaskData,
    showConfirm,
    showPaymentPlanSheet,
    tradeStore.payableChangeCount,
    tradeStore,
    buyStore.customPlan.length,
  ]);

  // Check can execute
  const checkState = useMemo<true | BuyErrorType>(() => {
    if (!tradeStore.accountName) {
      return BuyErrorType.NoAccount;
    }
    return true;
  }, [tradeStore.accountName]);

  const handleInputChange = useCallback(
    (amount: KontosNumber) => {
      setSliderValue(new KontosNumber(0));
      const toSetValue = amount.eq(0) ? undefined : amount;
      buyStore.setToBuyFtAssetValue(toSetValue);
    },
    [buyStore]
  );

  const handleSliderChange = useCallback(
    (percent: number) => {
      const formattedPercent = new KontosNumber(percent).round(2);
      setSliderValue(formattedPercent);

      const buyValue = buyStore.maxAvailableUsd
        ?.multiply(formattedPercent)
        ?.round(precision);
      const buyValueFormatted =
        !buyValue || buyValue.gt(MIN_SPOT_INPUT)
          ? buyValue
          : new KontosNumber(MIN_SPOT_INPUT);
      buyStore.setToBuyFtAssetValue(buyValueFormatted);
    },
    [buyStore]
  );

  const handleSelectToBuyFtAsset = useCallback(
    (asset: FtAsset) => {
      setSliderValue(new KontosNumber(0));
      buyStore.setToBuyFtAsset(asset);
      buyStore.resetInput();
      closeSelectAssetsSheet();
    },
    [buyStore, closeSelectAssetsSheet]
  );

  const handleConfirm = useCallback(async () => {
    if (
      !buyStore.toBuyFtAsset ||
      !buyStore.toBuyFtAssetQuantity ||
      !buyStore.toBuyFtAssetValue
    ) {
      return;
    }

    if (checkState !== true) {
      toast({
        type: "warning",
        text: <span>{t(getBuyErrorText(checkState))}</span>,
      });
      return;
    }

    if (tradeStore.mode !== TradeMode.Buy) {
      return;
    }

    let taskdata = buyStore.taskData;

    if (!buyStore.orderPrice || buyStore.orderPrice.eq(0)) {
      loadingStore.showLoading();
      taskdata = await callFetchAndSetTaskData(CallTaskDataPurpose.Result);
      loadingStore.hideLoading();
      if (!taskdata) return;
    }

    if (buyStore.insufficient) {
      // When insufficient, otc & receive are all allowed
      const paymentData: PaymentInitData = {
        method: "otc",
        fetchAndSetTaskData: buyStore.fetchAndSetTaskData,
        targetFtAsset: buyStore.toBuyFtAsset,
        targetFtAssetQuantity: buyStore.toBuyFtAssetQuantity,
        targetFtAssetValue: buyStore.toBuyFtAssetValue,
        maxAvailableUsd: buyStore.maxAvailableUsd || new KontosNumber(0),
        receiveConfig: {
          enable: true,
        },
        otcBuyConfig: {
          enable: true,
        },
      };
      setPaymentInitData(paymentData);
    } else {
      setPaymentInitData(undefined);
    }

    setShowConfirm(true);
    return;
  }, [
    buyStore.fetchAndSetTaskData,
    buyStore.insufficient,
    buyStore.maxAvailableUsd,
    buyStore.orderPrice,
    buyStore.taskData,
    buyStore.toBuyFtAsset,
    buyStore.toBuyFtAssetQuantity,
    buyStore.toBuyFtAssetValue,
    callFetchAndSetTaskData,
    checkState,
    t,
    tradeStore.mode,
  ]);

  const handleInteractionFail = useCallback((e: any) => {
    setShowConfirm(false);
  }, []);

  const handleInteractionCancel = useCallback(() => {
    setShowConfirm(false);
  }, []);

  const handleTaskRegistered = useCallback(async () => {
    setShowConfirm(false);
    tradeStore.reset();
    buyStore.startTrackingAccountBalances();
  }, [tradeStore, buyStore]);

  // View Logic
  const inputAttachment:
    | {
        chainIcon: string;
        quantity: KontosNumber | undefined;
        symbol: string;
        requesting: boolean;
      }
    | undefined = useMemo(() => {
    return (
      buyStore?.toBuyFtAsset && {
        chainIcon: buyStore.toBuyFtAsset.imageUrl,
        quantity: buyStore.toBuyFtAssetQuantity,
        symbol: buyStore.toBuyFtAsset.symbol,
        requesting: resultLoadingCount > 0,
      }
    );
  }, [
    buyStore.toBuyFtAsset,
    buyStore.toBuyFtAssetQuantity,
    resultLoadingCount,
  ]);

  const spotLabel: { inputIntro: string; maxAvailableOrMinRequired: string } =
    useMemo(() => {
      return {
        inputIntro: t("Purchase value: "),
        maxAvailableOrMinRequired: t("Maximum available: "),
      };
    }, [t]);

  const sliderDisabled: boolean = useMemo(() => {
    return !buyStore.maxAvailableUsd?.gt(0);
  }, [buyStore.maxAvailableUsd]);

  const maxAvailableOrMinRequiredValueView: ReactNode = useMemo(() => {
    return inputLoadingCount > 0 ||
      !buyStore.toBuyFtAsset ||
      !buyStore.maxAvailableUsd ? (
      <EllipsisPlaceholder />
    ) : (
      buyStore.maxAvailableUsd.toFormat() + " " + buyStore.inputSymbol
    );
  }, [
    buyStore.inputSymbol,
    buyStore.maxAvailableUsd,
    buyStore.toBuyFtAsset,
    inputLoadingCount,
  ]);

  const mainBtnContext: {
    children?: ReactNode;
    disabled?: boolean;
    icon?: any;
    loading?: boolean;
    callback?: () => void;
  } = useMemo(() => {
    if (!buyStore.toBuyFtAssetValue) {
      return {
        children: <DarkBtnText>{t("Input Value to Continue")}</DarkBtnText>,
        disabled: true,
        icon: null,
        loading: false,
      };
    }
    if (resultLoadingCount > 0) {
      return {
        children: <DarkBtnText>{t("Calculating...")}</DarkBtnText>,
        disabled: true,
        icon: DarkWhiteLoadingSvg,
        loading: true,
      };
    }
    if (
      buyStore.hasValidResultTaskData &&
      resultLoadingCount === 0 &&
      inputLoadingCount === 0
    ) {
      return {
        children: <LightBtnText>{t("Confirm")}</LightBtnText>,
        disabled: false,
        icon: null,
        loading: false,
        callback: handleConfirm,
      };
    }

    if (
      buyStore.taskData &&
      buyStore.taskData.isChainLiquidityInsufficient === true
    ) {
      return {
        children: <DarkBtnText>{t("Liquidity Insufficient")}</DarkBtnText>,
        disabled: true,
        icon: DarkWhiteLoadingSvg,
        loading: false,
      };
    }

    return {
      children: <DarkBtnText>...</DarkBtnText>,
      disabled: true,
      icon: DarkWhiteLoadingSvg,
      loading: true,
    };
  }, [
    buyStore.hasValidResultTaskData,
    buyStore.taskData,
    buyStore.toBuyFtAssetValue,
    handleConfirm,
    inputLoadingCount,
    resultLoadingCount,
    t,
  ]);

  const breakdownList: {
    label: string;
    value: ReactNode;
  }[] = useMemo(() => {
    const slippage = (
      <TradeBreakdownEditableItem
        text={
          buyStore.slippage.eq(DEFAULT_SLIPPAGE)
            ? "Auto (" + buyStore.slippage.multiply(100) + "%)"
            : buyStore.slippage.multiply(100) + "%"
        }
        onClick={() => setShowSlippage(true)}
      />
    );
    const receivingAddress = (
      <TradeBreakdownEditableItem
        prefixIcon={avatarIcon}
        text={
          buyStore.receiver
            ? ethers.utils.isAddress(buyStore.receiver)
              ? shortAddress(buyStore.receiver)
              : buyStore.receiver.replaceAll(".os", "") + ".os"
            : "-"
        }
        onClick={() => setShowReceiver(true)}
      />
    );
    const orderPrice = (
      <>
        <DarkBreakDownText>
          {/* fee */}
          {resultLoadingCount > 0 ? (
            <>
              <EllipsisPlaceholder />
              {" USD"}
            </>
          ) : buyStore?.orderPrice?.gt(0) && buyStore.insufficient ? (
            "≈ " + buyStore?.orderPrice?.toFormatV2()
          ) : (
            (buyStore?.orderPrice?.toFormatV2({ zeroPlaceholder: "-" }) ||
              "-") + " USD"
          )}
        </DarkBreakDownText>
        {buyStore?.orderPrice?.gt(0) && (
          <LightBreakDownText>
            {" (Fee "}
            {buyStore.fee?.toFormatV2({ zeroPlaceholder: "-" })}
            {" USD)"}
          </LightBreakDownText>
        )}
      </>
    );
    const paymentPlan = (
      <TradeBreakdownEditableItem
        prefixItem={
          resultLoadingCount > 0 ? (
            <EllipsisPlaceholder style={{ marginRight: "6px" }} />
          ) : (
            <AssetIconGroup
              style={{ marginRight: "6px" }}
              iconList={
                buyStore.enableCustomPlan
                  ? buyStore.customPlan.map((item) => item.imageUrl)
                  : buyStore.taskData?.paymentPlan?.map(
                      (item) => item.assetImageUrl
                    ) || []
              }
              size={16}
              overlapWidthPx={"6px"}
            />
          )
        }
        text={
          buyStore.enableCustomPlan ? t("Custom Plan") : t("Recommend Plan")
        }
        onClick={() => setShowPaymentPlanSheet(true)}
      />
    );
    return [
      {
        label: t("Receiving address") + ": ",
        value: receivingAddress,
      },
      {
        label: t("Slippage") + ": ",
        value: slippage,
      },
      {
        label: t("Minimum received") + ": ",
        value: (
          <BreakDownLineWrapper>
            <ImageWithFallback
              src={buyStore.toBuyFtAsset?.imageUrl || defaultTokenIcon}
              fallbackSrc={defaultTokenIcon}
              StyledImg={BreakDownAssetIcon}
            />
            <DarkBreakDownText style={{ marginLeft: "4px" }}>
              {/* quantity */}
              {resultLoadingCount > 0 ? (
                <EllipsisPlaceholder />
              ) : buyStore.toBuyFtAssetQuantity ? (
                calculateBySlippage(
                  buyStore.toBuyFtAssetQuantity,
                  buyStore.slippage
                ).toFormat()
              ) : (
                "-"
              )}
              {/* symbol */}
              {buyStore.toBuyFtAsset ? " " + buyStore.toBuyFtAsset?.symbol : ""}
            </DarkBreakDownText>
          </BreakDownLineWrapper>
        ),
      },
      {
        label: t("Order price:"),
        value: orderPrice,
      },
      {
        label: t("Payment Plan:"),
        value: paymentPlan,
      },
    ];
  }, [
    buyStore.customPlan,
    buyStore.enableCustomPlan,
    buyStore.fee,
    buyStore.insufficient,
    buyStore?.orderPrice,
    buyStore.receiver,
    buyStore.slippage,
    buyStore.taskData?.paymentPlan,
    buyStore.toBuyFtAsset,
    buyStore.toBuyFtAssetQuantity,
    resultLoadingCount,
    t,
  ]);

  const slippageSetupProps = useMemo(() => {
    return {
      symbol: buyStore.toBuyFtAsset?.symbol,
      rawAmount: buyStore.toBuyFtAssetQuantity,
    };
  }, [buyStore.toBuyFtAsset?.symbol, buyStore.toBuyFtAssetQuantity]);

  useEffect(() => {
    navigate(`${ROUTE_TRADE}${ROUTE_TRADE_BUY}`);
    uiStore.setTradeRouteBuy();
  }, [navigate, uiStore]);

  // Main
  return (
    <Container ref={wrapperRef}>
      {/* Main asset/s selector */}
      {/* Spot FtAssets Panel */}
      <TradeAiPanel
        enableClick={true}
        assetSymbol={buyStore.toBuyFtAsset?.symbol}
        assetImageUrl={buyStore.toBuyFtAsset?.imageUrl}
        assetChainIndex={buyStore.toBuyFtAsset?.chainIndex}
        assetAddress={buyStore.toBuyFtAsset?.address}
        showAi={!tradeStore.disableAiScore && showAiScore}
        onBottomClick={() => openSelectAssetsSheet()}
        onClose={() => {
          setShowAiScore(false);
        }}
      >
        <TargetAssetPanel
          ftAsset={buyStore.toBuyFtAsset}
          requesting={fetchingToBuyFtAsset}
          isFavorite={isFavoriteFtAsset(
            buyStore.toBuyFtAsset,
            favStore.ftAssetFavorites
          )}
          disableFav={!authed}
          onClick={() => openSelectAssetsSheet()}
          onDetailClick={() => handleViewAssetInfo(buyStore.toBuyFtAsset!)}
        />
      </TradeAiPanel>

      {/* Amount */}
      <TradePanel>
        {/* Line 1 */}
        <TradeAmountBox>
          {/* Amount Spots*/}
          <div>
            {/* Purchase value Text */}
            <TradeAmountValueText>{spotLabel.inputIntro}</TradeAmountValueText>
            {/* Purchase value Input */}
            <TradeInput
              unit={buyStore.inputSymbol}
              precision={precision}
              maxValue={buyStore.inputMaxValue}
              minValue={buyStore.inputMinValue}
              amountOnChange={handleInputChange}
              value={buyStore.inputValue}
              attachmentProps={inputAttachment}
            />
          </div>

          {/* Price Switch Button */}
          {/* <StyledPriceToggle /> */}
        </TradeAmountBox>

        {/* Spot Amount Slider */}
        <SpotAmountSliderContainer>
          <SpotAmountSlider
            value={sliderValue.round(2).toNumber()}
            onChange={handleSliderChange}
            disabled={sliderDisabled}
          />
        </SpotAmountSliderContainer>

        {/* -Spot-Trading- Balance */}
        <BalanceBox>
          {/* Line 1 */}
          <BalanceLine>
            <CommonText>{spotLabel.maxAvailableOrMinRequired}</CommonText>
            &nbsp;
            <StrongText>{maxAvailableOrMinRequiredValueView}</StrongText>
            <MaxAvailableQuestionIcon
              className={MAX_AVAILABLE_HELPER_CLASSNAME}
              src={questionIco}
              alt="?"
              onMouseEnter={() => setShowTips(true)}
              onMouseLeave={() => setShowTips(false)}
            />
          </BalanceLine>
        </BalanceBox>
      </TradePanel>

      {/* Action Button */}
      {authed ? (
        <FloatingButton
          onClick={mainBtnContext?.callback}
          icon={mainBtnContext.icon}
          loading={mainBtnContext.loading}
          disabled={mainBtnContext.disabled}
        >
          {mainBtnContext.children}
        </FloatingButton>
      ) : (
        <ConnectButton radius={99} onClick={() => navigate(ROUTE_AUTH)}>
          {t("Connect to Kontos")}
        </ConnectButton>
      )}

      {/* Breakdown */}
      {breakdownList.length > 0 && (
        <TradeBreakdownViewV2 list={breakdownList} />
      )}

      {/* Sheet to select asset to buy */}
      <BottomSheet
        isOpen={showSelectAssets}
        onClose={() => closeSelectAssetsSheet()}
        mountPoint={domNode}
      >
        <ToBuyAssetSelector
          onChoose={handleSelectToBuyFtAsset}
          chains={chainStore.allowBuyChains}
          hasRecommend
          hasAll
          hasFavorites={favStore.hasFavFtAssets}
          showAssetType={ShowAssetType.Detail}
          initAssetType={buyStore.defaultToBuyFtAssetType}
          onChainChange={buyStore.onChainChange}
          enableTradeStore
        />
      </BottomSheet>

      {/* Tx Confirm Sheet */}
      <BottomSheet
        isOpen={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        mountPoint={domNode}
      >
        {buyStore.taskData && (
          <PaymentProvider initData={paymentInitData}>
            <TxConfirmation
              interactionType={ContractInteractionType.BuyToken}
              target={"Kontos Trade · Buy"}
              onInnerModalChange={onInnerModalChange}
              onCancel={handleInteractionCancel}
              onSuccess={handleTaskRegistered}
              onFail={handleInteractionFail}
              wallet={userStore.accountName!}
              receiver={buyStore.receiver}
              buyProps={{
                toBuyFtAsset: buyStore.toBuyFtAsset!,
                toBuyFtAssetValue: buyStore.toBuyFtAssetValue!,
                amount: buyStore.toBuyFtAssetQuantity!,
                taskData: buyStore.taskData!,
                isInSufficient: buyStore.insufficient,
                slippage: buyStore.slippage,
                maxRequiredUsdCost: new KontosNumber(
                  buyStore.taskData?.totalPaymentsInUsd,
                  DEFAULT_DECIMAL
                ),
                isCustom: buyStore.enableCustomPlan,
              }}
              displayPrecision={displayPrecision}
            />
          </PaymentProvider>
        )}
      </BottomSheet>

      {/* Slippage Sheet */}
      <BottomSheet
        isOpen={showSlippage}
        mountPoint={domNode}
        customHeight={330}
        disableScrollLocking
        onClose={() => setShowSlippage(false)}
      >
        <SlippageSetup
          initSlippage={buyStore.slippage}
          symbol={slippageSetupProps?.symbol}
          rawAmount={slippageSetupProps?.rawAmount}
          onSubmit={(slippage) => {
            buyStore.setSlippage(slippage);
            setShowSlippage(false);
          }}
        />
      </BottomSheet>

      {/* Receiving Address Sheet */}
      {buyStore.receiver && (
        <BottomSheet
          isOpen={showReceiver}
          onClose={() => setShowReceiver(false)}
          mountPoint={domNode}
          disableScrollLocking
        >
          <ReceiverSelectModal
            rawAddress={buyStore.receiver}
            onConfirm={(address) => {
              buyStore.setReceiver(address);
              setShowReceiver(false);
            }}
            onCancel={() => setShowReceiver(false)}
          />
        </BottomSheet>
      )}

      {/* Payment Plan Sheet */}
      <BottomSheet
        isOpen={showPaymentPlanSheet}
        onClose={() => setShowPaymentPlanSheet(false)}
        mountPoint={domNode}
        disableScrollLocking
      >
        <PaymentPlanFlow
          mode={"editable"}
          selectedPlan={buyStore.enableCustomPlan ? "custom" : "recommend"}
          recommendAssets={
            buyStore.taskData?.paymentPlan
              ? filterUserHoldings(
                  bulkConvertUniformedPaymentToFtAssetBase(
                    buyStore.taskData.paymentPlan
                  ),
                  true
                )
              : undefined
          }
          customAssets={buyStore.customPlan}
          onBack={() => setShowPaymentPlanSheet(false)}
          setCustomPlan={buyStore.setCustomPlan}
          selectedBalanceIds={buyStore.selectedBalances}
          fixedBalanceIds={buyStore.fixedBalanceIds}
          setSelectedPlan={(plan) => {
            if (plan === "custom") {
              buyStore.setEnableCustomPlan(true);
            } else {
              buyStore.setEnableCustomPlan(false);
            }
          }}
        />
      </BottomSheet>

      {showTips && (
        <Floater
          eventDelay={0}
          open={showTips}
          component={TipsBuyMaxAvailableV2}
          target={`.${MAX_AVAILABLE_HELPER_CLASSNAME}`}
          placement="top"
        />
      )}
    </Container>
  );
});
