import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStores } from "src/hooks/useStore";
import ReceiveNetwork from "./ReceiveNetwork";
import ReceiveMain from "./ReceiveMain";
import { watchChainId } from "@wagmi/core";
import { wagmiConfig } from "src/configs/wagmi";
import toast from "src/components/toast/Toast";
import { Trans, useTranslation } from "react-i18next";
import { useWatchDefaultPendingTx } from "./hooks/useWatchDefaultPendingTx";
import { openUrl } from "src/utils/helper";
import { loadingStore } from "src/store/loadingStore";
import KontosNumber from "src/utils/KontosNumber";
import { useWalletDisconnect } from "./hooks/useWalletDisconnect";
import { CONNECTOR_BYBIT } from "src/config";
import { useAccount, useSwitchChain } from "wagmi";

const Receive: React.FC = observer(() => {
  const { t } = useTranslation();
  const { receiveStore } = useStores();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const chainIdParam = searchParams.get("chainId");
  const addressParam = searchParams.get("address");
  const amountParam = searchParams.get("amount");
  const decimalsParam = searchParams.get("decimals");
  const connectorParam = searchParams.get("connector");
  const [step, setStep] = useState<"network" | "main">(() => {
    if (typeof chainIdParam === "string") {
      return "main";
    }
    if (receiveStore.chain) {
      return "main";
    }
    return "network";
  });
  const { disconnectAsync } = useWalletDisconnect();
  const walletAccount = useAccount();
  const { switchChainAsync } = useSwitchChain();

  useEffect(() => {
    const checkLegalityOfSearchParams = async () => {
      if (
        typeof chainIdParam === "string" &&
        typeof addressParam === "string" &&
        typeof amountParam === "string" &&
        typeof decimalsParam === "string"
      ) {
        loadingStore.showLoading();

        try {
          // Check chain
          const chain = await receiveStore.setChainByChainIndex(chainIdParam);
          if (!chain) {
            throw new Error(
              t(
                "The specified chain does not exist or is currently not supported by Kontos Wallet. You can freely edit the asset that need to be deposited"
              )
            );
          }
          if (
            typeof connectorParam === "string" &&
            connectorParam === CONNECTOR_BYBIT &&
            walletAccount?.connector?.id === CONNECTOR_BYBIT
          ) {
            // Bybit Wallet
            await switchChainAsync({
              chainId: parseInt(chain.chainIndex),
            });
          } else {
            // Others
            await disconnectAsync();
          }

          // Check asset
          const asset = await receiveStore.setAssetByAssetAddress(addressParam);
          if (!asset) {
            throw new Error(
              t(
                "The specified asset does not exist or is currently not supported by Kontos Wallet. You can freely edit the asset that need to be deposited"
              )
            );
          }

          // Amount
          receiveStore.setAmount(
            new KontosNumber(amountParam, Number(decimalsParam))
          );
          receiveStore.setByDepositOrder(true);
        } catch (e) {
          const errorMessage =
            e instanceof Error
              ? e.message
              : t(
                  "The specified parameters are incorrect. You can freely edit the asset that need to be deposited"
                );
          toast({
            text: errorMessage,
            type: "error",
          });
        } finally {
          loadingStore.hideLoading();
        }
      }
    };
    checkLegalityOfSearchParams();

    return () => {
      receiveStore.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toastForTxResult = useCallback(
    (status: "success" | "reverted", explorerUrl: string) => {
      if (status === "success") {
        toast({
          text: (
            <Trans i18nKey={"trans-receive-tx-success"}>
              Transaction successful, click
              <span
                style={{
                  color: "var(--Kontos-Blue, #413dec)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  openUrl(explorerUrl);
                }}
              >
                {" "}
                here{" "}
              </span>
              to view details
            </Trans>
          ),
          type: "success",
        });
        return;
      }

      if (status === "reverted") {
        toast({
          text: (
            <Trans i18nKey={"trans-receive-tx-fail"}>
              Transaction failed, click
              <span
                style={{
                  color: "var(--Kontos-Blue, #413dec)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  openUrl(explorerUrl);
                }}
              >
                {" "}
                here{" "}
              </span>
              to view details
            </Trans>
          ),
          type: "error",
        });
        return;
      }
    },
    []
  );

  useWatchDefaultPendingTx(toastForTxResult);
  useEffect(() => {
    receiveStore.restore();
    const unwatch = watchChainId(wagmiConfig, {
      async onChange(chainId) {
        if (receiveStore.freezeWatchingChainId) {
          return;
        }

        if (step === "network") {
          return;
        }

        if (
          receiveStore.chain &&
          chainId === parseInt(receiveStore.chain?.chainIndex)
        ) {
          return;
        }

        try {
          receiveStore.setChain(undefined);
          const matched = await receiveStore.setChainByChainIndex(
            chainId.toString()
          );
          if (!matched) {
            throw new Error("Fail");
          }
        } catch (e) {
          console.warn(e);
          toast({
            text: t("Chain has been reset in connected wallet"),
          });
          setStep("network");
        }
      },
    });

    return () => {
      unwatch();
    };
  }, [receiveStore, step, t]);

  return (
    <>
      {step === "network" && <ReceiveNetwork onNext={() => setStep("main")} />}

      {step === "main" && (
        <ReceiveMain onGoNetworkSelect={() => setStep("network")} />
      )}
    </>
  );
});

export default Receive;
