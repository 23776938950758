import Header from "src/components/common/header";
import styled from "styled-components";
import ChainSelect from "src/components/chain-select/ChainSelect";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "src/router/router-config";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";
import { useAccount, useSwitchChain } from "wagmi";
import { useTranslation } from "react-i18next";
import { StatusModal } from "src/components/modal/StatusModal";
import { KontosButton } from "src/components/button/KontosButton";
import toast from "src/components/toast/Toast";
import { ConnectStatusBar } from "./components/ConnectStatusBar";
import { useWalletDisconnect } from "./hooks/useWalletDisconnect";
import { Chain } from "@/type/zkkontos";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DisconnectButton = styled(KontosButton)`
  border-radius: 99px;
  border: 1px solid var(--error-notice, #ff1e2b);
  background: var(--White, #fff);
  color: var(--error-notice, #ff1e2b);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
`;

const StyledConnectStatusBar = styled(ConnectStatusBar)`
  margin-bottom: 10px;
`;

interface IProps {
  onNext: () => void;
}

const ReceiveNetwork: React.FC<IProps> = observer(({ onNext }) => {
  const { t } = useTranslation();
  const { receiveStore, chainStore } = useStores();
  const navigate = useNavigate();
  const walletAccount = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const [failCount, setFailCount] = useState<number>(0);
  const { disconnectAsync, toastDisconnectSuccess } = useWalletDisconnect();
  const [showSwitchStatusModal, setShowSwitchStatusModal] =
    useState<boolean>(false);
  const [disconnecting, setDisconnecting] = useState<boolean>(false);

  const handleChooseChain = useCallback(
    async (chain: Chain) => {
      if (walletAccount.isConnected) {
        try {
          setShowSwitchStatusModal(true);
          await switchChainAsync({
            chainId: parseInt(chain.chainIndex),
          });
          setFailCount(0);
        } catch (e) {
          setFailCount((prev) => prev + 1);
          console.warn(e);
          if (failCount >= 3) {
            try {
              disconnectAsync();
              setFailCount(0);
            } catch (e) {
              console.log(e);
            }
          } else {
            return;
          }
        } finally {
          setShowSwitchStatusModal(false);
        }
      }
      receiveStore.setChain(chain);
      onNext();
      return;
    },
    [
      disconnectAsync,
      failCount,
      onNext,
      receiveStore,
      switchChainAsync,
      walletAccount.isConnected,
    ]
  );

  const handleDisconnect = useCallback(async () => {
    if (disconnecting) {
      return;
    }

    try {
      setDisconnecting(true);
      await disconnectAsync();
      toastDisconnectSuccess();
      setShowSwitchStatusModal(false);
    } catch (e) {
      console.log(e);
      const errorMsg =
        e instanceof Error ? e.message : t("Error when disconnecting");
      toast({
        text: errorMsg,
        type: "error",
      });
    } finally {
      setDisconnecting(false);
    }
  }, [disconnectAsync, disconnecting, t, toastDisconnectSuccess]);

  return (
    <Container>
      <Header
        title={t("Choose Network")}
        enableBack={true}
        callBack={() => navigate(ROUTE_HOME)}
      />
      <ChainSelect
        chains={chainStore.chains}
        onChoose={handleChooseChain}
        middleComponent={
          walletAccount.isConnected ? <StyledConnectStatusBar /> : undefined
        }
      />

      {showSwitchStatusModal && (
        <StatusModal
          needBackDrop
          status={"pending"}
          title={t("Switch Chain")}
          desc={t("Please confirm in wallet")}
          buttons={[
            <DisconnectButton
              onClick={handleDisconnect}
              disabled={disconnecting}
            >
              {disconnecting ? t("Disconnecting...") : t("Disconnect")}
            </DisconnectButton>,
          ]}
        />
      )}
    </Container>
  );
});

export default ReceiveNetwork;
