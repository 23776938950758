import styled from "styled-components";
import { observer } from "mobx-react";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStores } from "src/hooks/useStore";
import { FtAsset } from "@/type/zkkontos";
import {
  bulkConvertUniformedPaymentToFtAssetBase,
  calculateBySlippage,
  shortAddress,
} from "src/utils/helper";
import { isSameFtAsset, parseApiErrorMessage } from "src/utils/zkkontosHelper";
import toast from "src/components/toast/Toast";
import { runInAction } from "mobx";
import { debounce } from "lodash";
import { AssetsPanel } from "./AssetsPanel";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import {
  ShowAssetType,
  ToBuyAssetSelector,
} from "../asset-select/ToBuyAssetSelector";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { useTranslation } from "react-i18next";
import { SlippageSetup } from "../common/SlippageSetup";
import {
  BreakDownAssetIcon,
  BreakDownLineWrapper,
  DarkBreakDownText,
  LightBreakDownText,
  TradeBreakdownEditableItem,
} from "../common/TradeBreakdownEditableItem";
import { DEFAULT_SLIPPAGE, KONTOS_CHAIN_INDEX } from "src/config";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import { TradeBreakdownViewV2 } from "../common/TradeBreakdownViewV2";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import FloatingButton from "../FloatingButton";
import darkWhiteLoadingIcon from "src/assets/icons/dark-white-loading.svg";
import { loadingStore } from "src/store/loadingStore";
import { ReceiverSelectModal } from "../common/ReceiverSelectModal";
import { ContractInteractionType } from "src/pages/contract-interaction/types";
import { ethers } from "ethers";
import { AvailableHelper } from "./AvailableHelper";
import { TradeAiPanel } from "../common/TradeAiPanel";
import useAuthCheck from "@/hooks/useAuthCheck";
import PrimaryButton from "@/components/button/PrimaryButton";
import { fontH5 } from "@/style/style.global";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_AUTH,
  ROUTE_TRADE,
  ROUTE_TRADE_SELL,
} from "@/router/router-config";
import { TxConfirmation } from "@/pages/contract-interaction/TxConfirmation";
import {
  CallTaskDataPurpose,
  DarkBtnText,
  LightBtnText,
} from "../buy/TradeBuy";
import { filterUserHoldings, isSameChainAsset } from "@/store/storeHelper";
import { AssetIconGroup } from "@/components/icons/AssetIconGroup";
import { PaymentPlanFlow } from "@/flows/payment-plan/PaymentPlanFlow";
import { t } from "i18next";
import { PaymentInitData, PaymentProvider } from "@/store/trade/PaymentStore";

const DEBOUNCE_TIME = 500;
const REFRESH_TIME = 15000;

const Container = styled.div`
  padding: 16px 16px calc(16px + var(--navi-height)) 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const FirstLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectButton = styled(PrimaryButton)`
  width: 100%;
  padding: 17px;
  ${fontH5}
`;

interface IProps {
  viewAssetInfo: (asset: FtAsset) => void;
}

const warningTextTooLow = t("The amount entered must be at least");
const warningTextTooHigh = t("The maximum amount that can be entered is");
const warningTextWhitelist = t("Cross-chain supports only whitelisted assets");

export const TradeSell: React.FC<IProps> = observer(({ viewAssetInfo }) => {
  const { t } = useTranslation();
  const { sellStore, chainStore, userStore, uiStore, tradeStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [innerModalOpen, setInnerModalOpen] = useState<boolean>(false);
  const [showSellAssetSelect, setShowSellAssetSelect] =
    useState<boolean>(false);
  const [showReceiveAssetSelect, setShowReceiveAssetSelect] =
    useState<boolean>(false);
  const [showSlippage, setShowSlippage] = useState<boolean>(false);
  const [showReceiver, setShowReceiver] = useState<boolean>(false);
  const [showContractInteraction, setShowContractInteraction] = useState(false);
  // Input Loading
  const [inputLoadingCount, setInputLoadingCount] = useState(0);
  const addInputLoadingCount = useCallback(() => {
    setInputLoadingCount((prevCount) => prevCount + 1);
  }, []);
  const minusInputLoadingCount = useCallback(() => {
    setInputLoadingCount((prevCount) => prevCount - 1);
  }, []);
  // Result Loading
  const [resultLoadingCount, setResultLoadingCount] = useState(0);
  const addResultLoadingCount = useCallback(() => {
    setResultLoadingCount((prevCount) => prevCount + 1);
  }, []);
  const minusResultLoadingCount = useCallback(() => {
    setResultLoadingCount((prevCount) => prevCount - 1);
  }, []);
  const [showAiScore, setShowAiScore] = useState<boolean>(true);
  const { authed } = useAuthCheck();
  const navigate = useNavigate();
  const [showPaymentPlanSheet, setShowPaymentPlanSheet] =
    useState<boolean>(false);
  const [warningTipText, setWarningTipText] = useState<string | undefined>(
    undefined
  );
  const [paymentInitData] = useState<PaymentInitData | undefined>(undefined);

  const onInnerModalChange = useCallback((isOpen: boolean) => {
    setInnerModalOpen(isOpen);
  }, []);

  const closeModals = useCallback(() => {
    setShowSellAssetSelect(false);
    setShowReceiveAssetSelect(false);
    setShowSlippage(false);
    setShowReceiver(false);
    setShowContractInteraction(false);
    setShowPaymentPlanSheet(false);
  }, []);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: !innerModalOpen,
  });

  const handleInteractionFail = useCallback((e: any) => {
    setShowContractInteraction(false);
  }, []);

  const handleInteractionCancel = useCallback(() => {
    setShowContractInteraction(false);
  }, []);

  // When task registraion succeeds (not the result succeeds)
  const handleTaskRegistered = useCallback(async () => {
    setShowContractInteraction(false);
    tradeStore.reset();
  }, [tradeStore]);

  // update warning tip text for minRequiredAssetAmount
  useEffect(() => {
    if (!authed) {
      setWarningTipText(undefined);
      return;
    }

    if (sellStore.isAmountInsufficient) {
      setWarningTipText(undefined);
    } else if (
      sellStore.isAmountTooLow &&
      sellStore.minRequiredAssetAmountProcessed?.gt(0)
    ) {
      setWarningTipText(
        warningTextTooLow +
          " " +
          sellStore.minRequiredAssetAmountProcessed.toFormatV2()
      );
    } else if (
      sellStore.isAmountTooHigh &&
      sellStore.toSellFtAssetMaxAvailable?.gt(0)
    ) {
      setWarningTipText(
        warningTextTooHigh +
          " " +
          sellStore.toSellFtAssetMaxAvailable?.toFormatV2()
      );
    } else {
      setWarningTipText(undefined);
    }
  }, [
    authed,
    sellStore.isAmountInsufficient,
    sellStore.isAmountTooHigh,
    sellStore.isAmountTooLow,
    sellStore.minRequiredAssetAmountProcessed,
    sellStore.toSellFtAssetMaxAvailable,
  ]);

  // On user selecting asset to sell
  const handleSelectToSellFtAsset = useCallback(
    (asset: FtAsset) => {
      setWarningTipText(undefined);

      // The same asset as before, do nothing
      if (isSameFtAsset(asset, sellStore.toSellFtAsset)) {
        setShowSellAssetSelect(false);
        return;
      }

      // Exclude Kontos assets
      if (asset.chainIndex === KONTOS_CHAIN_INDEX) {
        toast({
          text: t("Currently, selling Kontos chain assets is not supported"),
          type: "error",
        });
        return;
      }

      // Forbid selling & buying the same asset
      if (isSameFtAsset(sellStore.toReceiveFtAsset, asset)) {
        toast({
          text: t("You cannot sell or buy the same asset"),
          type: "error",
        });
        return;
      }

      // If to sell asset is not in whitelist and to receive asset is selected and is different chain
      // Reset to receive asset and toast
      if (
        asset.isWhitelist === false &&
        sellStore.toReceiveFtAsset &&
        !isSameChainAsset(asset, sellStore.toReceiveFtAsset)
      ) {
        setWarningTipText(warningTextWhitelist);
        toast({
          text: t(
            "When selling non whitelist asset, the receiving asset need to be on the same chain as it"
          ),
          type: "warning",
        });
        sellStore.setToReceiveFtAsset(undefined);
        sellStore.setToSellFtAsset(asset);
        setShowSellAssetSelect(false);
        return;
      }

      sellStore.setToSellFtAsset(asset);
      setShowSellAssetSelect(false);
    },
    [sellStore, t]
  );

  // On user selecting asset to receive
  const handleSelectToReceiveAsset = useCallback(
    (asset: FtAsset) => {
      setWarningTipText(undefined);

      // The same asset as before, do nothing
      if (isSameFtAsset(asset, sellStore.toReceiveFtAsset)) {
        setShowReceiveAssetSelect(false);
        return;
      }

      // Exclude Kontos assets
      if (asset.chainIndex === KONTOS_CHAIN_INDEX) {
        toast({
          text: t("Currently, buying Kontos chain assets is not supported"),
          type: "warning",
        });
        return;
      }

      // Forbid selling & buying the same asset
      if (isSameFtAsset(asset, sellStore.toSellFtAsset)) {
        toast({
          text: t("You cannot sell or buy the same asset"),
          type: "warning",
        });
        return;
      }

      // If to sell asset is decided and it's non whitelist asset and this to receive one is cross-chain, return
      if (
        sellStore.toSellFtAsset?.isWhitelist === false &&
        sellStore.toSellFtAsset.chainIndex !== asset.chainIndex
      ) {
        setWarningTipText(warningTextWhitelist);
        toast({
          text: t(
            "Because you are selling non whitelist assets, you can only choose to receive same chain assets"
          ),
          type: "error",
        });
        return;
      }

      sellStore.setToReceiveFtAsset(asset);
      setShowReceiveAssetSelect(false);
    },
    [sellStore, t]
  );

  const startTaskDataLoading = useCallback(
    (purpose: CallTaskDataPurpose) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          addInputLoadingCount();
          break;
        case CallTaskDataPurpose.Result:
          addResultLoadingCount();
          break;
        default:
          break;
      }
    },
    [addInputLoadingCount, addResultLoadingCount]
  );

  const endTaskDataLoading = useCallback(
    (purpose: CallTaskDataPurpose) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          minusInputLoadingCount();
          break;
        case CallTaskDataPurpose.Result:
          minusResultLoadingCount();
          break;
        default:
          break;
      }
    },
    [minusInputLoadingCount, minusResultLoadingCount]
  );

  const callFetchAndSetTaskData = useCallback(
    async (purpose: CallTaskDataPurpose) => {
      try {
        startTaskDataLoading(purpose);
        const resp = await sellStore.fetchAndSetTaskData();
        if (resp?.isChainLiquidityInsufficient === true) {
          toast({
            type: "error",
            text: t("Chain liquidity insufficient, please try other chains"),
          });
          return;
        }
        return resp;
      } catch (e) {
        console.log("Failed to fetch taskdata", e);
        const errorMessage =
          e instanceof Error
            ? parseApiErrorMessage(e).message
            : "An unknown error occurred when calculating task data";
        if (errorMessage.toLocaleLowerCase() !== "canceled") {
          toast({ type: "error", text: errorMessage });
          runInAction(() => {
            sellStore.resetInput();
          });
        }
      } finally {
        endTaskDataLoading(purpose);
      }
    },
    [endTaskDataLoading, sellStore, startTaskDataLoading, t]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchTaskData = useCallback(
    debounce((purpose: CallTaskDataPurpose) => {
      callFetchAndSetTaskData(purpose);
    }, DEBOUNCE_TIME),
    []
  );

  const prepareTaskDataLoading = useCallback(
    (
      purpose: CallTaskDataPurpose,
      debounceTime: number = DEBOUNCE_TIME + 50
    ) => {
      switch (purpose) {
        case CallTaskDataPurpose.Input:
          addInputLoadingCount();
          setTimeout(() => {
            minusInputLoadingCount();
          }, debounceTime);
          break;
        case CallTaskDataPurpose.Result:
          addResultLoadingCount();
          setTimeout(() => {
            minusResultLoadingCount();
          }, debounceTime);
          break;
        default:
          break;
      }
    },
    [
      addInputLoadingCount,
      addResultLoadingCount,
      minusInputLoadingCount,
      minusResultLoadingCount,
    ]
  );

  const prepareAndDebouncedFetchTaskData = useCallback(
    (purpose: CallTaskDataPurpose) => {
      prepareTaskDataLoading(purpose);
      debouncedFetchTaskData(purpose);
    },
    [debouncedFetchTaskData, prepareTaskDataLoading]
  );

  // input useEffect
  useEffect(() => {
    if (
      sellStore.toSellFtAsset &&
      sellStore.toReceiveFtAsset &&
      !sellStore.toSellFtAssetQuantity
    ) {
      prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Input);
    }
  }, [
    prepareAndDebouncedFetchTaskData,
    sellStore.toReceiveFtAsset,
    sellStore.toSellFtAsset,
    sellStore.toSellFtAssetQuantity,
  ]);

  // result useEffect
  useEffect(() => {
    const cronjob = () => {
      if (
        sellStore.receiver &&
        sellStore.slippage &&
        sellStore.toSellFtAsset &&
        sellStore.toReceiveFtAsset &&
        sellStore.toSellFtAssetQuantity?.gt(0) &&
        sellStore.selectedBalances.length >= 0
      ) {
        prepareAndDebouncedFetchTaskData(CallTaskDataPurpose.Result);
        return true;
      } else {
        return false;
      }
    };

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (!showContractInteraction && !showPaymentPlanSheet) {
      const res = cronjob();
      if (res) {
        timerRef.current = setInterval(() => {
          const flag = cronjob();
          if (!flag && timerRef.current) {
            clearInterval(timerRef.current);
          }
        }, REFRESH_TIME);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [
    prepareAndDebouncedFetchTaskData,
    sellStore.receiver,
    sellStore.selectedBalances.length,
    sellStore.slippage,
    sellStore.toReceiveFtAsset,
    sellStore.toSellFtAsset,
    sellStore.toSellFtAssetQuantity,
    showContractInteraction,
    showPaymentPlanSheet,
  ]);

  const handleConfirm = useCallback(async () => {
    // Too low
    if (sellStore.isAmountTooLow) {
      if (sellStore.minRequiredAssetAmountProcessed?.gt(0)) {
        sellStore.setToSellFtAssetQuantity(
          sellStore.minRequiredAssetAmountProcessed
        );
      }
      return;
    }

    // Too high
    if (sellStore.isAmountTooHigh) {
      if (sellStore.toSellFtAssetMaxAvailable?.gt(0)) {
        sellStore.setToSellFtAssetQuantity(sellStore.toSellFtAssetMaxAvailable);
      }
      return;
    }

    let taskData = sellStore.taskData;

    if (!taskData || !taskData.msgs) {
      loadingStore.showLoading();
      taskData = await callFetchAndSetTaskData(CallTaskDataPurpose.Result);
      loadingStore.hideLoading();
      if (!taskData) return;
    }

    setShowContractInteraction(true);
    return;
  }, [callFetchAndSetTaskData, sellStore]);

  // init hook
  useEffect(() => {
    sellStore.startTrackingChains();

    return () => {
      sellStore.stopTrackingChains();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breakdownList: {
    label: string;
    value: ReactNode;
  }[] = useMemo(() => {
    const slippage = (
      <TradeBreakdownEditableItem
        text={
          sellStore.slippage.eq(DEFAULT_SLIPPAGE)
            ? "Auto (" + sellStore.slippage.multiply(100) + "%)"
            : sellStore.slippage.multiply(100) + "%"
        }
        onClick={() => setShowSlippage(true)}
      />
    );
    const receivingAddress = (
      <TradeBreakdownEditableItem
        prefixIcon={avatarIcon}
        text={
          sellStore.receiver
            ? ethers.utils.isAddress(sellStore.receiver)
              ? shortAddress(sellStore.receiver)
              : sellStore.receiver.replaceAll(".os", "") + ".os"
            : "-"
        }
        onClick={() => setShowReceiver(true)}
      />
    );
    const orderPrice = (
      <>
        <DarkBreakDownText>
          {/* fee */}
          {resultLoadingCount > 0 ? (
            <>
              <EllipsisPlaceholder />
              {" USD"}
            </>
          ) : sellStore?.orderPrice?.gt(0) && sellStore.isAmountOutOfRange ? (
            "≈ " + sellStore?.orderPrice?.toFormatV2()
          ) : (
            (sellStore?.orderPrice?.toFormatV2({ zeroPlaceholder: "-" }) ||
              "-") + " USD"
          )}
        </DarkBreakDownText>
        {sellStore?.orderPrice?.gt(0) && (
          <LightBreakDownText>
            {" (Fee "}
            {sellStore.fee?.toFormatV2({ zeroPlaceholder: "-" })}
            {" USD)"}
          </LightBreakDownText>
        )}
      </>
    );
    const paymentPlan = (
      <TradeBreakdownEditableItem
        prefixItem={
          resultLoadingCount > 0 ? (
            <EllipsisPlaceholder style={{ marginRight: "6px" }} />
          ) : (
            <AssetIconGroup
              style={{ marginRight: "6px" }}
              iconList={
                sellStore.taskData?.paymentPlan?.map(
                  (item) => item.assetImageUrl
                ) || []
              }
              size={16}
              overlapWidthPx={"6px"}
            />
          )
        }
        text={
          sellStore.enableCustomPlan ? t("Custom Plan") : t("Recommend Plan")
        }
        onClick={() => setShowPaymentPlanSheet(true)}
      />
    );
    return [
      {
        label: t("Receiving address") + ": ",
        value: receivingAddress,
      },
      {
        label: t("Slippage") + ": ",
        value: slippage,
      },
      {
        label: t("Minimum received") + ": ",
        value: (
          <BreakDownLineWrapper>
            <ImageWithFallback
              src={sellStore.toReceiveFtAsset?.imageUrl || defaultTokenIcon}
              fallbackSrc={defaultTokenIcon}
              StyledImg={BreakDownAssetIcon}
            />
            <DarkBreakDownText style={{ marginLeft: "4px" }}>
              {/* quantity */}
              {resultLoadingCount > 0 ? (
                <EllipsisPlaceholder />
              ) : sellStore.toReceiveFtAssetQuantity ? (
                calculateBySlippage(
                  sellStore.toReceiveFtAssetQuantity,
                  sellStore.slippage
                ).toFormat()
              ) : (
                "-"
              )}
              {/* symbol */}
              {sellStore.toReceiveFtAsset
                ? " " + sellStore.toReceiveFtAsset?.symbol
                : ""}
            </DarkBreakDownText>
          </BreakDownLineWrapper>
        ),
      },
      {
        label: t("Order price:"),
        value: orderPrice,
      },
      {
        label: t("Payment Plan:"),
        value: paymentPlan,
      },
    ];
  }, [
    resultLoadingCount,
    sellStore.enableCustomPlan,
    sellStore.fee,
    sellStore.isAmountOutOfRange,
    sellStore?.orderPrice,
    sellStore.receiver,
    sellStore.slippage,
    sellStore.taskData?.paymentPlan,
    sellStore.toReceiveFtAsset,
    sellStore.toReceiveFtAssetQuantity,
    t,
  ]);

  const mainBtnContext: {
    children?: ReactNode;
    disabled?: boolean;
    icon?: any;
    loading?: boolean;
  } = useMemo(() => {
    if (!sellStore.toSellFtAssetQuantity) {
      return {
        children: <DarkBtnText>{t("Input Quantity to Continue")}</DarkBtnText>,
        disabled: true,
        icon: null,
        loading: false,
      };
    }
    if (resultLoadingCount > 0) {
      return {
        children: <DarkBtnText>{t("Calculating...")}</DarkBtnText>,
        disabled: true,
        icon: darkWhiteLoadingIcon,
        loading: true,
      };
    }
    if (sellStore.isAmountInsufficient) {
      return {
        children: <DarkBtnText>{t("Balance Insufficient")}</DarkBtnText>,
        disabled: true,
        icon: null,
        loading: false,
      };
    }
    if (sellStore.isAmountTooLow) {
      return {
        children: <LightBtnText>{t("Adjust to Minimum")}</LightBtnText>,
        disabled: false,
        icon: null,
        loading: false,
      };
    }
    if (sellStore.isAmountTooHigh) {
      return {
        children: <LightBtnText>{t("Adjust to Maximum")}</LightBtnText>,
        disabled: false,
        icon: null,
        loading: false,
      };
    }
    if (sellStore.mayFail) {
      return {
        children: (
          <LightBtnText>{t("Task may fail by low slippage")}</LightBtnText>
        ),
        disabled: false,
        icon: null,
        loading: false,
      };
    }
    if (
      sellStore.taskData &&
      sellStore.taskData.isChainLiquidityInsufficient === true
    ) {
      return {
        children: <DarkBtnText>{t("Liquidity Insufficient")}</DarkBtnText>,
        disabled: true,
        icon: darkWhiteLoadingIcon,
        loading: false,
      };
    }
    return {
      children: <LightBtnText>{t("Confirm")}</LightBtnText>,
      disabled: false,
      icon: null,
      loading: false,
    };
  }, [
    resultLoadingCount,
    sellStore.isAmountInsufficient,
    sellStore.isAmountTooHigh,
    sellStore.isAmountTooLow,
    sellStore.mayFail,
    sellStore.taskData,
    sellStore.toSellFtAssetQuantity,
    t,
  ]);

  useEffect(() => {
    navigate(`${ROUTE_TRADE}${ROUTE_TRADE_SELL}`);
    uiStore.setTradeRouteSell();
  }, [navigate, uiStore]);

  useEffect(() => {
    // If the user did not provide a usdAmount, the confirm sheet should not open automatically
    if (
      !sellStore.toSellFtAssetQuantity ||
      sellStore.toSellFtAssetQuantity.eq(0)
    ) {
      tradeStore.setFromAi(false);
    }
    if (!mainBtnContext.disabled && tradeStore.fromAi) {
      tradeStore.setFromAi(false);
      handleConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainBtnContext, sellStore.toSellFtAssetQuantity]);

  return (
    <Container ref={wrapperRef}>
      <FirstLine>
        <AvailableHelper inputLoading={inputLoadingCount > 0} />
      </FirstLine>

      <TradeAiPanel
        style={{
          boxShadow: "6px 6px 10px 0px rgba(0, 13, 31, 0.03)",
        }}
        assetSymbol={sellStore.toReceiveFtAsset?.symbol}
        assetImageUrl={sellStore.toReceiveFtAsset?.imageUrl}
        assetChainIndex={sellStore.toReceiveFtAsset?.chainIndex}
        assetAddress={sellStore.toReceiveFtAsset?.address}
        showAi={!tradeStore.disableAiScore && showAiScore}
        onClose={() => {
          setShowAiScore(false);
        }}
        warningText={warningTipText}
      >
        <AssetsPanel
          enabelAiBox={!tradeStore.disableAiScore && showAiScore}
          resultLoading={resultLoadingCount > 0}
          onFromClick={() => {
            setShowSellAssetSelect(true);
          }}
          onToClick={() => {
            setShowReceiveAssetSelect(true);
          }}
        />
      </TradeAiPanel>

      {/* Action Button */}
      {authed ? (
        <FloatingButton
          onClick={handleConfirm}
          icon={mainBtnContext.icon}
          loading={mainBtnContext.loading}
          disabled={mainBtnContext.disabled}
        >
          {mainBtnContext.children}
        </FloatingButton>
      ) : (
        <ConnectButton radius={99} onClick={() => navigate(ROUTE_AUTH)}>
          {t("Connect to Kontos")}
        </ConnectButton>
      )}

      {/* Only if from, to and value is set, show breakdown */}
      {breakdownList.length > 0 && (
        <div style={{ width: "100%" }}>
          <TradeBreakdownViewV2 list={breakdownList} />
        </div>
      )}

      <BottomSheet
        isOpen={showSellAssetSelect}
        onClose={() => setShowSellAssetSelect(false)}
        mountPoint={domNode}
      >
        <ToBuyAssetSelector
          onChoose={handleSelectToSellFtAsset}
          chains={chainStore.allowSellChains}
          hasMyAssets
          hasAll
          showAssetType={ShowAssetType.Balance}
          initAssetType={sellStore.defaultChainIndexForSell}
          onChainChange={chainStore.setDefaultOptForSell}
          sortByBalance
          title={t("Choose asset to sell")}
          enableTradeStore
        />
      </BottomSheet>

      <BottomSheet
        isOpen={showReceiveAssetSelect}
        onClose={() => setShowReceiveAssetSelect(false)}
        mountPoint={domNode}
      >
        <ToBuyAssetSelector
          onChoose={handleSelectToReceiveAsset}
          chains={chainStore.allowSellChains}
          hasAll={sellStore.toSellFtAsset?.isWhitelist !== false}
          hasRecommend={sellStore.toSellFtAsset?.isWhitelist !== false}
          showAssetType={ShowAssetType.Detail}
          initAssetType={
            sellStore.toSellFtAsset?.isWhitelist === false
              ? sellStore.toSellFtAsset.chainIndex
              : sellStore.defaultChainIndexForReceive
          }
          onChainChange={chainStore.setDefaultOptForSellReceive}
          title={t("Choose asset to receive")}
          enableTradeStore
          showButNotAvailableChains={
            sellStore.toSellFtAsset !== undefined &&
            sellStore.toSellFtAsset.isWhitelist === false
              ? chainStore.allowSellChains.filter(
                  (chain) =>
                    chain.chainIndex !== sellStore.toSellFtAsset?.chainIndex
                )
              : []
          }
        />
      </BottomSheet>

      <BottomSheet
        isOpen={showSlippage}
        mountPoint={domNode}
        customHeight={330}
        disableScrollLocking
        onClose={() => setShowSlippage(false)}
      >
        <SlippageSetup
          initSlippage={sellStore.slippage}
          symbol={sellStore?.toReceiveFtAsset?.symbol}
          rawAmount={sellStore?.toReceiveFtAssetQuantity}
          onSubmit={(slippage) => {
            sellStore.setSlippage(slippage);
            setShowSlippage(false);
          }}
        />
      </BottomSheet>

      {/* Receiving Address Sheet */}
      {sellStore.receiver && (
        <BottomSheet
          isOpen={showReceiver}
          onClose={() => setShowReceiver(false)}
          mountPoint={domNode}
          disableScrollLocking
        >
          <ReceiverSelectModal
            rawAddress={sellStore.receiver}
            onConfirm={(address) => {
              sellStore.setReceiver(address);
              setShowReceiver(false);
            }}
            onCancel={() => setShowReceiver(false)}
          />
        </BottomSheet>
      )}

      {/* Payment Plan Sheet */}
      <BottomSheet
        isOpen={showPaymentPlanSheet}
        onClose={() => setShowPaymentPlanSheet(false)}
        mountPoint={domNode}
        disableScrollLocking
      >
        <PaymentPlanFlow
          mode={"editable"}
          selectedPlan={sellStore.enableCustomPlan ? "custom" : "recommend"}
          recommendAssets={
            sellStore.taskData?.paymentPlan
              ? filterUserHoldings(
                  bulkConvertUniformedPaymentToFtAssetBase(
                    sellStore.taskData.paymentPlan
                  ),
                  true
                )
              : undefined
          }
          customAssets={sellStore.customPlan}
          onBack={() => setShowPaymentPlanSheet(false)}
          setCustomPlan={sellStore.setCustomPlan}
          selectedBalanceIds={sellStore.selectedBalances}
          fixedBalanceIds={sellStore.fixedBalanceIds}
          setSelectedPlan={(plan) => {
            if (plan === "custom") {
              sellStore.setEnableCustomPlan(true);
            } else {
              sellStore.setEnableCustomPlan(false);
            }
          }}
        />
      </BottomSheet>

      {/* Contract Interaction Sheet*/}
      <BottomSheet
        isOpen={showContractInteraction}
        onClose={() => {
          setShowContractInteraction(false);
        }}
        mountPoint={domNode}
      >
        {sellStore.taskData && (
          <PaymentProvider initData={paymentInitData}>
            <TxConfirmation
              interactionType={ContractInteractionType.SellToken}
              target={"Kontos Trade · Swap"}
              onInnerModalChange={onInnerModalChange}
              onCancel={handleInteractionCancel}
              onSuccess={handleTaskRegistered}
              onFail={handleInteractionFail}
              wallet={userStore.accountName!}
              receiver={sellStore.receiver}
              sellProps={{
                taskData: sellStore.taskData,
                toSellFtAsset: sellStore.toSellFtAsset!,
                toSellFtAssetQuantity: sellStore.toSellFtAssetQuantity!,
                toSellFtassetValue: sellStore.toSellFtAssetValue!,
                toReceiveFtAsset: sellStore.toReceiveFtAsset!,
                toReceiveFtAssetQuantity: sellStore.toReceiveFtAssetQuantity!,
                toReceiveFtAssetValue: sellStore.toReceiveFtAssetValue!,
                slippage: sellStore.slippage,
                mayFail: sellStore.mayFail,
              }}
            />
          </PaymentProvider>
        )}
      </BottomSheet>
    </Container>
  );
});
