import {
  ROUTE_RECOVER_ACCOUNT_OLD,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_SECURITY__EMAIL,
  ROUTE_UNLOCK,
  ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER,
  ROUTE_AUTH,
  ROUTE_ENERGYBOOSTING,
  ROUTE_ASSET_DETAIL_QUOTE,
  ROUTE_RECEIVE,
  ROUTE_TRANSFER,
} from "./router-config";

export const NO_NAV_ROUTES: string[] = [
  ROUTE_CREATE_ACCOUNT,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_RECOVER_ACCOUNT_OLD,
  ROUTE_UNLOCK,
  ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER,
  ROUTE_AUTH,
  ROUTE_ENERGYBOOSTING,
  ROUTE_ASSET_DETAIL_QUOTE,
  ROUTE_RECEIVE,
  ROUTE_SECURITY__EMAIL,
  ROUTE_TRANSFER,
];

export type RouteWithSearchParam = {
  pathname: string;
  param: string;
};

export const H5_ROUTES: RouteWithSearchParam[] = [
  { pathname: ROUTE_ENERGYBOOSTING, param: "inviter" },
];

export const matchH5Route = (
  pathname: string,
  params: URLSearchParams
): boolean => {
  const macted = H5_ROUTES.some(
    (item) => item.pathname === pathname && !!params.get(item.param)
  );
  return macted;
};
