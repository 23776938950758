import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Web3WalletTypes } from "@walletconnect/web3wallet";
import { SignClientTypes } from "@walletconnect/types";
import { RootStore } from "./RootStore";
import { SessionTypes } from "@walletconnect/types/dist/types/sign-client/session";
import { KontosClient } from "@zkkontos/kontos-sdk";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";

export enum SheetView {
  DappConnection = "DappConnection",
  WcConnect = "WcConnect",
  Sign = "Sign",
  Unlock = "Unlock",
  ContractInteraction = "ContractInteraction",
  SpotPayableAssets = "SpotPayableAssets",
  QrReader = "QrReader",
  DiscoverSearch = "DiscoverSearch",
}

export interface SheetData {
  sessionProposal?: Web3WalletTypes.SessionProposal;
  requestEvent?: SignClientTypes.EventArguments["session_request"];
  requestSession?: SessionTypes.Struct;
  isMessgae?: boolean;
  taskData?: RespTaskPayment;
  unlockCallback?: (cli: KontosClient) => void;
}

export const NoAutoCloseSheetViews = [
  SheetView.Sign,
  SheetView.ContractInteraction,
  SheetView.WcConnect,
];

export const CanNotCoverSheetViews = [
  SheetView.Sign,
  SheetView.ContractInteraction,
  SheetView.DappConnection,
  SheetView.WcConnect,
  SheetView.Unlock,
];

export interface Sheet {
  id: number;
  view: SheetView;
  data?: SheetData;
  canCover: boolean;
  zIndexExtra: number;
}

export const createSheet = (
  view: SheetView,
  zIndexExtra: number,
  data?: SheetData
): Sheet => {
  const id = Date.now();
  return {
    id,
    view,
    data,
    canCover: CanNotCoverSheetViews.includes(view),
    zIndexExtra: zIndexExtra,
  };
};

export class SheetStore {
  rootStore: RootStore;
  sheetStack: Sheet[] = [];
  sheetQueue: Sheet[] = [];
  zIndexExtra: number = 0;
  showOuterSend: boolean = false;
  showOuterTrade: boolean = false;
  showOuterPin: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
    this.startTrackingActiveSheet();
  }

  openOuterSend = () => {
    this.showOuterSend = true;
  };

  closeOuterSend = () => {
    this.showOuterSend = false;
  };

  openOuterTrade = () => {
    this.showOuterTrade = true;
  };

  closeOuterTrade = () => {
    this.showOuterTrade = false;
  };

  openOuterPin = () => {
    this.showOuterPin = true;
  };

  closeOuterPin = () => {
    this.showOuterPin = false;
  };

  increaseZIndex = () => {
    this.zIndexExtra++;
  };

  // Adds a sheet to the stack, making it active
  addSheetToStack = (sheet: Sheet) => {
    runInAction(() => {
      this.sheetStack.push(sheet);
    });
  };

  // Removes a sheet from the stack by its ID
  removeSheetFromStackById = (id?: number) => {
    runInAction(() => {
      this.sheetStack = this.sheetStack.filter((item) => item.id !== id);
    });
  };

  // Removes all sheets from the stack by View
  removeSheetFromStackByView = (view?: SheetView) => {
    runInAction(() => {
      this.sheetStack = this.sheetStack.filter((item) => item.view !== view);
    });
  };

  // Adds a sheet to the queue
  addSheetToQueue = (sheet: Sheet) => {
    runInAction(() => {
      this.sheetQueue.push(sheet);
    });
  };

  // Removes a sheet from the queue by its view type
  removeFromQueueByView = (view?: SheetView) => {
    runInAction(() => {
      this.sheetQueue = this.sheetQueue.filter((item) => item.view !== view);
    });
  };

  // Opens a sheet, either adding it directly to the stack or to the queue based on the ignoreOthers flag
  openSheet = (
    view: SheetView,
    data?: SheetData,
    ignoreOthers: boolean = true
  ): Sheet => {
    const sheet = createSheet(view, this.zIndexExtra, data);
    if (ignoreOthers) {
      this.addSheetToStack(sheet);
    } else {
      this.addSheetToQueue(sheet);
    }
    return sheet;
  };

  openSheetWithCheck = (view: SheetView, data?: SheetData) => {
    if (
      !this.sheetStack.find((sheet) => sheet.canCover === false) &&
      !this.showOuterSend &&
      !this.showOuterTrade &&
      !this.showOuterPin
    ) {
      const sheet = createSheet(view, this.zIndexExtra, data);
      this.addSheetToStack(sheet);
      return sheet;
    }
    return null;
  };

  // Closes a sheet by its ID
  closeSheetById = (id: number) => {
    this.removeSheetFromStackById(id);
  };

  closeSheetByView = (view: SheetView) => {
    this.removeSheetFromStackByView(view);
  };

  // Closes the latest sheet in the stack
  closeLatestSheet = () => {
    const lastestSheet = this.sheetStack.pop();
    this.removeSheetFromStackById(lastestSheet?.id);
  };

  // Closes all sheets in the stack
  closeAllSheets = () => {
    while (this.sheetStack.length > 0) {
      const sheet = this.sheetStack.pop();
      this.removeSheetFromStackById(sheet?.id);
    }
  };

  getLatestSheetForView = (view: SheetView): Sheet | undefined => {
    for (let i = this.sheetStack.length - 1; i >= 0; i--) {
      if (this.sheetStack[i].view === view) {
        return this.sheetStack[i];
      }
    }
    return undefined;
  };

  isOpen = (view: SheetView): boolean => {
    return this.sheetStack.some((item) => item.view === view);
  };

  // Getter for the active sheet (the last sheet in the stack)
  get latestSheet(): Sheet | undefined {
    return this.sheetStack.length >= 1
      ? this.sheetStack[this.sheetStack.length - 1]
      : undefined;
  }

  get sheetVisibility(): Map<SheetView, boolean> {
    const map = new Map<SheetView, boolean>();
    Object.values(SheetView).forEach((view) => {
      map.set(
        view,
        this.sheetStack.some((item) => item.view === view)
      );
    });
    return map;
  }

  // Starts tracking the active sheet, opening sheets from the queue when there are no active sheets
  startTrackingActiveSheet = () => {
    reaction(
      () => [this.sheetStack.length, this.sheetQueue.length],
      () => {
        if (this.sheetStack.length === 0 && this.sheetQueue.length > 0) {
          this.addSheetToStack(this.sheetQueue[0]);
          this.sheetQueue.shift();
        }
      }
    );
  };
}
