import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ROUTE_RECOVER_ACCOUNT_OLD,
  ROUTE_DEFAULT,
  ROUTE_TRADE,
  ROUTE_SIGN,
  ROUTE_AUTH,
  ROUTE_UNLOCK,
  ROUTE_TRANSFER,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_SECURITY__EMAIL,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_GENERAL_LANG,
  ROUTE_ENERGYBOOSTING,
  ROUTE_HOME,
  ROUTE_ENERGYBOOSTING_LEADERBOARD,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_ACCOUNT_SWITCH,
  ROUTE_AI,
  ROUTE_RECEIVE,
  ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER,
} from "./router-config";
import RegisterKontos from "../pages/auth/RegisterKontos";
import { AI_QUERY_PARAM, RECOVERY } from "src/config";
import { useState, useEffect, useCallback } from "react";
import Home from "../pages/home/Home";
import ReactiveWelcome from "../pages/newAuth/Welcome";
import { MultiAccountsPinVerifier } from "src/pages/sign-up/unlock/MultiAccountsPinVerifier";
import { observer } from "mobx-react";
import CreateAccount from "src/pages/newAuth/CreateAccount";
import RecoverAccount from "src/pages/newAuth/RecoverAccount";
import { Email } from "src/pages/security-email/Email";
import Layout, { NoNavLayout } from "src/components/layout";
import Settings from "src/pages/settings/Settings";
import { isSameKontosName } from "src/utils/zkkontosHelper";
import FirstScreen from "./FirstScreen";
import GeneralSetting from "src/pages/settings/general/GeneralSetting";
import LangSetting from "src/pages/settings/general/lang/LangSetting";
import EnergyBoosting from "src/pages/events/energy-boosting/EnergyBoosting";
import Sign from "src/pages/sign/Sign";
import { useStores } from "src/hooks/useStore";
import { matchH5Route } from "./router-map";
import { Flag } from "src/configs/featureFlags";
import { EnergyBoostingMain } from "src/pages/events/energy-boosting/EnergyBoostingMain";
import { EbLeaderBoard } from "src/pages/events/energy-boosting/leader-board/EbLeaderBoard";
import { AiScreen } from "src/pages/ai/AiScreen";
import { Trade } from "@/pages/trade/Trade";
import ReceiveV2 from "src/pages/receive-v2/ReceiveV2";
import Transfer from "@/pages/transfer/Transfer";
// import tgManager from "src/store/managers/tgManager";

const AUTH_ROUTE_ARR = [
  ROUTE_RECOVER_ACCOUNT_OLD,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_UNLOCK,
  ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER,
];

export const SWITCH_ACCOUNT_PATH = `${ROUTE_HOME}/${ROUTE_SETTINGS_ACCOUNT}${ROUTE_SETTINGS_ACCOUNT_SWITCH}`;

const pubKeyAllowedRoutes = [
  ROUTE_HOME,
  ROUTE_HOME + "/" + ROUTE_SETTINGS_ACCOUNT,
  SWITCH_ACCOUNT_PATH,
];

const fullScreenRouteArr = [ROUTE_RECOVER_ACCOUNT_OLD, ROUTE_SIGN, ROUTE_AI];

export const Router = observer(() => {
  const { userStore, uiStore, chainStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const currentPath = location.pathname;
  const [ready, setReady] = useState(false);

  const goToAuth = useCallback(() => {
    const checkWcBeforeAuth = () => {
      const wc = searchParams.get("wc");
      const expiryTimestamp = searchParams.get("expiryTimestamp");
      const relayProtocol = searchParams.get("relay-protocol");
      const symKey = searchParams.get("symKey");

      if (wc && expiryTimestamp && relayProtocol && symKey) {
        const { pathname } = location;
        const url = `${pathname}?wc=${wc}&expiryTimestamp=${expiryTimestamp}&relay-protocol=${relayProtocol}&symKey=${symKey}`;
        userStore.updateRouteAfterAuth(url);
      }
    };

    const checkDepositBeforeAuth = () => {
      const chainId = searchParams.get("chainId");
      const address = searchParams.get("address");
      const amount = searchParams.get("amount");
      const decimals = searchParams.get("decimals");

      if (chainId && address && amount && decimals) {
        const { pathname } = location;
        const url = `${pathname}?chainId=${chainId}&address=${address}&amount=${amount}&decimals=${decimals}`;
        userStore.updateRouteAfterAuth(url);
      }
    };

    const checkAiBeforeAuth = () => {
      const aiParam = searchParams.get(AI_QUERY_PARAM);

      if (aiParam) {
        const url = `${ROUTE_AI}?${AI_QUERY_PARAM}=${aiParam}`;
        userStore.updateRouteAfterAuth(url);
      }
    };

    const getAuthRoute = () => {
      const referralCode = searchParams.get("referralCode");

      if (typeof referralCode === "string") {
        return ROUTE_AUTH + "?referralCode=" + referralCode;
      }
      return ROUTE_AUTH;
    };

    checkWcBeforeAuth();
    checkAiBeforeAuth();
    checkDepositBeforeAuth();
    navigate(getAuthRoute());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, searchParams, userStore]);

  const checkRecovering = useCallback(
    (route: string, name?: string) => {
      const localKeys = userStore.storageKeys;
      const matchKey = localKeys.find((item) =>
        isSameKontosName(item.accountName, name)
      );
      const recoverings = userStore.accountsRecovering;
      const matchName = recoverings.find((item) =>
        isSameKontosName(item, matchKey?.accountName)
      );
      if (matchName) {
        console.log("recoverings matchName exists", matchName);
        // For recoverd successfully,but local recoverings remaining exception
        if (
          userStore.accountInfo?.pubKey ===
          matchKey?.pubKeyData.compressedPubKey
        ) {
          userStore.updateAccountsRecovering(
            recoverings.filter((item) => item !== matchName)
          );
          navigate(route);
        }
        goToAuth();
      } else {
        navigate(route);
      }
    },
    [goToAuth, navigate, userStore]
  );

  const check = useCallback(async () => {
    // Special treatment for shared page, skip restore loading time
    if (matchH5Route(location.pathname, searchParams)) {
      await userStore.initNetwork();
      userStore.restore(true, false);
      setReady(true);
      return;
    }

    await userStore.restore(true, false);
    const name = userStore.accountInfo?.name;
    // When there are local accounts and user wants to add more accounts
    // Navigate to unlock page since we need existing seed
    if (userStore.accountInfo && AUTH_ROUTE_ARR.includes(location.pathname)) {
      // save target(auth)
      userStore.updateTargetRoute(
        location.pathname + location.search || ROUTE_AUTH
      );
      navigate(ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER);
    } else {
      checkRecovering(location.pathname + location.search, name);
    }

    setReady(true);
    return;
  }, [
    checkRecovering,
    location.pathname,
    location.search,
    navigate,
    searchParams,
    userStore,
  ]);

  // after userStore inited
  useEffect(() => {
    if (userStore.isInited && chainStore.isInitialized) {
      check();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.isInited, chainStore.isInitialized]);

  // after check
  useEffect(() => {
    if (ready) {
      // RPC network check
      if (userStore.noNetwork) {
        if (userStore.accountInfo) {
          navigate(ROUTE_HOME);
        } else {
          userStore.reset();
          goToAuth();
        }
        return;
      }

      // Pubkey check
      if (
        userStore.pubKeyException &&
        !AUTH_ROUTE_ARR.includes(location.pathname) &&
        !pubKeyAllowedRoutes.includes(location.pathname)
      ) {
        navigate(SWITCH_ACCOUNT_PATH);
        return;
      }
    }
  }, [
    goToAuth,
    location.pathname,
    navigate,
    ready,
    searchParams,
    userStore,
    userStore.accountInfo,
    userStore.noNetwork,
    userStore.pubKeyException,
  ]);

  if (!ready)
    return (
      <NoNavLayout>
        <FirstScreen isMobile={!uiStore.isOverWidth} />
      </NoNavLayout>
    );

  return fullScreenRouteArr.includes(currentPath) ? (
    <Routes>
      <Route
        path={ROUTE_RECOVER_ACCOUNT_OLD}
        element={<RegisterKontos type={RECOVERY} />}
      />
      <Route path={ROUTE_SIGN} element={<Sign />} />

      <Route
        path={ROUTE_AI}
        element={
          // tgManager.isTg() ? (
          //   <NoNavLayout>
          //     <AiScreen />
          //   </NoNavLayout>
          // ) : (
          //   <Navigate to={ROUTE_HOME} />
          // )
          <NoNavLayout>
            <AiScreen />
          </NoNavLayout>
        }
      />
    </Routes>
  ) : (
    <Layout>
      <Routes>
        {/* Default */}
        <Route path={ROUTE_DEFAULT} element={<Navigate to={ROUTE_HOME} />} />

        {/* Main tabs */}
        <Route path={ROUTE_HOME + "/*"} element={<Home />} />
        <Route path={ROUTE_TRADE + "/*"} element={<Trade />} />
        <Route path={ROUTE_SETTINGS + "/*"} element={<Settings />} />

        <Route path={ROUTE_AUTH} element={<ReactiveWelcome />} />
        <Route path={ROUTE_CREATE_ACCOUNT} element={<CreateAccount />} />
        <Route path={ROUTE_RECOVER_ACCOUNT} element={<RecoverAccount />} />
        <Route
          path={ROUTE_MULTI_ACCOUNTS_PIN_VERIFIVER}
          element={<MultiAccountsPinVerifier />}
        />
        <Route path={ROUTE_TRANSFER} element={<Transfer />} />
        {/* <Route
          path={ROUTE_H5_APPROVE}
          element={<GuardianPage init="approve" />}
        />
        <Route
          path={ROUTE_GUARDIAN}
          element={<GuardianPage init="editGuardian" />}
        /> */}
        <Route path={ROUTE_SECURITY__EMAIL} element={<Email />} />
        <Route path={ROUTE_SETTINGS_GENERAL} element={<GeneralSetting />} />
        <Route path={ROUTE_SETTINGS_GENERAL_LANG} element={<LangSetting />} />
        {/* Energy orbs event */}
        {Flag.eb && (
          <Route
            path={ROUTE_ENERGYBOOSTING + "/*"}
            element={<EnergyBoosting />}
          >
            <Route index element={<EnergyBoostingMain />} />
            <Route
              path={ROUTE_ENERGYBOOSTING_LEADERBOARD + "/*"}
              element={<EbLeaderBoard />}
            />
            <Route
              path={"*"}
              element={<Navigate to={ROUTE_ENERGYBOOSTING} />}
            />
          </Route>
        )}

        {/* Receive */}
        <Route path={ROUTE_RECEIVE} element={<ReceiveV2 />} />

        <Route path="*" element={<Navigate to={ROUTE_DEFAULT} />} />
      </Routes>
    </Layout>
  );
});
