import { t } from "i18next";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "src/components/common/header";
import styled from "styled-components";
import toast from "src/components/toast/Toast";
import SetUpThreshold from "src/components/start/SetUpThreshold";
import { Unlock } from "src/pages/sign-up/unlock/Unlock";
import { KontosClient } from "@zkkontos/kontos-sdk";
import { loadingStore } from "src/store/loadingStore";
// import GuardianItem from "src/components/guardian/GuardianItem";
// import guardianAvatarIco from "src/assets/images/guardian-avatar.svg";
import AddNewBtn from "src/components/start/AddNewBtn";
import { KontosButton } from "src/components/button/KontosButton";
import googleAvatarIco from "src/assets/images/email/google-ava.svg";
import Divider from "src/components/divider/Divider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "src/config";
import { ethers } from "ethers";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { stringArrayEqual } from "src/utils/zkkontosHelper";
import { Trans } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { EmailSelector } from "src/components/start/EmailSelector";
import { Email, isValidGoogleEmail } from "src/utils/helper";
import { DirectEmailInputPopup } from "src/components/start/popups/DirectEmailInputPopup";
import { EmailVerificationPopup } from "src/components/start/popups/EmailVerificationPopup";
import RegisterStatusIcon from "src/components/start/RegisterStatusIcon";
import TransformingIcon from "src/components/icons/TransformingIcon";
import deleteIcoGrey from "src/assets/icons/delete-grey.svg";
import deleteIco from "src/assets/icons/delete1.svg";
import InputWithIcons from "src/components/input/KontosInputV2";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  justify-content: center;
  align-items: center;

  > .header {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;

const Avatar = styled.img`
  margin-top: 20px;

  width: 89px;
  height: 86px;
`;

const MainTextContainer = styled.div`
  margin: 16px 35px 0 35px;
`;

const MainText = styled.div`
  color: ${(props) => props.theme.colors.__deep_800};
  text-align: center;
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;

  .highlight {
    color: ${(props) => props.theme.colors.__kontos_blue};
    ${fontBold};
    font-size: 16px;
    line-height: 130%;
  }
`;

const RedText = styled.span`
  color: ${(props) => props.theme.colors.__error};
  ${fontBold};
  font-size: 14px;
`;

const Scrollable = styled.div`
  width: 100%;
  overflow: auto;

  padding: 0 20px;
`;

const StyledKontosButton = styled(KontosButton)`
  position: absolute;
  bottom: 16px;
  left: 20px;

  z-index: 1;
  width: calc(100% - 40px);
`;

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  onInnerModalChange?: (isOpen: boolean) => void;
}

const EditEmail: React.FC<Props> = ({
  onClose,
  onSuccess,
  onInnerModalChange,
}) => {
  const { userStore } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const rawThreshold = userStore.accountInfo?.actionThreshold || 0;
  const rawEmailGuardians = useMemo(() => {
    return userStore.accountInfo?.emailGuardians || [];
  }, [userStore.accountInfo?.emailGuardians]);
  const [emails, setEmails] = useState<Email[]>([]);
  const [threshold, setThreshold] = useState<number>(emails.length);
  const [showThresHoldModal, setShowThresHoldModal] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const headerText: string = t("Edit Security Email") as string;
  const rightBtnText: string = t("Cancel") as string;
  const mainText = useMemo<ReactNode>(() => {
    return (
      <MainText>
        {t("Please replace your security email list with a new one.")}
        <br />
        <span className="highlight">
          {userStore.accountInfo?.name?.replaceAll(".os", "") + ".os"}
        </span>
      </MainText>
    );
  }, [userStore.accountInfo]);
  const [showEmailSelector, setShowEmailSelector] = useState<boolean>(false);
  const [showDirectWarningPopup, setShowDirectWarningPopup] =
    useState<boolean>(false);
  const [showEmailVerificationPopup, setShowEmailVerificationPopup] =
    useState<boolean>(false);

  const removeEmail = useCallback(
    (email: string) => {
      const updatedEmails = emails.filter((item) => item.address !== email);
      setEmails(updatedEmails);
    },
    [emails]
  );

  const addEmail = useCallback(
    (newEmail: string, isVerified: boolean) => {
      if (!emails.some((item) => item.address === newEmail)) {
        setEmails([...emails, { address: newEmail, isVerified }]);
        setThreshold(emails.length + 1);
      } else {
        toast({
          type: "warning",
          text: t("The same email already exists") + " " + newEmail,
        });
      }
    },
    [emails]
  );

  const handleBack = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleRightBtnCallBack = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleExecuteUpdateEmail = useCallback(
    async (cli: KontosClient) => {
      setShowPinModal(false);
      try {
        loadingStore.showLoading();
        const emailHashArr = emails.map((email) => {
          return ethers.utils.keccak256(
            ethers.utils.toUtf8Bytes(
              email.address.trim().toLowerCase().toString()
            )
          );
        });
        await cli.commitUpdateGuardianInfo([], emailHashArr, threshold);
        toast({
          type: "success",
          text: t(
            "Successfully submitted your security email change operation. This change will take effect in 48 hours"
          ),
        });
        onSuccess();
      } catch (e) {
        console.log(e);
        const errorMessage =
          e instanceof Error
            ? e.message
            : t("Failed to submit your guardian change operation");
        toast({
          type: "error",
          text: errorMessage,
        });
      } finally {
        loadingStore.hideLoading();
      }
    },
    [emails, onSuccess, threshold]
  );

  const handleNextSubmit = useCallback(async () => {
    const rawHashes = rawEmailGuardians.map((mailHash) => mailHash);
    const hashes = emails.map((item) =>
      ethers.utils.keccak256(
        ethers.utils.toUtf8Bytes(item.address.trim().toLowerCase().toString())
      )
    );
    if (stringArrayEqual(rawHashes, hashes) && threshold === rawThreshold) {
      toast({
        type: "info",
        text: "You don't need to update since it's the same security email list and threshold",
      });
      return;
    }
    setShowPinModal(true);
  }, [emails, rawEmailGuardians, rawThreshold, threshold]);

  const handleThresholdChange = useCallback(
    (value: number) => {
      if (value <= emails.length && value >= 1) {
        setThreshold(value);
      }
    },
    [emails.length]
  );

  useEffect(() => {
    onInnerModalChange?.(showThresHoldModal || showPinModal);
  }, [showThresHoldModal, showPinModal, onInnerModalChange]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: () => {
      setShowPinModal(false);
      setShowThresHoldModal(false);
    },
    shouldClose: !loadingStore.isLoading,
  });

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Container ref={wrapperRef}>
        <div className="header">
          <Header
            title={headerText}
            padding="8px 24px"
            callBack={handleBack}
            rightBtnText={rightBtnText}
            rightBtnCallBack={handleRightBtnCallBack}
          />
        </div>
        <Avatar src={googleAvatarIco} />
        <MainTextContainer>{mainText}</MainTextContainer>
        <Divider top={16} bottom={16} left={45} right={45} isDashed={false} />
        <Scrollable>
          {emails.map((email) => (
            // <GuardianItem
            //   key={email.address}
            //   name={email.address}
            //   hasSuffix={false}
            //   avatar={guardianAvatarIco}
            //   onRemove={() => {
            //     handleRemoveClick(email.address);
            //   }}
            // />

            <InputWithIcons
              key={email.address}
              leftIcon={<RegisterStatusIcon status={"success"} type="email" />}
              rightIcon={
                <TransformingIcon
                  defaultIcon={deleteIcoGrey}
                  hoverIcon={deleteIco}
                  onClick={() => {
                    removeEmail(email.address);
                  }}
                />
              }
              placeholder={t("")}
              value={email.address}
              inputStyle={{
                color: "var(--Deep-800, #1A2535)",
                borderColor: email.isVerified
                  ? "var(--Success, #10CE5C)"
                  : "var(--Deep-100, #CCCFD2)",
              }}
              readonly
              disabled
            />
          ))}
        </Scrollable>

        {emails.length < 5 && (
          // <AddButtonWrapper>
          //   <GoogleLoginBtn
          //     loginSuccessFun={handleAddClick}
          //     wrapperStyle={{
          //       marginTop: "16px",
          //     }}
          //   >
          //     <AddNewBtn />
          //   </GoogleLoginBtn>
          // </AddButtonWrapper>

          <AddNewBtn onClick={() => setShowEmailSelector(true)} />
        )}

        <StyledKontosButton
          onClick={() => {
            if (emails.length === 0) {
              setShowEmailSelector(true);
              return;
            }
            setShowThresHoldModal(true);
          }}
        >
          {t("Next")}
        </StyledKontosButton>

        <BottomSheet
          isOpen={showThresHoldModal}
          onClose={() => setShowThresHoldModal(false)}
          mountPoint={domNode}
          customHeight={330}
        >
          <div
            style={{
              height: 330,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <SetUpThreshold
              submit={handleNextSubmit}
              cancel={() => setShowThresHoldModal(false)}
              maxThreshold={emails.length}
              currentThreshold={threshold}
              setCurrentThreshold={handleThresholdChange}
              firstBtnText={t("Confirm") as string}
              text={
                <span>
                  <Trans i18nKey={"trans-email-list-48"}>
                    *A <RedText style={{ color: "" }}>48-hour</RedText>{" "}
                    confirmation period is required to update the Security Email
                    list.
                  </Trans>
                </span>
              }
            />
          </div>
        </BottomSheet>

        <BottomSheet
          isOpen={showPinModal}
          onClose={() => setShowPinModal(false)}
          mountPoint={domNode}
          disableScrollLocking={true}
        >
          <Unlock onSuccess={handleExecuteUpdateEmail} />
        </BottomSheet>

        {/* Show email setting methods */}
        <BottomSheet
          isOpen={showEmailSelector}
          onClose={() => setShowEmailSelector(false)}
          mountPoint={domNode}
          customHeight={330}
          disableScrollLocking={true}
        >
          <EmailSelector
            googleAddCallback={(email) => {
              if (!email || !isValidGoogleEmail(email)) return;
              addEmail(email, true);
              // setEmails(pushEmail(email, emails, true));
              setShowEmailSelector(false);
            }}
            onVerifyMethodChosen={function (): void {
              setShowEmailSelector(false);
              setTimeout(() => {
                setShowEmailVerificationPopup(true);
              }, 500);
            }}
            onDirectMethodChosen={() => {
              setShowEmailSelector(false);
              setTimeout(() => {
                setShowDirectWarningPopup(true);
              }, 500);
            }}
          />
        </BottomSheet>

        {showDirectWarningPopup && (
          <DirectEmailInputPopup
            existedEmails={emails.map((item) => item.address)}
            onAddEmail={(email) => {
              if (!email || !isValidGoogleEmail(email)) return;
              addEmail(email, false);
              // setEmails(pushEmail(email, emails, false));
            }}
            onClose={() => {
              setShowDirectWarningPopup(false);
            }}
          />
        )}

        {showEmailVerificationPopup && (
          <EmailVerificationPopup
            existedEmails={emails.map((item) => item.address)}
            accountName={userStore.accountName!}
            onAddEmail={(email) => {
              if (!email || !isValidGoogleEmail(email)) return;
              addEmail(email, true);
              // setEmails(pushEmail(email, emails, true));
            }}
            onClose={() => {
              setShowEmailVerificationPopup(false);
            }}
          />
        )}
      </Container>
    </GoogleOAuthProvider>
  );
};

export default EditEmail;
