import styled from "styled-components";
import LogoIcon from "src/assets/images/logo.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "src/components/toast/Toast";
import { t } from "i18next";
import { PINCodeInput } from "../../../components/images/PINCodeInput";
import { observer } from "mobx-react";
import { loadingStore } from "src/store/loadingStore";
import { KontosClient } from "@zkkontos/kontos-sdk";
import { useNavigate } from "react-router";
import { ROUTE_AUTH } from "src/router/router-config";
import { useStores } from "src/hooks/useStore";
import { uploadErrorOnce } from "src/service/wallet-service";
import { fontBold } from "@/style/style.global";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(/static/bg-light.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  box-sizing: border-box;

  width: 100%;
  height: 100%;
  padding: 20px;

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .app-icon {
    width: 96px;
    height: 96px;
    // margin-top: 120px;
    // margin-bottom: 16px;
  }

  .title {
    margin-top: 16px;
    color: #413dec;
    font-size: 28px;
    line-height: 33px;
    ${fontBold};
    margin-bottom: 6px;
  }

  .desc {
    color: #413dec;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  .footer {
    margin-bottom: 60px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    transform: translateY(23px);

    .logo {
      height: auto;
      width: 65px;
      margin-left: 8px;
      transform: translateY(2px);
    }

    .radio {
      margin: 0;
      margin-right: 10px;
      appearance: none;
      position: relative;

      :before {
        content: "";
        width: 15px;
        height: 15px;
        border: 1px solid #413dec;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
      }
    }

    .checked:after {
      content: "";
      width: 11px;
      height: 11px;
      background: #413dec;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      position: absolute;
    }

    .link {
      color: #413dec;
      cursor: pointer;
      margin-left: 4px;
    }

    .logo {
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    // margin-top: auto;
    // margin-bottom: 24px;

    .info {
      margin-top: 34px;
      margin-bottom: 20px;
      padding: 24px 21px;
      border-radius: 8px;
      border: 1px solid var(--Deep-25, #f5f5f6);
      opacity: 0.7;
      background: var(--White, #fff);

      color: var(--Kontos-Blue, #413dec);
      text-align: center;
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-weight: 400;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
`;

export const MAX_UNLOCK_COUNT = 9;

interface UnlockProps {
  onSuccess: (cli: KontosClient) => void;
}

export const Unlock: React.FC<UnlockProps> = observer(({ onSuccess }) => {
  const { userStore, sheetStore } = useStores();
  const [seed, setSeed] = useState("");
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const inputRef = useRef<{ resetValue: () => void }>(null);
  const navigate = useNavigate();

  const clearInput = useCallback(() => {
    inputRef.current?.resetValue();
  }, []);

  const reset = useCallback(async () => {
    loadingStore.showLoading();
    try {
      toast({
        text: "You have exhausted all retry attempts. Your local storage will now be reset.",
        type: "error",
      });
      await uploadErrorOnce(
        userStore.accountName || "",
        "storage_reset",
        {
          unlockAmount: userStore.unlockAmount,
        },
        ""
      );
      userStore.updateUnlockAmount(0);
      userStore.resetStorage();
      navigate(ROUTE_AUTH);
    } catch (e) {
      await uploadErrorOnce(
        userStore.accountName || "",
        "storage_reset",
        {
          unlockAmount: userStore.unlockAmount,
        },
        ""
      );
    } finally {
      loadingStore.hideLoading();
    }
  }, [navigate, userStore]);

  useEffect(() => {
    sheetStore.openOuterPin();

    return () => {
      sheetStore.closeOuterPin();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userStore.unlockAmount > MAX_UNLOCK_COUNT) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, userStore.unlockAmount]);

  const handleUnlock = useCallback(async () => {
    setIsUnlocking(true);
    loadingStore.showLoading();
    try {
      const cli = await userStore.reconstructKontosCli(seed);
      userStore.updateUnlockAmount(0);
      userStore.setPin(seed);
      onSuccess && onSuccess(cli);
      setIsSuccess(true);
    } catch (e) {
      console.log("e: ", e);
      toast({
        text: `The PIN code incorrect. You only remain ${
          MAX_UNLOCK_COUNT - userStore.unlockAmount
        } more chances to try.`,
        type: "error",
      });
      userStore.updateUnlockAmount(userStore.unlockAmount + 1);
      setSeed("");
      clearInput();
    } finally {
      loadingStore.hideLoading();
      setIsUnlocking(false);
    }
  }, [clearInput, onSuccess, seed, userStore]);

  useEffect(() => {
    if (seed.length === 6 && !isSuccess && !isUnlocking) {
      handleUnlock();
    }
  }, [handleUnlock, isSuccess, isUnlocking, seed.length]);

  return (
    <Wrapper>
      <div className="main">
        <img
          className="app-icon"
          src="/static/kontos-app.svg"
          alt="kontos-app"
        />
        <div className="title">{t("Kontos Wallet")}</div>
        <div className="input-wrapper">
          <div className="info">
            <span>{t("Please enter the PIN code to confirm.")}</span>
            {/* <UnlockTips /> */}
          </div>
          <PINCodeInput
            size="small"
            onPINChange={setSeed}
            ref={inputRef}
            disabled={isUnlocking || isSuccess}
          />
        </div>
      </div>
      <div className="footer">
        <span>{t("Powered by")}</span>
        <img src={LogoIcon} className={"logo"} alt="" />
      </div>
    </Wrapper>
  );
});
