import { makeAutoObservable, runInAction } from "mobx";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import { RootStore } from "./RootStore";
import { Chain, FtAsset } from "src/type/zkkontos";
import KontosNumber from "src/utils/KontosNumber";
import { isSameFtAsset } from "src/utils/zkkontosHelper";
import { DEFAULT_DECIMAL } from "src/config";
import { fetchSpecificFtAsset } from "src/service/trade-service";
import { fetchAaAccounts } from "@/service/account-service";

export type TxHash = {
  explorerUrl: string;
  chainId: string;
  address: string;
  hash: string;
};

export type DepositOrder = {
  chainId: string;
  address: string;
  amount: string;
  decimals: string;
};

export class ReceiveStore {
  rootStore: RootStore;
  aaAccounts: { [key: string]: string } = {};
  chain?: Chain;
  asset?: FtAsset;
  amount?: KontosNumber;
  pendingTxHashes: TxHash[] = [];
  byDepositOrder: boolean = false;
  freezeWatchingChainId: boolean = false;

  get toReceiveAaAddress(): string | undefined {
    if (this.chain?.chainIndex && this.aaAccounts) {
      return this.aaAccounts?.[this.chain.chainIndex] || undefined;
    }
    return undefined;
  }

  get assetBalance(): KontosNumber | undefined {
    if (this.asset) {
      const matched = this.rootStore.userStore.userHoldings.find((item) =>
        isSameFtAsset(item, this.asset)
      );
      if (matched) {
        return new KontosNumber(matched.balance?.balance, DEFAULT_DECIMAL);
      }
      return new KontosNumber(0);
    }
    return undefined;
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    this.restore();
  }

  restore = async () => {
    this.refreshAaAccounts();
  };

  reset = async () => {
    this.amount = undefined;
    this.asset = undefined;
    this.byDepositOrder = false;
  };

  refreshAaAccounts = async () => {
    if (!this.rootStore.userStore.accountName) {
      return;
    }
    try {
      const resp = await fetchAaAccounts(this.rootStore.userStore.accountName);
      let aaAccounts = resp;
      const nameAddress = KontosQueryCli.nameAddress(
        this.rootStore.userStore.accountName
      );
      aaAccounts[0] = nameAddress;
      runInAction(() => {
        this.aaAccounts = aaAccounts;
      });
    } catch (e) {
      console.warn("Failed to fetch aaaccounts for receive modal", e);
    }
  };

  setChainByChainIndex = async (chainIndex: string) => {
    const matched =
      await this.rootStore.chainStore.fetchSpecificChain(chainIndex);
    this.setChain(matched);
    return matched;
  };

  setAssetByAssetAddress = async (address: string) => {
    if (!this.chain) return undefined;
    const matched = await fetchSpecificFtAsset(this.chain.chainIndex, address);
    this.setAsset(matched);
    return matched;
  };

  setChain = (chain?: Chain) => {
    this.byDepositOrder = false;
    this.amount = undefined;
    this.asset = undefined;
    this.chain = chain;
  };

  setAsset = (asset?: FtAsset) => {
    if (!this.chain) {
      this.amount = undefined;
      this.asset = undefined;
      return;
    }
    if (
      asset &&
      this.chain &&
      asset.chainIndex.toLowerCase() !== this.chain.chainIndex.toLowerCase()
    ) {
      return;
    }
    this.byDepositOrder = false;
    this.amount = undefined;
    this.asset = asset;
  };

  setAmount = (amount?: KontosNumber) => {
    if (!this.chain || !this.asset) {
      this.amount = undefined;
      return;
    }
    this.byDepositOrder = false;
    this.amount = amount;
  };

  addPendingTxHash = (hash: TxHash) => {
    this.pendingTxHashes = [...this.pendingTxHashes, hash];
  };

  removePendingTxHash = (hash: string) => {
    this.pendingTxHashes = this.pendingTxHashes.filter(
      (item) => item.hash !== hash
    );
  };

  setByDepositOrder = (byDepositOrder: boolean) => {
    this.byDepositOrder = byDepositOrder;
  };

  setFreezeWatchingChainId = (freezeWatchingChainId: boolean) => {
    this.freezeWatchingChainId = freezeWatchingChainId;
  };
}
