import { fontBold } from "@/style/style.global";
import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import styled from "styled-components";

const Wrapper = styled.div<{ size: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  .hidden-input {
    background-color: transparent;
    color: transparent;
    width: 1px;
    height: 1px;
    outline: none;
    padding: 0;
    border-width: 0;
    border: none;
    box-shadow: none;
    position: absolute;
    left: 50%;
    bottom: 50%;
    font-size: 16px;
  }
  .actual-pin-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: nowrap;
    position: relative;
  }

  .pin-wrapper {
    position: relative;
  }
  .pin-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #cccfd2;
    background: #f5f5f6;
    width: 50px;
    height: 60px;
    background: #f5f5f6;
    text-align: center;
    ${(prop) =>
      prop.size === "small" &&
      `
      width: 45px;
      height: 47px;
    `}

    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .active {
    position: relative;
    border-color: #413dec;
    background: #fff;
  }
  .dot {
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    padding: 6px;
    border-radius: 50%;
    background-color: #1a2535;
  }

  .value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--Deep-800, #1a2535);
    text-align: center;
    ${fontBold};
    font-size: 24px;
  }
  input {
    :focus-visible {
      outline: none;
      appearance: none; /*去除系统默认的样式*/
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 点击高亮的颜色*/
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .caret {
    animation: 0.8s van-cursor-flicker infinite;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 2px;
    height: 60%;
    background-color: #413dec;
  }
  @keyframes van-cursor-flicker {
    from {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;
const MAX = 6;
const KEYCODE = Object.freeze({
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  END: 35,
  HOME: 36,
  SPACE: 32,
  ENTER: 13,
});

type Props = {
  size?: string;
  onPINChange: (value: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  ref?: Ref<{ resetValue: () => void }>;
  showValue?: boolean;
  type?: "pin" | "verification";
};

export const PINCodeInput: React.FC<Props> = forwardRef(
  (
    {
      size = "normal",
      onPINChange,
      readOnly = false,
      disabled = false,
      showValue = false,
      type = "pin",
    }: Props,
    ref
  ) => {
    const [value, setValue] = useState("");
    const inputRef = useRef<any>();
    const [focus, setFocus] = useState(true);

    useEffect(() => {
      inputRef.current?.focus();
    }, []);

    useImperativeHandle(ref, () => ({
      resetValue: () => {
        setValue("");
      },
    }));

    const handleClick = (e: { preventDefault: () => void }) => {
      // e.preventDefault();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleChange = (e: { target: { value: string } }) => {
      const val = e.target.value || "";
      if (val.length > MAX) return;
      onPINChange(val);
      setValue(val);
    };

    const handleOnKeyDown = (e: {
      keyCode: any;
      preventDefault: () => void;
    }) => {
      switch (e.keyCode) {
        case KEYCODE.LEFT_ARROW:
        case KEYCODE.RIGHT_ARROW:
        case KEYCODE.HOME:
        case KEYCODE.END:
        case KEYCODE.SPACE:
          e.preventDefault();
          break;
        default:
          break;
      }
    };

    return (
      <Wrapper size={size}>
        <div className="actual-pin-wrapper">
          <input
            ref={inputRef}
            className="hidden-input"
            autoComplete={type === "pin" ? "new-password" : "one-time-code"}
            inputMode="numeric"
            type="number"
            pattern="\d*"
            value={value}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={handleChange}
            onKeyDown={handleOnKeyDown}
            maxLength={MAX}
            readOnly={readOnly}
            disabled={disabled}
          />
          {Array.from({ length: MAX }).map((_, index) => {
            const active = index <= value.length && focus;
            const showCaret = index === value.length && focus;
            return (
              <div className="pin-wrapper" key={index}>
                <input
                  className={`pin-input ${active && "active"}`}
                  autoComplete="off"
                  onClick={handleClick}
                  readOnly={true}
                />
                {value[index] &&
                  (showValue ? (
                    <div className="value">{value[index]}</div>
                  ) : (
                    <div className="dot" />
                  ))}
                {showCaret && <div className="caret"></div>}
              </div>
            );
          })}
        </div>
      </Wrapper>
    );
  }
);
