import styled from "styled-components";
import { ContractInteractionType } from "./types";
import avatarIcon from "src/assets/icons/trade/trade-avatar.svg";
import PlanButton from "./PlanButton";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { KontosButton } from "src/components/button/KontosButton";
import arrowIcon from "src/assets/icons/trade/trade-black-up-slim-arrow.svg";
import CostDetailModal from "./CostDetailModal";
import Payment from "./Payment";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_ASSET_ICON_OMIT, DEFAULT_DECIMAL } from "src/config";
import { Unlock } from "../sign-up/unlock/Unlock";
import { KontosClient } from "@zkkontos/kontos-sdk";
import {
  sendKontosNative,
  sendKontosOthers,
  buyV2,
  sellV2,
  userOpCall,
  crossChainTransfer,
} from "./executableMethods";
import { ethers } from "ethers";
import { FtAsset, Chain, toFtAsset } from "src/type/zkkontos";
import assetOmitIcon from "src/assets/icons/asset-omit.svg";
import kontosIntentIcon from "src/assets/icons/trade/kontos-intent.svg";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { loadingStore } from "src/store/loadingStore";
import toast from "src/components/toast/Toast";
import { observer } from "mobx-react";
import { calculateBySlippage, shortAddress } from "src/utils/helper";
import { FromToTitle } from "./FromToTitle";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import { useNavigate } from "react-router-dom";
import { TASK } from "../home/Home";
import { ROUTE_HOME } from "src/router/router-config";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";
import { getChainByAsset } from "@/store/storeHelper";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .clickable {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .top-12 {
    margin-top: 12px;
  }

  .left-10 {
    margin-left: 10px;
  }

  .left-4 {
    margin-left: 4px;
  }

  .right-10 {
    margin-right: 10px;
  }

  .to-text {
    white-space: nowrap;
  }
`;

const InitialContainer = styled.div`
  padding: 0 24px 16px 24px;

  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CommonText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const CommonDeepText = styled.span`
  color: var(--Deep-800);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const KontosText = styled.span`
  color: var(--Kontos-Blue, #413dec);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const StrongText = styled.span`
  color: var(--Deep-800, #1a2535);
  ${fontBold}
  font-size: 14px;
`;

const Title = styled.div`
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fontBold}
  font-size: 24px;
`;

const Target = styled.div`
  margin-top: 30px;
  display: flex;

  span {
    word-break: break-all;
    display: flex;
    align-items: center;
  }
`;

const Amount = styled.div`
  margin: 20px 0;

  text-align: center;
  word-wrap: break-word;

  .amount-insufficient {
    font-family: HarmonyOS Sans;
    color: var(--Kontos-Blue, #413dec);
    font-size: 13px;
  }

  .amount-number {
    display: inline-block;
    height: 46px;
    line-height: 46px;
    color: var(--Kontos-Blue, #413dec);
    ${fontBold}
    font-size: 39.429px;
  }

  .amount-signal {
    color: var(--Kontos-Blue, #413dec);
    ${fontBold}
    font-size: 29.571px;
  }
`;

const ItemList = styled.div`
  flex: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
  color: var(--Deep-400, #80868f);

  .strong {
    color: var(--Deep-800, #1a2535);
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  > span:first-child {
    flex-grow: 1;
  }
`;

const KontosIntentIcon = styled.img`
  width: 26px;
  height: 22px;
  margin-right: -2px;
`;

const Avatar = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  border-radius: 50%;
`;

const ChainOrTokenIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
`;

const Arrow = styled.img`
  width: 10px;
  margin-left: 6px;
`;

const Spliter = styled.div`
  margin-top: 10px;
  height: 1px;
  background: #cccfd2;
`;

const Buttons = styled.div`
  margin-top: 20px;

  :nth-child(3) {
    margin-top: 12px;
  }

  &:first-child {
    margin-top: 100px;
  }
`;

const FreeGasText = styled.span`
  color: var(--Success, #10ce5c);
`;

export interface BuyProps {
  toBuyFtAsset: FtAsset;
  toBuyFtAssetValue: KontosNumber;
  amount: KontosNumber;
  taskData: RespTaskPayment;
  isInSufficient: boolean;
  slippage: KontosNumber;
  maxRequiredUsdCost: KontosNumber;
  isCustom: boolean;
}

export interface SellProps {
  taskData: RespTaskPayment;
  toSellFtAssetQuantity: KontosNumber;
  toSellFtAsset: FtAsset;
  toSellFtassetValue: KontosNumber;
  toReceiveFtAssetQuantity: KontosNumber;
  toReceiveFtAsset: FtAsset;
  toReceiveFtAssetValue: KontosNumber;
  slippage: KontosNumber;
  mayFail: boolean;
}

export interface NativeTransferProps {
  toSendFtAsset: FtAsset;
  amount: KontosNumber;
}

export interface ERC20TransferProps {
  toSendFtAsset: FtAsset;
  amount: KontosNumber;
  gasPrice: ethers.BigNumber;
  gasLimit: number;
  nonce: number;
  contract: ethers.Contract;
}

export interface CrossChainTransferProps {
  toSendFtAsset: FtAsset;
  amount: KontosNumber;
  taskData: RespTaskPayment;
  chain: Chain;
}

export interface UserOpCallProps {
  taskData: RespTaskPayment;
  chain: Chain;
}

interface ContractInteractionProps {
  interactionType: ContractInteractionType;
  target: string;
  onChoosePaymentPlan?: (index: number) => void;
  onInnerModalChange?: (isOpen: boolean) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
  onFail?: (e: any) => void;
  wallet: string;
  receiver?: string;
  buyProps?: BuyProps;
  sellProps?: SellProps;
  nativeTransferProps?: NativeTransferProps;
  erc20TransferProps?: ERC20TransferProps;
  crossChainTransferProps?: CrossChainTransferProps;
  userOpCallProps?: UserOpCallProps;
  displayPrecision?: number;
}

export const TxConfirmation: React.FC<ContractInteractionProps> = observer(
  ({
    interactionType,
    target,
    onInnerModalChange,
    onCancel,
    onSuccess,
    onFail,
    wallet,
    receiver,
    onChoosePaymentPlan,
    buyProps,
    sellProps,
    nativeTransferProps,
    erc20TransferProps,
    crossChainTransferProps,
    userOpCallProps,
    displayPrecision,
  }) => {
    const { userStore, chainStore, sheetStore, tradeStore } = useStores();
    const { t } = useTranslation();
    const [showCostDetailModal, setShowCostDetailModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showPinModal, setShowPinModal] = useState(false);
    const [iconList, setIconList] = useState<any[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
      let newTasksByPaymentPlans: UniformedPayment[] | undefined;

      switch (interactionType) {
        case ContractInteractionType.SendOthers:
          newTasksByPaymentPlans =
            crossChainTransferProps?.taskData?.paymentPlan || undefined;
          break;
        case ContractInteractionType.SellToken:
          newTasksByPaymentPlans =
            sellProps?.taskData?.paymentPlan || undefined;
          break;
        case ContractInteractionType.Dapp:
          newTasksByPaymentPlans =
            userOpCallProps?.taskData?.paymentPlan || undefined;
          break;
        case ContractInteractionType.BuyToken:
          newTasksByPaymentPlans = buyProps?.taskData?.paymentPlan || undefined;
          if (buyProps?.isInSufficient) {
            if (JSON.stringify(iconList) !== JSON.stringify([assetOmitIcon]))
              setIconList([assetOmitIcon]);
            return;
          }
          break;
        default:
          break;
      }

      const newIconSet = new Set();

      const uniformedPayments = newTasksByPaymentPlans;

      uniformedPayments?.forEach((payment) => {
        newIconSet.add(payment?.assetImageUrl || defaultTokenIcon);
      });

      if (JSON.stringify(iconList) !== JSON.stringify([...newIconSet]))
        setIconList([...newIconSet]);
    }, [
      buyProps?.isInSufficient,
      buyProps?.taskData?.paymentPlan,
      userOpCallProps?.taskData?.paymentPlan,
      iconList,
      interactionType,
      sellProps?.taskData?.paymentPlan,
      crossChainTransferProps?.taskData?.paymentPlan,
    ]);

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const domNode = wrapperRef.current as Element | undefined;

    const handlePlanButtonOnClick = () => {
      setShowPaymentModal(true);
    };

    const handlePaymentOnBack = () => {
      setShowPaymentModal(false);
    };

    useEffect(() => {
      onInnerModalChange?.(
        showCostDetailModal || showPaymentModal || showPinModal
      );
    }, [
      showCostDetailModal,
      onInnerModalChange,
      showPaymentModal,
      showPinModal,
    ]);

    useEffect(() => {
      sheetStore.openOuterTrade();

      return () => {
        sheetStore.closeOuterTrade();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useMouseDownOutside({
      ref: wrapperRef,
      callback: () => {
        setShowCostDetailModal(false);
        setShowPaymentModal(false);
        setShowPinModal(false);
      },
      shouldClose: true,
    });

    const handleConfirmBtnOnClick = () => {
      if (
        interactionType === ContractInteractionType.BuyToken &&
        buyProps?.isInSufficient
      ) {
        setShowPaymentModal(true);
        return;
      }
      setShowPinModal(true);
    };

    const hanldePINSuccess = useCallback(
      async (cli: KontosClient) => {
        setShowPinModal(false);
        let chains = chainStore.chains;
        if (
          interactionType !== ContractInteractionType.SendKontosNative &&
          interactionType !== ContractInteractionType.SendKontosOthers &&
          chains.length === 0
        ) {
          try {
            loadingStore.showLoading();
            chains = (await chainStore.fetchAndSetChains()) || [];
            if (chains.length === 0) throw new Error();
          } catch (e) {
            toast({
              text: t("Failed to fetch chain info, please try again later"),
              type: "error",
            });
            return;
          } finally {
            loadingStore.hideLoading();
          }
        }

        const onTaskSuccess = (opHash?: string) => {
          toast({
            text: (
              <div
                onClick={() => {
                  tradeStore.setNewestOpHash(opHash);
                  navigate(`${ROUTE_HOME}?tab=${TASK.name}`);
                }}
              >
                <Trans i18nKey={"trans-on-task-register-success"}>
                  Successfully registered a cross-chain task. You can view the
                  task progress{" "}
                  <span
                    style={{
                      color: "var(--Kontos-Blue, #413dec)",
                      cursor: "pointer",
                    }}
                  >
                    here
                  </span>
                </Trans>
              </div>
            ),
            type: "success",
          });
          onSuccess?.();
        };

        switch (interactionType) {
          case ContractInteractionType.BuyToken:
            await buyV2(
              userStore.accountName!,
              chains,
              cli,
              buyProps?.taskData!,
              onTaskSuccess,
              onFail
            );
            break;
          case ContractInteractionType.SellToken:
            await sellV2(
              userStore.accountName!,
              chains,
              cli,
              sellProps?.taskData!,
              onTaskSuccess,
              onFail
            );
            break;
          case ContractInteractionType.SendKontosNative:
            await sendKontosNative(
              cli,
              nativeTransferProps!.amount,
              target,
              onSuccess,
              onFail
            );
            break;
          case ContractInteractionType.SendKontosOthers:
            await sendKontosOthers(
              erc20TransferProps!.contract,
              erc20TransferProps!.gasPrice,
              erc20TransferProps!.nonce,
              erc20TransferProps!.gasLimit,
              erc20TransferProps!.amount,
              target,
              onSuccess,
              onFail
            );
            break;
          case ContractInteractionType.SendOthers:
            await crossChainTransfer(
              crossChainTransferProps?.chain!,
              crossChainTransferProps?.amount!.toStringWithDecimal(
                DEFAULT_DECIMAL
              )!,
              crossChainTransferProps?.toSendFtAsset!,
              userStore.accountName!,
              cli,
              crossChainTransferProps?.taskData!,
              chains,
              onTaskSuccess,
              onFail
            );
            break;
          case ContractInteractionType.Dapp:
            await userOpCall(
              userStore.accountName!,
              cli,
              userOpCallProps?.taskData!,
              chains,
              onTaskSuccess,
              onFail
            );
            break;
          default:
            break;
        }
      },
      [
        chainStore,
        interactionType,
        t,
        onSuccess,
        navigate,
        tradeStore,
        userStore.accountName,
        buyProps?.taskData,
        onFail,
        sellProps?.taskData,
        nativeTransferProps,
        target,
        erc20TransferProps,
        crossChainTransferProps?.chain,
        crossChainTransferProps?.amount,
        crossChainTransferProps?.toSendFtAsset,
        crossChainTransferProps?.taskData,
        userOpCallProps?.taskData,
      ]
    );

    const targetAsset: FtAsset | undefined = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          return buyProps?.toBuyFtAsset;
        case ContractInteractionType.SellToken:
          return sellProps?.toSellFtAsset;
        case ContractInteractionType.SendKontosNative:
          return nativeTransferProps?.toSendFtAsset;
        case ContractInteractionType.SendKontosOthers:
          return erc20TransferProps?.toSendFtAsset;
        case ContractInteractionType.SendOthers:
          return crossChainTransferProps?.toSendFtAsset;
        case ContractInteractionType.Dapp:
          return undefined;
        default:
          return undefined;
      }
    }, [
      buyProps?.toBuyFtAsset,
      interactionType,
      sellProps?.toSellFtAsset,
      nativeTransferProps?.toSendFtAsset,
      erc20TransferProps?.toSendFtAsset,
      crossChainTransferProps?.toSendFtAsset,
    ]);

    // Bellows are view memory
    const toText = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
        case ContractInteractionType.SellToken:
        case ContractInteractionType.Swap:
        case ContractInteractionType.BatchSell:
          return t("Trade with: ");
        case ContractInteractionType.SendKontosNative:
          return t("Send to: ");
        case ContractInteractionType.SendKontosOthers:
          return t("Send to: ");
        case ContractInteractionType.SendOthers:
          return t("Send to: ");
        case ContractInteractionType.Dapp:
          return t("Pay to: ");
        default:
          return "";
      }
    }, [interactionType, t]);

    const interactionTaskData = useMemo((): RespTaskPayment | undefined => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          return buyProps?.taskData;
        case ContractInteractionType.SellToken:
          return sellProps?.taskData;
        case ContractInteractionType.SendOthers:
          return crossChainTransferProps?.taskData;
        case ContractInteractionType.Dapp:
          return userOpCallProps?.taskData;
        case ContractInteractionType.SendKontosNative:
        case ContractInteractionType.SendKontosOthers:
        default:
          return undefined;
      }
    }, [
      buyProps?.taskData,
      userOpCallProps?.taskData,
      interactionType,
      sellProps?.taskData,
      crossChainTransferProps?.taskData,
    ]);

    // Texts or JSXs to display
    const title = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
        case ContractInteractionType.SellToken:
        case ContractInteractionType.BatchSell:
        case ContractInteractionType.Dapp:
          return t("Contract Interaction") as string;
        case ContractInteractionType.SendKontosNative:
          return t("Send Confirmation") as string;
        case ContractInteractionType.SendKontosOthers:
          return t("Send Confirmation") as string;
        case ContractInteractionType.SendOthers:
          return t("Send Confirmation") as string;
        case ContractInteractionType.Swap:
          return t("Swap Confirmation") as string;
        default:
          return "";
      }
    }, [interactionType, t]);

    const fromToProps:
      | {
          isCrossChain?: boolean;
          fee?: KontosNumber;
          fromAssets: FtAsset[];
          fromValue: KontosNumber;
          fromSymbol: string;
          fromValueInUsd?: KontosNumber;
          toAssets: FtAsset[];
          toValue: KontosNumber;
          toSymbol: string;
          toValueInUsd?: KontosNumber;
        }
      | undefined = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.SellToken:
          if (!sellProps || !interactionTaskData) return undefined;
          return {
            isCrossChain: true,
            fee: new KontosNumber(
              interactionTaskData.totalFeeInUsd,
              DEFAULT_DECIMAL
            ),
            fromAssets: [sellProps.toSellFtAsset],
            fromValue: new KontosNumber(
              interactionTaskData.paymentPlan
                ? interactionTaskData.paymentPlan[0].assetAmount
                : undefined,
              DEFAULT_DECIMAL
            ),
            fromSymbol: sellProps.toSellFtAsset.symbol,
            fromValueInUsd: new KontosNumber(
              interactionTaskData.totalPaymentsInUsd,
              DEFAULT_DECIMAL
            ),
            toAssets: [sellProps.toReceiveFtAsset],
            toValue: sellProps.toReceiveFtAssetQuantity,
            toSymbol: sellProps.toReceiveFtAsset.symbol,
            toValueInUsd: sellProps.toReceiveFtAssetValue,
          };
        case ContractInteractionType.BuyToken:
          if (!buyProps || !interactionTaskData || buyProps.isInSufficient)
            return undefined;
          return {
            isCrossChain: true,
            fee: new KontosNumber(
              interactionTaskData.totalFeeInUsd,
              DEFAULT_DECIMAL
            ),
            fromAssets: interactionTaskData.paymentPlan
              ? interactionTaskData.paymentPlan.map((payment) =>
                  toFtAsset(payment)
                )
              : [],
            fromValue:
              interactionTaskData.paymentPlan &&
              interactionTaskData.paymentPlan.length === 1
                ? new KontosNumber(
                    interactionTaskData.paymentPlan[0].assetAmount,
                    DEFAULT_DECIMAL
                  )
                : new KontosNumber(
                    interactionTaskData.totalPaymentsInUsd,
                    DEFAULT_DECIMAL
                  ),
            fromSymbol:
              interactionTaskData.paymentPlan &&
              interactionTaskData.paymentPlan.length === 1
                ? interactionTaskData.paymentPlan[0].assetSymbol
                : "USD",
            fromValueInUsd:
              interactionTaskData.paymentPlan &&
              interactionTaskData.paymentPlan.length === 1
                ? new KontosNumber(
                    interactionTaskData.totalPaymentsInUsd,
                    DEFAULT_DECIMAL
                  )
                : undefined,
            toAssets: [buyProps.toBuyFtAsset],
            toValue: buyProps.amount,
            toSymbol: buyProps.toBuyFtAsset.symbol,
            toValueInUsd: buyProps.toBuyFtAssetValue,
          };
        default:
          return undefined;
      }
    }, [buyProps, interactionTaskData, interactionType, sellProps]);

    const mainAmountText = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          if (buyProps?.isInSufficient) {
            return (
              "-" + buyProps?.maxRequiredUsdCost?.toFormat(displayPrecision)
            );
          } else {
            return (
              "-" +
              new KontosNumber(
                interactionTaskData?.totalPaymentsInUsd,
                DEFAULT_DECIMAL
              ).toFormat(displayPrecision)
            );
          }
        case ContractInteractionType.SellToken:
          return (
            "-" + sellProps?.toSellFtAssetQuantity.toFormat(displayPrecision)
          );
        case ContractInteractionType.SendKontosNative:
          return "-" + nativeTransferProps?.amount.toFormat(displayPrecision);
        case ContractInteractionType.SendKontosOthers:
          return "-" + erc20TransferProps?.amount.toFormat(displayPrecision);
        case ContractInteractionType.SendOthers:
          return (
            "-" + crossChainTransferProps?.amount.toFormat(displayPrecision)
          );
        case ContractInteractionType.Dapp:
          return (
            "-" +
            new KontosNumber(
              userOpCallProps?.taskData?.totalPaymentsInUsd,
              DEFAULT_DECIMAL
            ).toFormat(displayPrecision)
          );
        default:
          return "";
      }
    }, [
      interactionType,
      buyProps?.isInSufficient,
      buyProps?.maxRequiredUsdCost,
      sellProps?.toSellFtAssetQuantity,
      displayPrecision,
      nativeTransferProps?.amount,
      erc20TransferProps?.amount,
      crossChainTransferProps?.amount,
      userOpCallProps?.taskData?.totalPaymentsInUsd,
      interactionTaskData?.totalPaymentsInUsd,
    ]);

    const networkLabel = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
        case ContractInteractionType.SellToken:
        case ContractInteractionType.BatchSell:
          return t("Receiving network:");
        case ContractInteractionType.SendKontosNative:
        case ContractInteractionType.SendKontosOthers:
        case ContractInteractionType.SendOthers:
        case ContractInteractionType.Dapp:
        case ContractInteractionType.Swap:
          return t("Network") + ":";
      }
    }, [interactionType, t]);

    const networkChainImageUrl = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          return (
            getChainByAsset(buyProps?.toBuyFtAsset)?.greyImageURL ||
            defaultChainIcon
          );
        case ContractInteractionType.SellToken:
          return (
            getChainByAsset(sellProps?.toReceiveFtAsset)?.greyImageURL ||
            defaultChainIcon
          );
        case ContractInteractionType.SendKontosNative:
          return (
            getChainByAsset(nativeTransferProps?.toSendFtAsset)?.greyImageURL ||
            defaultChainIcon
          );
        case ContractInteractionType.SendKontosOthers:
          return (
            getChainByAsset(erc20TransferProps?.toSendFtAsset)?.greyImageURL ||
            defaultChainIcon
          );
        case ContractInteractionType.SendOthers:
          return (
            getChainByAsset(crossChainTransferProps?.toSendFtAsset)
              ?.greyImageURL || defaultChainIcon
          );
        case ContractInteractionType.Dapp:
          return userOpCallProps?.chain?.imageURL || defaultChainIcon;
        default:
          return defaultChainIcon;
      }
    }, [
      buyProps?.toBuyFtAsset,
      crossChainTransferProps?.toSendFtAsset,
      erc20TransferProps?.toSendFtAsset,
      interactionType,
      nativeTransferProps?.toSendFtAsset,
      sellProps?.toReceiveFtAsset,
      userOpCallProps?.chain?.imageURL,
    ]);

    const networkChainSymbol = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          return getChainByAsset(buyProps?.toBuyFtAsset)?.chainSymbol;
        case ContractInteractionType.SellToken:
          return getChainByAsset(sellProps?.toReceiveFtAsset)?.chainSymbol;
        case ContractInteractionType.SendKontosNative:
          return getChainByAsset(nativeTransferProps?.toSendFtAsset)
            ?.chainSymbol;
        case ContractInteractionType.SendKontosOthers:
          return getChainByAsset(erc20TransferProps?.toSendFtAsset)
            ?.chainSymbol;
        case ContractInteractionType.SendOthers:
          return getChainByAsset(crossChainTransferProps?.toSendFtAsset)
            ?.chainSymbol;
        case ContractInteractionType.Dapp:
          return userOpCallProps?.chain?.chainSymbol;
        default:
          return null;
      }
    }, [
      buyProps?.toBuyFtAsset,
      crossChainTransferProps?.toSendFtAsset,
      erc20TransferProps?.toSendFtAsset,
      interactionType,
      nativeTransferProps?.toSendFtAsset,
      sellProps?.toReceiveFtAsset,
      userOpCallProps?.chain?.chainSymbol,
    ]);

    const mainAmountSymbol = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
        case ContractInteractionType.Dapp:
        case ContractInteractionType.BatchSell:
          return " USD";
        case ContractInteractionType.SellToken:
          return " " + sellProps?.toSellFtAsset?.symbol;
        case ContractInteractionType.SendKontosNative:
          return " " + nativeTransferProps?.toSendFtAsset?.symbol;
        case ContractInteractionType.SendKontosOthers:
          return " " + erc20TransferProps?.toSendFtAsset?.symbol;
        case ContractInteractionType.SendOthers:
          return " " + crossChainTransferProps?.toSendFtAsset?.symbol;
        default:
          return "";
      }
    }, [
      interactionType,
      sellProps?.toSellFtAsset?.symbol,
      nativeTransferProps?.toSendFtAsset?.symbol,
      erc20TransferProps?.toSendFtAsset?.symbol,
      crossChainTransferProps?.toSendFtAsset?.symbol,
    ]);

    const gasFeeText = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.SendKontosNative:
        case ContractInteractionType.SendKontosOthers:
          return <FreeGasText>{t("Free")}</FreeGasText>;
        default:
          return "";
      }
    }, [interactionType, t]);

    const syncAaFeeText = useMemo(() => {
      if (buyProps?.isInSufficient) {
        return t("(Insufficient balance)");
      }

      switch (interactionType) {
        case ContractInteractionType.BuyToken:
        case ContractInteractionType.SellToken:
        case ContractInteractionType.Swap:
        case ContractInteractionType.SendOthers:
        case ContractInteractionType.BatchSell:
          const syncFee = new KontosNumber(
            interactionTaskData?.totalSyncAccountFeeInUsd,
            DEFAULT_DECIMAL
          );

          if (syncFee.gt(0)) {
            return "-" + syncFee.toFormat(displayPrecision) + " USD";
          } else {
            return "";
          }
        default:
          return "";
      }
    }, [
      buyProps?.isInSufficient,
      displayPrecision,
      interactionTaskData?.totalSyncAccountFeeInUsd,
      interactionType,
      t,
    ]);

    const totalFeeText = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          if (buyProps?.isInSufficient) {
            return t("(Insufficient balance)");
          } else {
            return "";
          }
        case ContractInteractionType.Swap:
        case ContractInteractionType.SendOthers:
          return (
            "-" +
            new KontosNumber(
              interactionTaskData?.totalFeeInUsd,
              DEFAULT_DECIMAL
            ).toFormat(displayPrecision) +
            " USD"
          );
        default:
          return "";
      }
    }, [
      buyProps?.isInSufficient,
      displayPrecision,
      interactionTaskData?.totalFeeInUsd,
      interactionType,
      t,
    ]);

    const confirmBtnText = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          if (buyProps?.isInSufficient) {
            return t("Get Assets") as string;
          } else {
            return t("Confirm") as string;
          }
        case ContractInteractionType.SellToken:
        case ContractInteractionType.SendKontosNative:
        case ContractInteractionType.SendKontosOthers:
        case ContractInteractionType.Swap:
        case ContractInteractionType.SendOthers:
        case ContractInteractionType.Dapp:
        case ContractInteractionType.BatchSell:
          return t("Confirm") as string;
        default:
          return "";
      }
    }, [buyProps?.isInSufficient, interactionType, t]);

    const hasCostDetail = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          if (buyProps?.isInSufficient) {
            return false;
          } else {
            return true;
          }
        case ContractInteractionType.SellToken:
        case ContractInteractionType.SendOthers:
        case ContractInteractionType.Dapp:
        case ContractInteractionType.Swap:
        case ContractInteractionType.BatchSell:
          return true;
        default:
          return false;
      }
    }, [buyProps?.isInSufficient, interactionType]);

    const paymentPlanLabel: string = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
        case ContractInteractionType.SellToken:
        case ContractInteractionType.SendOthers:
        case ContractInteractionType.Dapp:
        case ContractInteractionType.Swap:
          return t("Payment");
        case ContractInteractionType.BatchSell:
          return t("Batch Sell");
        default:
          return "";
      }
    }, [interactionType, t]);

    const paymentPlanText = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          if (buyProps?.isInSufficient) {
            return t("insufficient balance") as string;
          } else if (buyProps?.isCustom) {
            return t("Custom Plan");
          } else {
            return t("Recommend Plan");
          }
        case ContractInteractionType.SellToken:
        case ContractInteractionType.SendOthers:
        case ContractInteractionType.Dapp:
        case ContractInteractionType.Swap:
        case ContractInteractionType.BatchSell:
          return t("Recommend Plan");
        default:
          return "";
      }
    }, [interactionType, buyProps?.isInSufficient, buyProps?.isCustom, t]);

    const receiveOrSendAssetText = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
        case ContractInteractionType.SellToken:
        case ContractInteractionType.BatchSell:
          return t("Receiving assets") as string;
        default:
          return "";
      }
    }, [interactionType, t]);

    const receiveOrSendAssetValue = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          return buyProps?.isInSufficient
            ? "+" +
                buyProps?.amount?.toFormat(displayPrecision) +
                " " +
                buyProps?.toBuyFtAsset?.symbol
            : "";

        default:
          return "";
      }
    }, [
      buyProps?.amount,
      buyProps?.isInSufficient,
      buyProps?.toBuyFtAsset?.symbol,
      displayPrecision,
      interactionType,
    ]);

    const slippageValue = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          return buyProps?.slippage.multiply(100).toFixed(2) + "%";
        case ContractInteractionType.SellToken:
          return sellProps?.slippage.multiply(100).toFixed(2) + "%";
        default:
          return "";
      }
    }, [buyProps?.slippage, interactionType, sellProps?.slippage]);

    const minimumReceivedIcon = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          return buyProps?.toBuyFtAsset?.imageUrl || defaultTokenIcon;
        case ContractInteractionType.SellToken:
          return sellProps?.toReceiveFtAsset?.imageUrl || defaultTokenIcon;
        default:
          return defaultTokenIcon;
      }
    }, [
      buyProps?.toBuyFtAsset?.imageUrl,
      interactionType,
      sellProps?.toReceiveFtAsset?.imageUrl,
    ]);

    const minimumReceivedValue = useMemo(() => {
      switch (interactionType) {
        case ContractInteractionType.BuyToken:
          return (
            "+" +
            calculateBySlippage(
              buyProps?.amount!,
              buyProps?.slippage!
            ).toFormat(displayPrecision) +
            " " +
            buyProps?.toBuyFtAsset?.symbol
          );
        case ContractInteractionType.SellToken:
          return (
            "+" +
            calculateBySlippage(
              sellProps?.toReceiveFtAssetQuantity!,
              sellProps?.slippage!
            ).toFormat(displayPrecision) +
            " " +
            sellProps?.toReceiveFtAsset?.symbol
          );
        default:
          return "";
      }
    }, [
      buyProps?.amount,
      buyProps?.slippage,
      buyProps?.toBuyFtAsset?.symbol,
      displayPrecision,
      interactionType,
      sellProps?.slippage,
      sellProps?.toReceiveFtAsset?.symbol,
      sellProps?.toReceiveFtAssetQuantity,
    ]);

    return (
      <Container ref={wrapperRef}>
        <InitialContainer>
          <Title>{title}</Title>
          <Target>
            <CommonText className="right-10 to-text">{toText}</CommonText>
            {(interactionType === ContractInteractionType.BuyToken ||
              interactionType === ContractInteractionType.SellToken ||
              interactionType === ContractInteractionType.Swap ||
              interactionType === ContractInteractionType.BatchSell) && (
              <KontosIntentIcon src={kontosIntentIcon} />
            )}
            {(interactionType === ContractInteractionType.SendKontosNative ||
              interactionType === ContractInteractionType.SendKontosOthers ||
              interactionType === ContractInteractionType.SendOthers) && (
              <Avatar src={avatarIcon} />
            )}
            <StrongText className="left-4">{target}</StrongText>
          </Target>

          {/* Intro Content */}
          {fromToProps !== undefined ? (
            <>
              <div style={{ marginTop: "20px" }} />
              <FromToTitle
                style={
                  fromToProps.isCrossChain
                    ? { marginBottom: "16px" }
                    : { marginBottom: "20px" }
                }
                isCrossChain={fromToProps.isCrossChain}
                fee={fromToProps.fee}
                fromAssets={fromToProps.fromAssets}
                fromValue={fromToProps.fromValue}
                fromSymbol={fromToProps.fromSymbol}
                fromValueInUsd={fromToProps.fromValueInUsd}
                toAssets={fromToProps.toAssets}
                toValue={fromToProps.toValue}
                toSymbol={fromToProps.toSymbol}
                toValueInUsd={fromToProps.toValueInUsd}
              />
            </>
          ) : (
            <Amount>
              {interactionType === ContractInteractionType.BuyToken &&
                buyProps?.isInSufficient && (
                  <div className="amount-insufficient">{t("(Estimated)")}</div>
                )}
              <div></div>
              <span className="amount-number">{mainAmountText}</span>
              <span className="amount-signal">{mainAmountSymbol}</span>
            </Amount>
          )}

          {/* List */}
          <ItemList>
            {/* Network */}
            <Item>
              <CommonText>{networkLabel}</CommonText>
              <ImageWithFallback
                src={networkChainImageUrl}
                fallbackSrc={defaultChainIcon}
                StyledImg={ChainOrTokenIcon}
              />
              <CommonText>{networkChainSymbol}</CommonText>
            </Item>

            {/* User's Kontos Wallet */}
            <Item>
              {receiver ? (
                <>
                  <CommonText>{t("Receiving address:")}</CommonText>
                  <Avatar src={avatarIcon} />
                  <CommonText>
                    {ethers.utils.isAddress(receiver)
                      ? shortAddress(receiver)
                      : receiver.replaceAll(".os", "") + ".os"}
                  </CommonText>
                </>
              ) : (
                <>
                  <CommonText>{t("Your wallet:")}</CommonText>
                  <Avatar src={avatarIcon} />
                  <CommonText>
                    {wallet.replaceAll(".os", "") + ".os"}
                  </CommonText>
                </>
              )}
            </Item>

            {/* Gas Fee */}
            {gasFeeText !== "" && (
              <Item>
                <CommonText>{t("Gas fee:")}</CommonText>
                <CommonText>{gasFeeText}</CommonText>
              </Item>
            )}

            {/* Receive Or Send asset type & amount */}
            {receiveOrSendAssetText !== "" &&
              receiveOrSendAssetValue !== "" && (
                <Item>
                  <CommonText>{receiveOrSendAssetText}:</CommonText>
                  <KontosText>{receiveOrSendAssetValue}</KontosText>
                </Item>
              )}

            {/* Receive asset with slippage */}
            {minimumReceivedValue !== "" && (
              <Item>
                <CommonText>{t("Minimum received")}:</CommonText>
                <ImageWithFallback
                  src={minimumReceivedIcon}
                  fallbackSrc={defaultTokenIcon}
                  StyledImg={ChainOrTokenIcon}
                />
                <CommonText>{minimumReceivedValue}</CommonText>
              </Item>
            )}

            {/* Slippage */}
            {slippageValue !== "" && (
              <Item>
                <CommonText>{t("Slippage")}:</CommonText>
                <CommonText>{slippageValue}</CommonText>
              </Item>
            )}

            {/* Sync AA smart account fee */}
            {syncAaFeeText !== "" && (
              <Item>
                <CommonText>{t("Sync AA smart account fee:")}</CommonText>
                <CommonText>{syncAaFeeText}</CommonText>
              </Item>
            )}

            {/* Total Fee */}
            {totalFeeText !== "" && (
              <Item>
                <CommonText>{t("Total fee:")}</CommonText>
                <CommonDeepText>{totalFeeText}</CommonDeepText>
              </Item>
            )}

            {/* Cost Detail */}
            {hasCostDetail === true && (
              <Item>
                <CommonText>{t("Cost details:")}</CommonText>
                <StrongText
                  className="clickable"
                  onClick={() => setShowCostDetailModal(true)}
                >
                  {t("Check")}
                </StrongText>
                <Arrow src={arrowIcon} />
              </Item>
            )}
          </ItemList>

          {/* Payment Button (With Spliter) */}
          {paymentPlanLabel !== "" && (
            <>
              <Spliter />
              <Item>
                <CommonText>{paymentPlanLabel}:</CommonText>
                <PlanButton
                  iconList={iconList}
                  max={DEFAULT_ASSET_ICON_OMIT}
                  text={paymentPlanText}
                  onClick={handlePlanButtonOnClick}
                />
              </Item>
            </>
          )}

          <Buttons>
            <KontosButton $isOutlined={false} onClick={handleConfirmBtnOnClick}>
              {confirmBtnText}
            </KontosButton>
            <KontosButton
              className="top-12"
              $isOutlined={true}
              onClick={onCancel}
            >
              {t("Cancel")}
            </KontosButton>
          </Buttons>
        </InitialContainer>

        <BottomSheet
          isOpen={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          mountPoint={domNode}
        >
          <Payment
            isInSufficient={buyProps?.isInSufficient!}
            onBack={handlePaymentOnBack}
            taskData={interactionTaskData!}
          />
        </BottomSheet>

        <BottomSheet
          isOpen={showCostDetailModal}
          onClose={() => setShowCostDetailModal(false)}
          mountPoint={domNode}
          customHeight={482}
        >
          <CostDetailModal
            onDone={() => setShowCostDetailModal(false)}
            taskData={interactionTaskData}
            targetAsset={targetAsset}
          />
        </BottomSheet>

        {/* Input PIN to Pay */}
        <BottomSheet
          isOpen={showPinModal}
          onClose={() => setShowPinModal(false)}
          mountPoint={domNode}
          disableScrollLocking={true}
        >
          <Unlock
            onSuccess={(cli) => {
              hanldePINSuccess(cli);
            }}
          />
        </BottomSheet>
      </Container>
    );
  }
);
