import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tipsIcon from "src/assets/icons/trade/tip.svg";
import { fontDescription } from "@/style/style.global";

const Container = styled.div`
  display: flex;
  padding: 24px 10px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const TipsIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const Title = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontDescription}
`;

const Desc = styled.span`
  color: var(--Deep-400, #80868f);
  text-align: center;
  ${fontDescription}
`;

const SearchButton = styled.span`
  color: var(--Kontos-Blue, #413dec);
  ${fontDescription}
`;

interface IProps {
  onGoToSearch?: () => void;
}

export const AssetEndMessage: React.FC<IProps> = ({ onGoToSearch }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TipsIcon src={tipsIcon} alt="" />
      <Title>{t("All assets displayed～")}</Title>
      <Desc>{t("You can search for assets by name or contract address.")}</Desc>
      <SearchButton onClick={onGoToSearch}>{t("Go to Search ☝️")}</SearchButton>
    </Container>
  );
};
